import { genericConstants } from "../constants/GenericConstants";

const initialState = {
    lang: "es"
};


export const SetDefualtLanguage = (state = initialState, action) => {
    if (action.type == genericConstants.SET_DEFAULT_LANGUAGE) {
        return {
            ...state,
            lang: action.payload,
        };
    } else {
        return state;
    }
};