import { AccumulationChartComponent, AccumulationDataLabel, AccumulationLegend, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Inject, PieSeries } from '@syncfusion/ej2-react-charts'
import React from 'react'

export default function ChartPieRadius({ data }) {

    console.log('dataChartData', data)

    return (
        <AccumulationChartComponent id={data.ChartLongName} legendSettings={{ visible: true, reverse: true }} enableSmartLabels={true} title={data.ChartLongName} enableBorderOnMouseMove={false} enableAnimation={true}
            tooltip={{ enable: true, format: '<b>${point.x}</b><br/>Cantidad: <b>${point.y}' }}
        >
            <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]} />
            <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective dataSource={data?.ChartData} pointColorMapping="HexColor" xName='Name' yName='Value' innerRadius='50%' tooltipMappingName='100' dataLabel={{ visible: true, position: 'Outside', name: 'Name', enableRotation: true, font: { fontWeight: '600' }, connectorStyle: { length: '20px', type: 'Curve' } }} radius='140' />
            </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
    )
}
