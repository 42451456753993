export const flujoMasivoConstants = {

    UPLOAD_BATCHFILE_REQUEST: 'UPLOAD_BATCHFILE_REQUEST',
    UPLOAD_BATCHFILE_SUCCESS: 'UPLOAD_BATCHFILE_SUCCESS',
    UPLOAD_BATCHFILE_FAILURE: 'UPLOAD_BATCHFILE_FAILURE',
    UPLOAD_BATCHFILE_RESET: 'UPLOAD_BATCHFILE_RESET',    

    INITIALIZATE_BATCHFILE_REQUEST: 'INITIALIZATE_BATCHFILE_REQUEST',
    INITIALIZATE_BATCHFILE_SUCCESS: 'INITIALIZATE_BATCHFILE_SUCCESS',
    INITIALIZATE_BATCHFILE_FAILURE: 'INITIALIZATE_BATCHFILE_FAILURE',

    GETFILE_STAGE_REQUEST: 'GETFILE_STAGE_REQUEST',
    GETFILE_STAGE_SUCCESS: 'GETFILE_STAGE_SUCCESS',
    GETFILE_STAGE_FAILURE: 'GETFILE_STAGE_FAILURE',

    GETALL_BULKFILE_REQUEST: 'GETALL_BULKFILE_REQUEST',
    GETALL_BULKFILE_SUCCESS: 'GETALL_BULKFILE_SUCCESS',
    GETALL_BULKFILE_FAILURE: 'GETALL_BULKFILE_FAILURE',

    UPLOAD_BULKFILE_REQUEST: 'UPLOAD_BULKFILE_REQUEST',
    UPLOAD_BULKFILE_SUCCESS: 'UPLOAD_BULKFILE_SUCCESS',
    UPLOAD_BULKFILE_FAILURE: 'UPLOAD_BULKFILE_FAILURE',
    UPLOAD_BULKFILE_RESET: 'UPLOAD_BULKFILE_RESET',

    GET_BULK_FILEDETAILS_REQUEST: 'GET_BULK_FILEDETAILS_REQUEST',
    GET_BULK_FILEDETAILS_SUCCESS: 'GET_BULK_FILEDETAILS_SUCCESS',
    GET_BULK_FILEDETAILS_FAILURE: 'GET_BULK_FILEDETAILS_FAILURE',
    GET_BULK_FILEDETAILS_RESET: 'GET_BULK_FILEDETAILS_RESET',

    UPLOAD_BATCHREGISTER_GEID_REQUEST: 'UPLOAD_BATCHREGISTER_GEID_REQUEST',
    UPLOAD_BATCHREGISTER_GEID_SUCCESS: 'UPLOAD_BATCHREGISTER_GEID_SUCCESS',
    UPLOAD_BATCHREGISTER_GEID_FAILURE: 'UPLOAD_BATCHREGISTER_GEID_FAILURE',
    UPLOAD_BATCHREGISTER_GEID_RESET: 'UPLOAD_BATCHREGISTER_GEID_RESET',

    GET_LIST_GEID_MASIVE_REQUEST: 'GET_LIST_GEID_MASIVE_REQUEST',
    GET_LIST_GEID_MASIVE_SUCCESS: 'GET_LIST_GEID_MASIVE_SUCCESS',
    GET_LIST_GEID_MASIVE_FAILURE: 'GET_LIST_GEID_MASIVE_FAILURE',

    GET_ALL_BULK_METADATA_REQUEST: 'GET_ALL_BULK_METADATA_REQUEST',
    GET_ALL_BULK_METADATA_SUCCESS: 'GET_ALL_BULK_METADATA_SUCCESS',
    GET_ALL_BULK_METADATA_FAILURE: 'GET_ALL_BULK_METADATA_FAILURE',
    GET_ALL_BULK_METADATA_RESET: 'GET_ALL_BULK_METADATA_RESET',

    GET_BULK_METADATA_DETAILS_REQUEST: 'GET_BULK_METADATA_DETAILS_REQUEST',
    GET_BULK_METADATA_DETAILS_SUCCESS: 'GET_BULK_METADATA_DETAILS_SUCCESS',
    GET_BULK_METADATA_DETAILS_FAILURE: 'GET_BULK_METADATA_DETAILS_FAILURE',
    GET_BULK_METADATA_DETAILS_RESET: 'GET_BULK_METADATA_DETAILS_RESET',

    UPLOAD_BULK_FILE_DETAILS_REQUEST: 'UPLOAD_BULK_FILE_DETAILS_REQUEST',
    UPLOAD_BULK_FILE_DETAILS_SUCCESS: 'UPLOAD_BULK_FILE_DETAILS_SUCCESS',
    UPLOAD_BULK_FILE_DETAILS_FAILURE: 'UPLOAD_BULK_FILE_DETAILS_FAILURE',
    UPLOAD_BULK_FILE_DETAILS_RESET: 'UPLOAD_BULK_FILE_DETAILS_RESET',
}