import React from 'react'
import { Dialog, DialogTitle, Typography, TextField, IconButton, Box } from '@mui/material'
import { Close } from '@mui/icons-material'

import { mainStyles } from '../theme/mainStyles'
import { Buttons } from "../components"

export const CustomAlert = (props) => {
    const classes = mainStyles.customDialogStyles()

    return (
        <Dialog onClose={props.closeAction} open={props.open} >
            <div className={classes.dialogCenter}>
                <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.title}>
                    {props.title}
                    {/* <IconButton
                        aria-label="close"
                        onClick={props.closeAction}
                        className={classes.customButtonClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton> */}
                </Typography>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.dialogBody}>
                    {props.body}
                </Typography>
                {props.comments &&
                    <Box sx={{ p: 2 }}>
                        <TextField
                            autoFocus
                            className={classes.textField}
                            placeholder={props.commentPlaceHolder}
                            type="text"
                            multiline
                            fullWidth
                            value={props.valueComment}
                            rows={4}
                            variant="outlined"
                            onChange={(event) => {
                                props.setValueComment(event.target.value)
                            }}
                        />
                    </Box>
                }
                <Buttons ConfirmButtonText={props.rightButtonText}
                    onClickConfirm={props.rightButtonAction}
                    CancelButtonText={props.leftButtonText}
                    onClickCancel={props.leftButtonAction} />

                {/* <div className={classes.btnContainer}>
                    <mainStyles.CustomDynamicLink
                        text_size={12}
                        text_color={theme.palette.button.main}
                        text={props.leftButtonText}
                        type='button'
                        className={classes.leftButton}
                        click={props.leftButtonAction} />
                    <mainStyles.CustomDynamicLink
                        text_size={12}
                        text_color={theme.palette.button.main}
                        text={props.rightButtonText}
                        type='button'
                        className={classes.rightButton}
                        click={props.rightButtonAction} /> 
                </div>*/}
            </div>
        </Dialog>
    )
}