import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next';

import { FormDynamic, Alert } from '../index';
import { fileActions } from '../../store/actions'
import { ForceFormatDate, CurrencyFormat } from '../../utils';

export default function MetadataEditingInfoIndex() {

  const infoFile = useSelector(state => state.FileInfo)
  const editCustomMetData = useSelector((state) => state.GetFileEditCustomMetData)
  const fileUpdateData = useSelector(state => state.FileUpdateMetData)

  const [listEditMetadataCustom, setListEditMetadataCustom] = useState([])
  const [editMetadataCustomSend, setEditMetadataCustomSend] = useState(false);
  const [searchEditMetadata, setSearchEditMetadata] = useState(false)
  const [loadView, setLoadView] = useState(false)
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId')
  let id = infoFile.items.Id

  const { t } = useTranslation();
  const dispatch = useDispatch()

  useEffect(() => {
    if (infoFile.items !== undefined) {
      console.log("Edicion de metadatos useEffect: infoFile.items: ", infoFile.items)
      dispatch(fileActions.GetFileEditCustomMetadata(infoFile.items.Id))
      setSearchEditMetadata(true)
    }
  }, [infoFile.items])


  useEffect(() => {
    console.log("useEffect------setListEditMetadataCustom,----", searchEditMetadata, editCustomMetData)
    if (editCustomMetData.error !== undefined && searchEditMetadata) {
      setLoadView(false)
      setListEditMetadataCustom(null)
      alert(editCustomMetData.error);
    } else if (editCustomMetData.items !== undefined && searchEditMetadata) {
      setLoadView(false)
      setListEditMetadataCustom(editCustomMetData)
    }
  }, [editCustomMetData]);

  useEffect(() => {
    setLoadView(fileUpdateData.loading)
    if (fileUpdateData.items !== undefined && editMetadataCustomSend) {
      Alert({
        icon: "success",
        title: t("successful_registration"),
      });
      setEditMetadataCustomSend(false)
      window.close();
      // getInfoDispatch()
    } else if (fileUpdateData.error !== undefined && editMetadataCustomSend) {
      console.log("fileUpdateData.error: ", fileUpdateData.error)
      setLoadView(false)
      setEditMetadataCustomSend(false)
    }
  }, [fileUpdateData])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
      }}
    >
      {listEditMetadataCustom?.items !== undefined && listEditMetadataCustom?.items !== null ?
        <FormDynamic
          fileId={id}
          EditMetadataCustom={listEditMetadataCustom.items}
          handleSave={(formData, e) => {
            let ListMetdata = []
            console.log("FormDynamic formData: ", formData)
            console.log("FormDynamic editCustomMetData.items: ", listEditMetadataCustom.items?.length, listEditMetadataCustom.items)
            if (listEditMetadataCustom.items?.length > 0) {
              listEditMetadataCustom.items?.forEach(itemGroup => {
                itemGroup?.Questions?.forEach(itemQ => {
                  ListMetdata.push({
                    GroupName: itemGroup.Name,
                    Name: itemQ.Name,
                    Value: itemQ.QuestionType === "Fecha" ?
                      formData[itemQ.Id] === "" ? null : ForceFormatDate(formData[itemQ.Id])
                      : itemQ.QuestionType === "Currency" || itemQ.QuestionType === "CurrencyEuropean" ? CurrencyFormat(formData[itemQ.Id]?.toString(), itemQ.QuestionType)
                        : formData[itemQ.Id],
                    ApprovedConfidence: null,
                    Confidence: null,
                    MetadataType: null,
                    ValueType: null,
                  })
                })
              })
            } else {
              listEditMetadataCustom.items?.questions?.forEach(itemQ => {
                ListMetdata.push({
                  GroupName: itemQ.QuestionGroup,
                  Name: itemQ.Name,
                  Value: itemQ.QuestionType === "Fecha" ?
                    formData[itemQ.Id] === "" ? null : ForceFormatDate(formData[itemQ.Id])
                    : itemQ.QuestionType === "Currency" || itemQ.QuestionType === "CurrencyEuropean" ? CurrencyFormat(formData[itemQ.Id]?.toString(), itemQ.QuestionType)
                      : formData[itemQ.Id],
                  ApprovedConfidence: null,
                  Confidence: null,
                  MetadataType: null,
                  ValueType: null,
                })
              })
            }

            //Metadatao que indica si se va actualizar correo del usuario y enviar ntificacion.
            ListMetdata.push({
              GroupName: "Update",
              Name: "ActiveEmail",
              Value: "1",
              ApprovedConfidence: null,
              Confidence: null,
              MetadataType: null,
              ValueType: null,
            })

            let Request = {
              ObjectId: id,
              Metadata: ListMetdata,
              ContinueWorkFlow: false,
              WorkFlowInstanceId: workFlowInstanceId
            }
            console.log("RequestUpdate metadata 1**********************: ", Request)
            dispatch(fileActions.FileUpdateMetadata(Request))
            setEditMetadataCustomSend(true)
          }}
        />
        : <>Cargando espere...</>}
    </Box>
  )
}
