import { Category, Inject, ChartComponent, ColumnSeries, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts'
import React from 'react'

export default function ChartBarras({ data }) {

    const primaryxAxis = { valueType: 'Category' };

    return (
        <ChartComponent id={data.ChartName} primaryXAxis={primaryxAxis} title={data.ChartName}>
            <Inject services={[ColumnSeries, Legend, LineSeries, Category]} />
            <SeriesCollectionDirective>
                <SeriesDirective dataSource={data?.ChartData} pointColorMapping="HexColor" xName='Name' type='Column' yName='Value' name={data.ChartName} />
            </SeriesCollectionDirective>
        </ChartComponent>
    )
}
