import { Button, Grid, Typography, Paper, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import {
  CustomLoadingOverlay,
  GenericHeaderActions,
  UseImage,
} from "../../components";
import { mainStyles } from "../../theme/mainStyles";
import Empty from "../../assets/NoResultMeet.png";
import { useDispatch, useSelector } from "react-redux";
import { flujoMasivoActions } from "../../store/actions";
import { MainTableDinamyc } from "../../components/table/MainTableDinamyc";
import TableDataDocumentsFilters from "./TableDataDocuments";
import { useTranslation } from 'react-i18next';

export default function InboxDocumentsMasive() {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const info = useSelector((state) => state.GetAllBulkFileData);
  const infoDocumentsZip = useSelector((state) => state.GetBulkFileDetails);
  const workFlowListState = useSelector((state) => state.Authentication?.items?.WorkFlowListData);

  const noInfo = mainStyles.noDataSections();
  const [searching, setSearching] = useState("");
  const [search, setSearch] = useState("");
  const [infoItems, setInfoItems] = useState([]);
  const [dataDocumentsZip, setDataDocumentsZip] = useState([]);
  const [setseletedRow, setSetseletedRow] = useState(null);

  const [currentWorkFlow, setCurrentWorkFlow] = useState(false);

  const { t } =  useTranslation();

  // useEffect(() => {
  //   setDataDocumentsZip([]);
  //   dispatch(flujoMasivoActions.GetAllBulkFile({ Body: "" }));
  //   resetData();
  // }, []);

  useEffect(() => {
    if (info.items !== undefined) {
      console.log("Items-----------: ", info.items);
      setInfoItems(info.items.Body);
    }
  }, [info]);

  useEffect(() => {
    console.log("........workFlowListState....Carga Masiva........: ", workFlowListState)
    if (workFlowListState?.length > 1) {
      setDataDocumentsZip([]);
      setCurrentWorkFlow(workFlowListState)
      dispatch(flujoMasivoActions.GetAllBulkFile(workFlowListState[0]?.WorkFlowId))
      resetData();
    } else if (workFlowListState?.length > 0) {
      setCurrentWorkFlow(workFlowListState[0])
      setDataDocumentsZip([]);
      dispatch(flujoMasivoActions.GetAllBulkFile(workFlowListState[0]?.WorkFlowId))
      resetData();
    }
  }, [workFlowListState])

  const columns = [
    { id: "folio", label: "Folio de carga", minWidth: 100 },
    { id: "name", label: "Nombre archivo zip", minWidth: 170 },
    { id: "etapa", label: "Etapa", minWidth: 170 },
    { id: "date", label: "Fecha de carga", minWidth: 170 },
    { id: "status", label: "Estatus", minWidth: 170 },
    { id: "sincronizados", label: "Sincronizados", minWidth: 100 },
    { id: "pendientes", label: "Pendientes de sincronizar", minWidth: 100 },
  ];

  const newData = infoItems?.map((col) => {
    return {
      folio: col.Id,
      name: col.PathFile,
      etapa: col.Phase,
      date: col.UploadDate,
      status: col.Status,
      sincronizados: col.TotalFilesSynced,
      pendientes: col.TotalFiles - col.TotalFilesSynced,
    };
  });

  const createData = (
    folio,
    name,
    etapa,
    date,
    status,
    sincronizados,
    pendientes
  ) => {
    return { folio, name, etapa, date, status, sincronizados, pendientes };
  };

  const rows = newData?.map((r) => {
    return createData(
      r.folio,
      r.name,
      r.etapa,
      r.date,
      r.status,
      r.sincronizados,
      r.pendientes
    );
  });

  const seletedRow = (dataRow) => {
    setSetseletedRow(dataRow);
    dispatch(
      flujoMasivoActions.GetBulkFileDetails({
        BatchUploadDocumentId: dataRow.folio,
      })
    );
  };

  useEffect(() => {
    if (!!infoDocumentsZip.items?.Body) {
      setDataDocumentsZip(infoDocumentsZip.items.Body);
    }
  }, [infoDocumentsZip, infoDocumentsZip.items]);

  const resetData = () => {
    dispatch(
      flujoMasivoActions.GetBulkFileDetails({
        BatchUploadDocumentId: "",
      })
    );
    setSetseletedRow(null);
    setDataDocumentsZip([]);
  };

  const reloadDataDocument = () => {
    setDataDocumentsZip([]);
    dispatch(
      flujoMasivoActions.GetBulkFileDetails({
        BatchUploadDocumentId: setseletedRow.folio,
      })
    );
  };

  if (dataDocumentsZip.loading === true) {
    return (
      <CustomLoadingOverlay
        active={true}
        text={t("general_loading_message")}
      />
    );
  }

  if (dataDocumentsZip.length > 0) {
    console.log("dataDocumentsZip", dataDocumentsZip);
    return (
      <Grid>
        <TableDataDocumentsFilters
          data={dataDocumentsZip}
          CleanData={() => resetData()}
          ReloadData={() => reloadDataDocument()}
        />
      </Grid>
    );
  }

  return (
    <Grid container>
      {currentWorkFlow?.length > 0 ?
        currentWorkFlow.map((item) =>
          <Grid lg={11}>
            <Paper elevation={4} sx={{ m: 2 }} onClick={() => {
              console.log("Workflow seleccionado: ", item)
              setCurrentWorkFlow(item)
              dispatch(flujoMasivoActions.GetAllBulkFile(item.WorkFlowId))
            }} >
              <Box sx={{ p: 4, display: 'flex' }}>
                <Box sx={{ p: 2, display: "flex", alignContent: 'center', alignItems: 'center' }}>
                  <Avatar
                    style={{
                      color: "primary.mainLyrics",
                      backgroundColor: "primary.main",
                      fontWeight: 600,
                      width: 50,
                      height: 50,
                      fontSize: 3,
                      mt: 0.7,
                    }}
                    alt={'user'}
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Box>
                    <Typography variant='h4'>
                      {item.WorkFlowName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='subtitle'>
                      {item.WorkFlowDescription}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ) :
        <>
          <Grid item lg={12}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                // startIcon={
                //   <Icon fontSize={25} icon="material-symbols:add-circle-outline" />
                // }
                sx={{ height: 45, px: 5, maxWidth: 280 }}
                color="secondary"
                onClick={() => {
                  dispatch(flujoMasivoActions.GetFileStage(currentWorkFlow?.WorkFlowId));
                  navigation('/inbox/uploadDocumentsMasive', { state: { WorkFlowId: currentWorkFlow?.WorkFlowId, WorkFlowName: currentWorkFlow?.WorkFlowName } })
                  // navigation("/inbox/uploadDocumentsMasive");
                }}
              >
                {t("new_charge")}
              </Button>

              {workFlowListState?.length > 1 ?
                <Grid item xs={3} sx={{ marginLeft: '0.5em' }}>
                  <Button
                    variant="outlined"
                    // startIcon={<Icon fontSize={25} icon="ph:users-three" />}
                    sx={{ height: 45, px: 10, maxWidth: 300 }}
                    onClick={() => setCurrentWorkFlow(workFlowListState)} >
                    Regresar
                  </Button>
                </Grid>
                : null}
            </Box>
          </Grid>
          <Grid item lg={12}>
            {info.error && <p>{"Error de red favor de actualizar la pagina"}</p>}
            {info.loading || infoDocumentsZip.loading ? (
              <CustomLoadingOverlay
                active={info.loading || infoDocumentsZip.loading}
                text={t("general_loading_message")}
              />
            ) : infoItems.length > 0 ? (
              <MainTableDinamyc
                OnClickData={seletedRow}
                Rows={rows}
                Columns={columns}
                data={infoItems}
              />
            ) : (
              <div className={noInfo.noResult}>
                <UseImage src={Empty} type="img" style={{ maxWidth: 300 }} />
                <Typography variant="h6" className={noInfo.h6}>
                {t("general_not_request")}
                </Typography>
              </div>
            )}
          </Grid>
        </>
      }
    </Grid>
  );
}
