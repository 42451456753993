
import { presentationCredentialConstants } from '../constants/PresentationCredentialConstants'
import { presentationRequests } from '../../services/PresentationRequest'

export const presentationCredentialActions = {}


presentationCredentialActions.GetPresentation = (data, isReset = false) => {
  console.log('dataToken', data)
  return dispatch => {
    if (isReset) {
      dispatch(reset())
    } else {
      dispatch(request())
      presentationRequests.GetPresentation(data)
        .then(
          data => dispatch(success(data)),
          error => dispatch(failure(error.message))
        )
    }
  }

  function reset() {
    return { type: presentationCredentialConstants.VERIFY_CREDENTIAL_RESET }
  }

  function request() {
    return { type: presentationCredentialConstants.VERIFY_CREDENTIAL_REQUEST }
  }

  function success(object) {
    return { type: presentationCredentialConstants.VERIFY_CREDENTIAL_SUCCESS, object }
  }

  function failure(error) {
    return { type: presentationCredentialConstants.VERIFY_CREDENTIAL_FAILURE, error }
  }

}





