import { Box, Button, Grid, Typography, IconButton, Tooltip, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useNavigate,useLocation } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { useForm, FormProvider } from "react-hook-form";

import { UseImage } from '../../components'
import { UploadDcumentsControl } from '../../components/DocumentUpload/UploadDcumentsControl';
import { useDispatch, useSelector } from 'react-redux';
import { flujoMasivoActions } from '../../store/actions';
import plantillaExcel from '../../assets/PlantillaCargaMasivaGEID.xlsx'
import img from '../../assets/DownloadExcel.png';
import { MainTableDinamyc } from "../../components/table/MainTableDinamyc";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    loader: {
        position: 'absolute !important',
        top: '0 !important',
        bottom: '0 !important',
        left: '0 !important',
        right: '0 !important',
        height: '100vh !important',
        width: '100% !important',
        zIndex: 100000
    },
}));

export default function UploadGEIDMassive(props) {
    const theme = useTheme()
    const location = useLocation()
    const formMethods = useForm({ mode: "onChange" });
    const classes = useStyles(theme)
    const batchFileGEIDResponse = useSelector((state) => state.UploadBatchFileGEIDData);

    const dispatch = useDispatch();
    const navigation = useNavigate();

    const [base64File, setBase64File] = useState(null);
    const [loadView, setLoadView] = useState(null);
    const [data, setData] = useState([]);

    const { t } = useTranslation();

    const newData = data?.map((col) => {
        return {
            Folio: col.Folio,
            Nombre: col.Nombre,
            NombreCargo: col.NombreCargo,
            FechaInicioContrato: col.FechaInicioContrato,
            GEID: col.GEID,
            State: col.State,
            ErrorToolTip: col.ErrorToolTip,
        };
    });

    const columns = [
        { id: "Folio", label: t("table_geid_folio"), minWidth: 170 },
        { id: "Nombre", label: t("table_name"), minWidth: 170 },
        { id: "NombreCargo", label: t("table_geid_job_name"), minWidth: 170 },
        { id: "FechaInicioContrato", label: t("table_geid_hiring_date"), minWidth: 170 },
        { id: "GEID", label: t("main_geid"), minWidth: 170 },
        { id: "State", label: t("table_status"), minWidth: 170 },
        { id: "ErrorToolTip", label: t("table_geid_message"), minWidth: 170 },
    ];

    const createData = (
        Folio,
        Nombre,
        NombreCargo,
        FechaInicioContrato,
        GEID,
        State,
        ErrorToolTip
    ) => {
        return {
            Folio,
            Nombre,
            NombreCargo,
            FechaInicioContrato,
            GEID,
            State,
            ErrorToolTip
        };
    };

    const rows = newData?.map((r) => {
        return createData(
            r.Folio,
            r.Nombre,
            r.NombreCargo,
            r.FechaInicioContrato,
            r.GEID,
            r.State,
            r.ErrorToolTip
        );
    });

    const handleFileInputChange = (fileContent, fileBase64, extensionFile) => {
        console.log("*************fileContent*********-----: ", fileContent)
        console.log("*************FileContent******fileBase64***-----: ", fileBase64)
        console.log("*************FileContent******extensionFile***-----: ", extensionFile)
        setBase64File(fileBase64)
    };

    useEffect(() => {
        console.log("location WorkFlowId : ",location.state?.WorkFlowId, " - ", location)
        if (base64File !== null && base64File?.length > 1) {
            setLoadView(true)
            dispatch(flujoMasivoActions.UploadBatchRegisterGEID({
                "FileContentB64": base64File,                
                "WorkflowId": location.state?.WorkFlowId
            }))
        }
    }, [base64File])


    useEffect(() => {
        setLoadView(batchFileGEIDResponse.loading)
        console.log("************batchFileGEIDResponse---------********: ", batchFileGEIDResponse)
        if (batchFileGEIDResponse.items != null && batchFileGEIDResponse.items.length > 0) {
            setData(batchFileGEIDResponse.items)
        } else if (batchFileGEIDResponse.error != null) {
            setData([])
        }
    }, [batchFileGEIDResponse])

    return (<>
        {loadView && <LoadingOverlay
            active={loadView}
            spinner
            text={t("general_loading_message")}
            className={classes.loader}>
        </LoadingOverlay>}
        {base64File !== null ?
            <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                <Grid container spacing={2}>
                    <Grid item xs={7.5}>
                        {/* Content for the first column */}
                        {/* You can put your components or content here */}
                    </Grid>
                    <Grid item xs={3}>
                        <Button style={{
                            height: 38,
                            width: '100%',
                            margin: '1em',
                            borderRadius: '25px',
                            fontSize: 16,
                            fontWeight: 500
                        }} variant='contained' color="primary"
                            onClick={() =>
                                setBase64File(null)
                            }>
                            {t("general_new")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item lg={10} xs={12}>
                    <MainTableDinamyc
                        Rows={rows}
                        Columns={columns}
                    // OnClickData={OnClickData}
                    />
                </Grid>
            </Grid>
            :
            <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                <Grid item lg={10} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={t("geid_download_template")} placement="top">
                                <a href={plantillaExcel} target="_blank" rel="noopener noreferrer" download="Plantilla Carga Masiva.xlsx">
                                    <IconButton xs={{ width: 60 }}>
                                        <UseImage src={img} type='img' />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={10} xs={12}>
                    <Box textAlign={'justify'} display="flex" alignItems={'center'}>
                        <Typography variant='subtitle'>
                            {t("geid_load_your_excel_document")}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={10} xs={12}>
                    <FormProvider {...formMethods}>
                        <UploadDcumentsControl
                            Excel={true}
                            Title={t("general_upload_your_file_xlsx")}
                            onChange={(fileContent, fileBase64, extensionFile) => handleFileInputChange(fileContent, fileBase64, extensionFile)}
                        />
                    </FormProvider>
                </Grid>               
            </Grid>
        }
    </>)
}
