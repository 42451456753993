import React, { useEffect, useState, useRef } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Pagination as Pag,
  PaginationItem,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Button,
  Checkbox,
  IconButton,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Edit,
  FiberManualRecord,
  Visibility,
} from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { FormProvider, useForm } from "react-hook-form";
import { documentRequests } from "../services";
import { ModalVisualPDF } from "./modals/ModalVisualPDF";
import { CustomLoadingOverlay } from "./CustomLoadingOverlay";
import { useTranslation } from "react-i18next";

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Pagination = styled(Pag)(({ theme }) => ({
  "& ul": { justifyContent: "flex-end" },
  "& ul li button": { fontSize: "14px" },
  "& .Mui-selected": {
    backgroundColor: (theme) => `${theme.palette.secondary.main40} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    "& ul": { justifyContent: "center" },
  },
}));

export const GenericTableSelection = (props) => {
  const buttonSubmit = useRef();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const formMethods = useForm();
  const { handleSubmit } = formMethods;
  const [selectedRows, setSelectedRows] = useState([]);

  const stopClick = (event) => {
    event.stopPropagation();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns: props.Columns,
      data: props.Data,
      title: "",
      initialState: {
        pageIndex: 0,
        hiddenColumns: ["stage"],
        selectedRowIds: props.Data.reduce((acc, row, index) => {
          if (row.Active === true) {
            acc[index] = true;
          }
          return acc;
        }, {}),
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) =>
            props.SelectedAll === true ? (
              <Checkbox
                sx={{
                  color: "#222222", // Cambia el color
                  "&.Mui-checked": {
                    color: "#4ECB71", // Cambia el color cuando el checkbox está marcado
                    background: "#FFFFFF",
                  },
                  "&.MuiCheckbox-indeterminate": {
                    color: "#4ECB71", // Cambia el color cuando el checkbox está en estado indeterminado
                    background: "#FFFFFF",
                  },
                  // marginLeft: "0.5px",
                }}
                {...getToggleAllRowsSelectedProps()}
              />
            ) : null,
          Cell: ({ row }) =>
            row.original.userCreation === true ? (
              <Checkbox
                onClick={(e) => stopClick(e)}
                sx={{
                  color: "#222222", // Cambia el color
                  "&.Mui-checked": {
                    color: "#4ECB71", // Cambia el color cuando el checkbox está marcado
                  },
                }}
                {...row.getToggleRowSelectedProps()}
              />
            ) : row.original.userCreation === false ? null : (
              <Checkbox
                onClick={(e) => stopClick(e)}
                sx={{
                  color: "#222222", // Cambia el color
                  "&.Mui-checked": {
                    color: "#4ECB71", // Cambia el color cuando el checkbox está marcado
                  },
                }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
        },
        ...columns,
        {
          id: "view",
          Header: () => "Ver",//"Ver/Editar"
          Cell: ({ row }) => {
            return (
              [<ViewDoc data={row} />,
              //<EditDoc data={row} />
            ])
          },
        },
      ]);
    }
  );

  useEffect(() => {
    if (props.sendDataActive) {
      props.setSendDataActive(null);
      buttonSubmit.current.click();
    }
  }, [props.sendDataActive]);

  useEffect(() => {
    setSelectedRows(selectedFlatRows.map((row) => row.original));
    if (props?.ClickSelectedRows !== undefined) {
      props.ClickSelectedRows(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  return (
    <FormProvider {...formMethods}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <TableContainer
            component={Paper}
            elevation={5}
            sx={{ height: "50vh" }}
          >
            <Table {...getTableProps()} size="small">
              <TableHead
                sx={{
                  backgroundColor: "primary.main",
                  position: "sticky",
                }}
              >
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        sx={{
                          padding: 0.8,
                          paddingLeft: 2,
                        }}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: undefined })
                        )}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            minWidth:
                              column.id == "selection"
                                ? 10
                                : column.Header === "Folio"
                                ? 30
                                : column.Header === "Tipo de Solicitud"
                                ? 30
                                : 200,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: 14,
                              minWidth: column.id == "selection" ? 10 : 80,
                              fontWeight: 500,
                              color: "primary.mainLyrics",
                            }}
                          >
                            {column.render("Header")}
                          </Typography>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  // console.log("----++row table++---: ", row)
                  return (
                    <TableRowStyled
                      {...row.getRowProps()}
                      onClick={() => {
                        props.onClickRow(row);
                      }}
                      sx={{
                        borderBottom: "none !important",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      {row.cells.map((cell, index) => {
                        if (cell.column.id === "status") {
                          return (
                            <TooltipTableCell
                              row={row}
                              statusVigencia={cell.row.original.statusVigencia}
                              cell={cell}
                              key={cell.column.id}
                              enterTouchDelay={0}
                            />
                          );
                        }
                        if (cell.column.id === "estatus") {
                          return (
                            <TooltipTableCellEstatus
                              row={row}
                              statusRow={cell.row.original.estatus}
                              cell={cell}
                              key={cell.column.id}
                              enterTouchDelay={0}
                            />
                          );
                        }
                        if (cell.column.id === "statusError") {
                          return null;
                        }
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            sx={{
                              /* padding: 0.8,
                              paddingLeft: 2, */
                              padding: "0px 20px 0px 0px !important",
                              borderBottom: "none",
                              fontSize: "14px !important",
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {props.ShowPagination === undefined ||
        props.ShowPagination === null ||
        props.ShowPagination === true ? (
          <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              px: { xs: 0, sm: 1 },
              mt: 2,
            }}
          >
            <Box sx={{ mr: 5 }}>
              <Typography variant="caption">
                Total de solicitudes: {props.TotalRows}
              </Typography>
            </Box>

            <Pagination
              shape="rounded"
              count={props.TotalPages}
              page={props.PageCurrent}
              // onChange={handleChangePage}
              onChange={(event, page) => {
                props.onClickChangePage(event, page);
              }}
              siblingCount={xsScreen ? 0 : 1}
              boundaryCount={xsScreen ? 0 : 1}
              size={xsScreen ? "small" : "medium"}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: PreviusPag, next: NextPag }}
                  {...item}
                />
              )}
            />
          </Box>
        ) : null}
        <Button
          id={`btn_Save`}
          ref={buttonSubmit}
          onClick={handleSubmit(props.HandleSendData)}
          color="primary"
          style={{ marginTop: "0.5em", display: "none" }}
        >
          Send Data
        </Button>
      </LocalizationProvider>
    </FormProvider>
  );
};

const PreviusPag = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
      }}
    >
      <Box sx={{ mt: 0.5 }}>
        <ArrowBackIos sx={{ fontSize: "14px" }} />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>Anterior</Box>
    </Box>
  );
};

const NextPag = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>Siguiente</Box>
      <Box sx={{ mt: 0.5 }}>
        <ArrowForwardIos sx={{ fontSize: "14px" }} />
      </Box>
    </Box>
  );
};
const TooltipTableCell = (props) => {
  const [open, setopen] = useState(false);
  const tooltipTitle = (
    <Typography variant="caption">{props.row.original.stage}.</Typography>
  );

  const colorStatus = () => {
    if (props.statusVigencia === "EnTiempo") {
      return "success.dark";
    } else if (props.statusVigencia === "PorVencer") {
      return "#FFDB7E";
    } else if (props.statusVigencia === "Vencido") {
      return "primary.main";
    }
  };

  return (
    <TableCell
      {...props.cell.getCellProps()}
      sx={{ borderBottom: "none", padding: 1 }}
      onMouseEnter={() => setopen(true)}
      onMouseLeave={() => setopen(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        {props.cell.column.id === "status" && (
          <FiberManualRecord
            sx={{
              mb: "-1px",
              mr: 1,
              color: colorStatus(),
              fontSize: 13,
            }}
          />
        )}
        {props.cell.render("Cell")}
      </Box>
    </TableCell>
  );
};

const TooltipTableCellEstatus = (props) => {
  const [open, setopen] = useState(false);
  // console.log("props.row.original", props.row.original);
  const tooltipTitle = (
    <Typography variant="caption">
      {props.row.original.statusError ??
        "Los datos no estan completos, todos los datos son requeridos."}
    </Typography>
  );

  return (
    <TableCell
      {...props.cell.getCellProps()}
      sx={{ borderBottom: "none", padding: 1 }}
      onMouseEnter={() => setopen(true)}
      onMouseLeave={() => setopen(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        {props.cell.column.id === "estatus" && props.statusRow === false ? (
          <Icon
            icon="el:ok-circle"
            style={{
              mb: "-1px",
              mr: 1,
              fontSize: 13,
              color: "#0FA958",
            }}
          />
        ) : (
          <Icon
            icon="zondicons:close-outline"
            style={{
              mb: "-1px",
              mr: 1,
              fontSize: 13,
              color: "#FF4842",
            }}
          />
        )}
        {props.cell.render("Cell")}
      </Box>
    </TableCell>
  );
};

const ViewDoc = ({data}) => {
  const [fileUrl, setfileUrl] = useState(null)
  const [open, setOpen] = useState(false);
  const [loadView, setLoadView] = useState(false);
  const { t } = useTranslation();

  const handleClose = (event) => {
    setOpen(false);
  };

  const getDocument = async (data) => {
    try {
      setLoadView(true);
      const responseDoc =
        await documentRequests.GetDownloadDocumentGeneratorSelectionContent(
          data.original.DocumentGeneratorSelection_Id
        );
        setLoadView(false);
        console.log("responseData", responseDoc)
      if (responseDoc.data) {
        setOpen(true);
        setfileUrl(responseDoc.data);
      }
    } catch (error) {
      console.log("error", error);
      setLoadView(false);
    }
  };

  return (
    <>
      {loadView && (
        <CustomLoadingOverlay
          active={loadView}
          text={t("general_loading_message")}
        />
      )}
      <IconButton
        id="btnViewDocKit"
        sx={{ mx: 1 }}
        onClick={() => getDocument(data)}
      >
        <Visibility />
      </IconButton>
      <ModalVisualPDF open={open} handleClose={handleClose} pdfUrl={fileUrl} />
    </>
  );
}
const EditDoc = ({data}) => {
  const [fileUrl, setfileUrl] = useState(null)
  const [open, setOpen] = useState(false);
  const [loadView, setLoadView] = useState(false);

  const { t } = useTranslation();
  
  const handleClose = (event) => {
    setOpen(false);
  };

  const editDocument = async (data) => {
    try {
      setLoadView(true);
      const docId = data.original.DocumentGeneratorSelection_Id;
      const token = sessionStorage.getItem('tokenMc');
      const responseDoc =
        await documentRequests.DownloadDocumentViewerContent(docId);
        setLoadView(false);
        console.log("responseDataEdit", responseDoc)
        if (responseDoc.data) {
          window.open(`https://obmlifedev-visor.techhub.mx/?docId=${docId}&token=${token}`, '_blank');
          //setOpen(true);
          //setEditable(true);
          //setfileUrl(responseDoc.data);
        }
    } catch (error) {
      console.log("error", error);
      setLoadView(false);
    }
  };

  return (
    <>
      {loadView && (
        <CustomLoadingOverlay
          active={loadView}
          text={t("general_loading_message")}
        />
      )}
      <IconButton
        id="btnEditDocKit"
        sx={{ mx: 1 }}
        onClick={() => editDocument(data)}
      >
        <Edit />
      </IconButton>
      <ModalVisualPDF open={open} handleClose={handleClose} pdfUrl={fileUrl} />
    </>
  );
}