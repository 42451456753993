import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { Button, Typography, Grid, Box, Hidden, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm, FormProvider } from "react-hook-form";
import { HubConnectionBuilder } from '@microsoft/signalr';
import imageLoad from '../../assets/Loading_Overlay.gif'

import ImageLogin from '../../assets/FodoMetlifeFull.jpg'
import { authActions } from '../../store/actions'
import { UseImage, Alert, InputField } from '../../components/'
import { obtenerDireccionIP } from '../../utils'
import QRCode from 'qrcode.react';
import { authRequests } from '../../services';

import LogoAthenticator from '../../assets/authenticator_metLifeIcon.png'

import { getHash } from '../../utils'
import packageJson from "../../../package.json";
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'absolute !important',
    top: '0 !important',
    bottom: '0 !important',
    left: '0 !important',
    right: '0 !important',
    height: '100vh !important',
    width: '100% !important',
  },
  containerImagen: {
    height: "100vh",
  },
  imagenIlustrativa: {
    height: "100%",
    width: "100%",
    backgroundRepeat: 'none',
    backgroundSize: '100%',
    objectFit: 'cover',
    transform: 'scaleX(-1)'
  },
  loginBox: {
    top: '18%',
    width: '35%',
    height: '58%',
    right: '2%',
    overflow: 'auto',
    position: 'absolute',
    background: '#FFFFFF',
    boxShadow: '0px 4px 100px 0px rgba(138, 138, 138, 0.25)',
    borderRadius: 63,
    padding: '25px 35px',
    '@media only screen and (max-width: 600px)': {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    '@media only screen and (min-height: 900px)': {
      height: '55%',
    },
    '@media only screen and (max-height: 899px)': {
      top: '8%',
      height: '85%',
    },
    '@media only screen and (min-height: 2501px)': {
      top: '10%',
      height: '60%',
    },
  },
  containerForm: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  form: {
    display: "flex",
    justifyContent: 'space-evenly',
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
}));

export const iniciarConexionSignalR = () => {
  const authenticatorUrl = process.env.REACT_APP_URL_VERIFY_CREDENTIALS;

  const connection = new HubConnectionBuilder()
    .withUrl(authenticatorUrl)
    .build();

  connection.start().catch(error => console.error(error));

  return connection;
};

export default function DownloadCredentials(props) {
  const theme = useTheme()
  const formMethods = useForm({ mode: "onChange" });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenLink = params.get('token');

  const [loadView, setLoadView] = useState(false)
  const [tokenSR, setIToken] = useState("")
  const [tokenIsUser, setTokenIsUser] = useState("") //token que se obtiene de la url que se genera a registrar credencial authenticator -- Pneidnte de logica de llenado

  const [QRValue, setQRValue] = useState("")
  /*  const [IsQRActive, setIsQRActive] = useState(false)
   const [IsQROnScan, setIsQROnScan] = useState(false) */
  const [IsIssueSuccess, setIsIssueSuccess] = useState(false)
  const [MsgScanQR, setMsgScanQR] = useState("")
  const { t } = useTranslation();

  const [qrValueVC, setQRValueVC] = useState("Credenciales Verificadas")

  const [isVisibleQR, setIsVisibleQR] = useState(1);

  const classes = useStyles(theme)

  // const fieldStyles = null //mainStyles.useStylesField()
  const navigation = useNavigate()
  const { id } = useParams()


  //Redux hooks
  const auth = useSelector(state => state.Authentication)

  const dispatch = useDispatch()


  useEffect(() => {
    const conn = iniciarConexionSignalR();
    conn.on("ReceiveMessageGroup", (data) => {
      setIToken(data);
      console.log('dataAuth 01', data);
    });
    conn.on("ReceiveMessage", (data) => {
      console.log('dataAuth 02', data);
      const objetoJson = JSON.parse(data);
      console.log(objetoJson);
      if (objetoJson.requestStatus == "request_retrieved") {
        setIsVisibleQR(2);
        console.log('dataAuth 0221', data);
      } else if (objetoJson.requestStatus == "issuance_successful") {
        setIsVisibleQR(3);
      }
      else {
        console.log('authauth 00', objetoJson.Body)
        /* const AccessToken = objetoJson.Body.Token;
        localStorage.setItem('AccessToken', AccessToken); */
        //navigation('/inbox/homeComponent');
        sessionStorage.clear()
        sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
          'tokenMc',
          objetoJson.Body.Token
        )
        console.log('authauth 000', objetoJson)
        sessionStorage.setItem(//Borrar al cerrar sesión
          'userName',
          `${objetoJson.Body.UserLoginData.Name} ${objetoJson.Body.UserLoginData.FatherLastName} ${objetoJson.Body.UserLoginData.MotherLastName}`
        )
        // console.log("Role: ", userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name)
        sessionStorage.setItem(//Borrar al cerrar sesión
          'Rol',
          `${objetoJson.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
        )
        console.log('authauth 001', objetoJson)
        dispatch(authActions.AuthenticationQA(objetoJson))
      }
    });
  }, []);

  const DownloadCredential = async () => {
    setLoadView(true);
    console.log('dataAuth 03');
    console.log('tokenLink', tokenSR)
    try {
      const responseDoc = await authRequests.AuthenticatorGeneraCredential(tokenLink, tokenSR).then((data) => {
        console.log('dataAuth 04', data);
        console.log('AuthenticatorGeneraCredential data', data)
        if (data.Error == null) {
          console.log('dataAuth 05', data);
          setQRValue(data.RequestVerifyResult.url)
          /* setIsQRActive(true)
          setIsQROnScan(false) */
          setLoadView(false);
          setIsVisibleQR(0)
          setMsgScanQR("Escanea el siguiente QR con la aplicación de Microsoft Authenticator para descargar tu credencial digital")
        }
        else {
          setLoadView(false);
          console.log('dataAuth 06', data);
          console.log(data.Error);
          Alert({
            icon: 0,
            title: data.Error.Error,
          });
        }
      })
      setLoadView(false);
      console.log('responseDoc', responseDoc)
    } catch (error) {
      setLoadView(false);
      console.log('responseDoc', error)
    }

  }

  return (
    <FormProvider {...formMethods} theme={theme}>
      {loadView && <LoadingOverlay
        active={loadView}
        spinner
        text={t("general_loading_message")}
        className={classes.loader}>
      </LoadingOverlay>}
      <Grid container>
        <Hidden smDown>
          <Grid item lg={12} md={12}>
            <Box className={classes.containerImagen}>
              <UseImage src={ImageLogin} type='img' className={classes.imagenIlustrativa} />
            </Box>
          </Grid>
        </Hidden>
        <Box className={classes.loginBox} >
          <Box className={classes.containerForm}>
            {isVisibleQR == 0 ?
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh"
              }}>
                <Typography variant="subtitle" gutterBottom>
                  Escanea el siguiente código QR para descargar tu credencial</Typography>
                <Box sx={{ mt: 4 }} style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                  <QRCode size={290} value={QRValue} />
                </Box>
              </Box>
              : isVisibleQR == 1 ?
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh"
                }}>
                  <img src={LogoAthenticator} />
                  <Typography variant="h3">Bienvenido</Typography>
                  <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ height: 45, px: 10 }}
                      type={'button'}
                      onClick={DownloadCredential}
                    >
                      Descargar mi credencial digital
                    </Button>
                  </Box>
                </Box>
                : isVisibleQR == 2 ?
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: 'center',
                    height: "40vh"
                  }}>
                    <Box sx={{ display: "flex", alignItems: 'center', mt: 4, mb: 4 }}>
                      <UseImage src={imageLoad} type='img' />
                    </Box>
                    <Typography variant="h5">Estamos procesando tu credencial, para continuar, autoriza el permiso en Authenticator</Typography>
                  </Box>
                  : isVisibleQR == 3 ?
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: 'center',
                      height: "40vh"
                    }}>
                      <img src={LogoAthenticator} />
                      <Typography variant="h5">Tu credencial se ha guardado de manera éxitosa</Typography>
                      <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{ height: 45, px: 10 }}
                          type={'button'}
                          onClick={() => navigation("/")}
                        >
                          Regresar
                        </Button>
                      </Box>
                    </Box> : null}
          </Box>
        </Box>
        <Box sx={(theme) => ({
          height: '35px',
          position: 'absolute;',
          backgroundColor: `${theme.palette.footerBar.main} !important`,
          width: '100%',
          color: '#FFF',
          bottom: 0,
          ['@media only screen and (max-width: 600px)']: {
            width: '100%',
          },
        })} >
          <Typography variant="h6" gutterBottom
            sx={(theme) => ({
              width: '100%',
              textAlign: 'center',
              paddingTop: '7px',
              letterSpacing: '0.53px',
              color: `${theme.palette.footerBar.text} !important`,
              opacity: '1',
              fontSize: '16px',
              margin: 'inherit !important',
              marginBottom: '0px !important',
              paddingBottom: '0px !important',
            })} >{`${packageJson.textDescription} Versión: ${packageJson.version}`}</Typography>
        </Box>
      </Grid >
    </FormProvider >)
}