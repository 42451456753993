import React, { useEffect, useState } from 'react'
import { Typography, Button, Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FormProvider, useForm } from "react-hook-form";

import {
    InputField,
    CustomLoadingOverlay, Alert
} from '../index'
import { constPathRoot, ValidarSICumpleCondiciones, ForceFormatDate, CurrencyFormat } from '../../utils';
import { executeSearchRequests } from '../../services'
import { NumeroALetras } from './NumALetras';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export function FormDynamic(props) {
    const formMethods = useForm();
    const { control, handleSubmit, watch, setValue, formState, register, clearErrors, setFocus, formState: { errors, submitCount }, } = useForm({ mode: 'onBlur' });
    const [loadView, setLoadView] = useState(false)//Se muestra/oculta el gif loader   
    const [properties, setProperties] = useState('');
    const [initLoad, setInitLoad] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [dataControls, setDataControls] = useState([]);

    const [expanded, setExpanded] = useState("")
    let path = window.location.pathname

    const { t } = useTranslation();

    useEffect(() => {
        const firstError = Object.keys(errors).reduce((field, a) => {
            // console.log("Erros********Forms Hooks------***----**---*field, a: ", field, a)
            return !!errors[field] ? field : a;
        }, null);

        if (firstError) {
            // console.log("First error: ", firstError)
            setExpanded(false);
            let textData = questions.filter(itemGroup => itemGroup.questions.filter(itemQ => itemQ.Id == firstError).length > 0)
            setExpanded(textData[0].llave);
            setFocus(firstError);
        }
    }, [errors, setFocus, submitCount]);


    useEffect(() => {
        if (props.StageCurrent?.Stage !== undefined && props.StageCurrent?.Stage !== null) {
            // console.log("props.StageCurrent*****************", props.StageCurrent)
            setProperties(props.StageCurrent.Stage)
        }
    }, [props.StageCurrent])

    useEffect(() => {
        // console.log("props.DataControls*****************", props, props.DataControls?.QuestionDefinitions)
        if (props.DataControls !== undefined && props.DataControls !== null) {
            setQuestions([])
            let newJson = [{
                llave: props.DataControls?.Id,
                groupTitle: props.DataControls?.Name,
                description: props.DataControls?.Description,
                questions: props.DataControls?.QuestionDefinitions
            }]

            // console.log("new json: ", newJson)
            setQuestions(newJson)
            setDataControls(newJson)
        }
    }, [props.DataControls])

    useEffect(() => {
        if (properties !== "") {
            // console.log("properties*******: ", properties.Description, properties)
            if (properties.Description !== undefined) {
                console.log('ARRAYDATA', questions)
                setQuestions([])
                let newArray = setGroupNames(properties.QuestionGroups)
                // console.log('TOMAESTO', newArray)
                setQuestions(newArray)
                setDataControls(newArray)
            }
        }
    }, [properties]);


    useEffect(() => {
        if (props.EditMetadataCustom !== undefined && props.EditMetadataCustom !== null) {
            setQuestions([])
             console.log("properties*****props.EditMetadataCustom**: ", props.EditMetadataCustom)

            let newArray = props.EditMetadataCustom?.groupTitle != null && 
            props.EditMetadataCustom?.groupTitle != undefined ?
            [{...props.EditMetadataCustom}]
             : setGroupNames(props.EditMetadataCustom)

            setQuestions(newArray)
            setDataControls(newArray)

            // let newJson = [props.EditMetadataCustom]
            // setQuestions(newJson)
            // setDataControls(newJson)
        }
    }, [props.EditMetadataCustom])

    useEffect(() => {
        if (questions?.length > 0) {
            let listDocumentFilter = questions?.map(item => item.questions.filter(itemDoc => itemDoc.Value != undefined && itemDoc.Value != null && itemDoc.Value != ''))
            listDocumentFilter.forEach(itemGroup => {
                itemGroup?.forEach(itemQuestion => {
                    if (itemQuestion.QuestionType == "Fecha") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion.Metadata?.Value))
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion?.Value))
                        }
                    } else if (itemQuestion.QuestionType === "FechaPrevia" || itemQuestion.QuestionType === "FechaPosterior") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion.Metadata?.Value))
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion?.Value))
                        }
                    } else if (itemQuestion.QuestionType === "Radio") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value)
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, itemQuestion?.Value)
                        }
                    } else if (itemQuestion.QuestionType === "AutoComplete") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value)
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, itemQuestion?.Value)
                        }
                    } else {
                        setValue(`${itemQuestion.Id}`, itemQuestion.Value);
                    }
                });
            });

            if (expanded == "") {
                setExpanded(questions.length > 0 ? questions[0].llave : false);
            }
            setInitLoad(false)

        }
    }, [questions])


    const setGroupNames = (array) => {
        console.log('arrayData ------ 1', array, questions)
        const newGroup = array?.map((group) => {
            return {
                groupTitle: group.Name,
                description: group.Description,
                // folio: folio,
                llave: group.Id,
                questions: group.Questions.map((qn) => {
                    // console.log("value date: ", Date.now(), qn)
                    if (qn.QuestionType === "Fecha") {
                        // console.log("value date set : ", Date.now(), qn)
                        setValue(`${qn.Name}`, Date.now())
                    }

                    return {
                        ...qn,
                        GroupName: group.Name,
                        Name: qn.Name,
                        Value: qn.Metadata !== null ? qn.Metadata?.Value : '',
                        QuestionType: qn.QuestionType,
                        Options: qn.Options,
                        Required: qn.Required,
                        Id: qn.Id,
                        DefaultValue: null,
                        Visible: qn?.Metadata?.Value !== null ? true : false,
                        Captured: null
                    }
                })
            }
        })

        console.log('arrayData ------ 2', newGroup)
        return newGroup
    }

    let UpdateCtrlQuestionsAsync = async (itemCurrent, valueParam) => {
        if (questions.length == 0) return null
        // console.log('arrayData ------ 4')
        // console.log('itemCurrentCombo', itemCurrent, questions)
        try {
            if (itemCurrent.ExecuteSearchFunction === "GetCurrencyLetter") {
                if (itemCurrent.QuestionType === "CurrencyEuropean") {

                    const textoModificado = CurrencyFormat(valueParam, itemCurrent.QuestionType)//valueParam?.replace(/\./g, '')?.replace(/,/g, '.')?.replace(/\$/g, '');
                    const flotanteConvertido = parseFloat(textoModificado);
                    console.log("textoModificado--------1---CurrencyFormat: ", textoModificado)

                    let arrayOriginal = questions;
                    let valueNumLetra = arrayOriginal.find((doc) => doc.groupTitle == itemCurrent.ExecuteSearchDefinition[0].AssignQuestion.QuestionGroup).questions.find((itemQ) => itemQ.Name == itemCurrent.ExecuteSearchDefinition[0].AssignQuestion.Question)?.Id
                    if (!!flotanteConvertido) {
                        setValue(`${valueNumLetra}`, NumeroALetras(flotanteConvertido, t))
                    } else {
                        setValue(`${valueNumLetra}`, '')
                    }

                    const productosAplican = [
                        "Contratación ML Chile Masivo",
                        "Contratación ML Chile Estándar",
                        "Contratación ML Chile Seguros",
                        "Contratación ML Chile Provida",
                        "Contratación PROVIDA SPEN"
                    ];

                    let dataGratificacion = questions.find((itemG) => itemG.groupTitle.toUpperCase().toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "GRATIFICACIÓN (%)")

                    if (dataGratificacion?.Name.toUpperCase() == "GRATIFICACIÓN (%)" && props.StageCurrent !== undefined && props.StageCurrent !== null && productosAplican.includes(props.StageCurrent?.Product)) {
                        let optionCombo = watch(`${dataGratificacion.Id}`)
                        let idGratificacion = questions.find((itemG) => itemG.groupTitle.toUpperCase().toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "GRATIFICACIÓN")?.Id
                        let idGratificacionTexto = questions.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "GRATIFICACIÓN EN TEXTO")?.Id
                        let idSueldoBruto = questions.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO TOTAL")?.Id
                        let idSueldoBrutoTexto = questions.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO - TOTAL EN TEXTO")?.Id

                        if (optionCombo?.toUpperCase() == "25% SOBRE SALARIO BASE" || optionCombo?.toUpperCase() == "50% SOBRE SALARIO BASE") {
                            let salarioMoneda = watch(`${questions.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == 'SALARIO EN MONEDA')?.Id}`)
                            console.log("salarioMoneda--------2---CurrencyFormat: ", salarioMoneda, itemCurrent.QuestionType)
                            const textoModificado = CurrencyFormat(salarioMoneda, itemCurrent.QuestionType)  //salarioMoneda?.replace(/\./g, '')?.replace(/,/g, '.')?.replace(/\$/g, '');

                            console.log("textoModificado--------2---CurrencyFormat: ", textoModificado)
                            const flotanteConvertido = parseFloat(textoModificado);
                            console.log("flotanteConvertido--------2---CurrencyFormat: ", flotanteConvertido)

                            let porcentajeATomar = optionCombo?.toUpperCase() == "50% SOBRE SALARIO BASE" ? .50 : .25;
                            let newGratificacion = (flotanteConvertido * porcentajeATomar);
                            console.log("newGratificacion--------2---CurrencyFormat: ", newGratificacion)
                            const flotanteGratificacion = parseFloat(newGratificacion);
                            console.log("flotanteGratificacion--------1---CurrencyFormat: ", flotanteGratificacion)
                            let sueldoBrutoTotal = (flotanteGratificacion + flotanteConvertido)
                            console.log("sueldoBrutoTotal--------1---CurrencyFormat: ", sueldoBrutoTotal)

                            if (idGratificacion !== undefined && idGratificacion !== null) {
                                setValue(`${idGratificacion}`, newGratificacion)
                            }
                            if (idGratificacionTexto !== undefined && idGratificacionTexto !== null) {
                                setValue(`${idGratificacionTexto}`, NumeroALetras(flotanteGratificacion, t))
                            }
                            if (idSueldoBruto !== undefined && idSueldoBruto !== null) {
                                setValue(`${idSueldoBruto}`, sueldoBrutoTotal)
                            }
                            if (idSueldoBrutoTexto !== undefined && idSueldoBrutoTexto !== null) {
                                setValue(`${idSueldoBrutoTexto}`, NumeroALetras(sueldoBrutoTotal, t))
                            }

                        } else if (optionCombo?.toUpperCase() == "GRATIFICACIÓN LEGAL" || optionCombo == "Gratificación Legal") {

                            const productosAplican = [
                                "Contratación ML Chile Masivo",
                                "Contratación ML Chile Estándar",
                                "Contratación ML Chile Seguros",
                                "Contratación ML Chile Provida",
                                "Contratación PROVIDA SPEN"
                            ];

                            if (productosAplican.includes(props.StageCurrent?.Product)) {
                                let salarioMoneda = watch(`${questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == 'SALARIO EN MONEDA')?.Id}`)
                                let gratificacionMILA = watch(`${questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => (item.Name.toUpperCase() == 'GRATIFICACIÓN VENTAS / MILA'))?.Id}`)
                                let idGratificacionTexto = questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == "GRATIFICACIÓN EN TEXTO").Id
                                let idSueldoBruto = questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO TOTAL")?.Id
                                let idSueldoBrutoTexto = questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO - TOTAL EN TEXTO")?.Id

                                const textoModificado = CurrencyFormat(salarioMoneda, itemCurrent.QuestionType) //salarioMoneda?.replace(/\./g, '')?.replace(/,/g, '.')?.replace(/\$/g, '');
                                console.log("textoModificado--------3---CurrencyFormat: ", textoModificado)
                                const textoModificadoMila = CurrencyFormat(gratificacionMILA, itemCurrent.QuestionType) //gratificacionMILA?.replace(/\./g, '')?.replace(/,/g, '.')?.replace(/\$/g, '');
                                console.log("textoModificadoMila--------4---CurrencyFormat: ", textoModificado)
                                const sueldoBaseConvertido = parseFloat(textoModificado);
                                const flotanteGratificacion = parseFloat(textoModificadoMila);
                                const newCalculoBruto = (flotanteGratificacion + sueldoBaseConvertido)
                                const floatNewCalculoBruto = parseFloat(newCalculoBruto);
                                console.log("textoModificadoMila--------4---idSueldoBruto: ", floatNewCalculoBruto)
                                setValue(`${idGratificacionTexto}`, NumeroALetras(flotanteGratificacion, t))
                                setValue(`${idSueldoBruto}`, floatNewCalculoBruto)
                                setValue(`${idSueldoBrutoTexto}`, NumeroALetras(floatNewCalculoBruto, t))
                            }

                        } else {
                            if (idSueldoBruto !== undefined && idSueldoBruto !== null) {
                                setValue(`${idSueldoBruto}`, null)
                            }
                            if (idSueldoBrutoTexto !== undefined && idSueldoBrutoTexto !== null) {
                                setValue(`${idSueldoBrutoTexto}`, null)
                            }
                            if (idGratificacion !== undefined && idGratificacion !== null) {
                                setValue(`${idGratificacion}`, null)
                            }
                            if (idGratificacionTexto !== undefined && idGratificacionTexto !== null) {
                                setValue(`${idGratificacionTexto}`, null)
                            }
                        }
                    }

                } else {

                    let arrayOriginal = questions;
                    const flotanteConvertido = parseFloat(valueParam?.replace(/\R/g, ''));
                    let valueNumLetra = arrayOriginal.find((doc) => doc.groupTitle == itemCurrent.ExecuteSearchDefinition[0].AssignQuestion.QuestionGroup).questions.find((itemQ) => itemQ.Name == itemCurrent.ExecuteSearchDefinition[0].AssignQuestion.Question).Id
                    console.log('flotanteConvertido', flotanteConvertido, valueNumLetra)
                    if (!!flotanteConvertido) {
                        setValue(`${valueNumLetra}`, NumeroALetras(flotanteConvertido, t))
                        console.log('flotanteConvertido', flotanteConvertido)

                    } else {
                        setValue(`${valueNumLetra}`, '')
                        console.log('flotanteConvertido', flotanteConvertido)
                    }
                }

            } else if (itemCurrent.ExecuteSearchFunction === "GetBySelectedOption") {
                setLoadView(true)
                let value = valueParam === undefined || valueParam === null || valueParam === "" ? watch(`${itemCurrent.Id}`) : valueParam
                let request = {
                    File_Id: props.fileId,
                    Document_Id: null,
                    DocumentType_Id: null,
                    QuestionGroup: itemCurrent.QuestionGroup,
                    Question: itemCurrent.Name,
                    Value: value
                }
                await executeSearchRequests.GetBySelectedOption(request).then(response => {
                    let arrayOriginal = questions
                    response.Body?.forEach(itemQuestion => {
                        let listOptions = itemQuestion?.Values.map(item => {
                            return {
                                Option: item,
                                Value: item
                            }
                        })
                        const updatedOSArray = arrayOriginal.map(itemGroup =>
                            itemGroup.groupTitle.toUpperCase() === itemQuestion.QuestionGroup.toUpperCase()
                                ? {
                                    ...itemGroup, questions: itemGroup.questions.map(itemQ =>
                                        itemQ.Name.toUpperCase() === itemQuestion.Question.toUpperCase()
                                            ? { ...itemQ, Options: listOptions }
                                            : itemQ)
                                }
                                : itemGroup
                        )
                        arrayOriginal = updatedOSArray
                    })
                    setQuestions([])
                    setQuestions(arrayOriginal)
                    setLoadView(false)
                })
            }
            else if (itemCurrent.ExecuteSearchFunction == "GetPreguntasRequeridas") {
                let value = valueParam === undefined || valueParam === null || valueParam == "" ? watch(`${itemCurrent.Id}`) : valueParam
                let arrayOriginal = questions

                itemCurrent.ExecuteSearchDefinition?.filter(itemOption => itemOption.Option === value)[0]?.Requiered?.forEach(itemQuestion => {
                    const updatedOSArray = arrayOriginal.map(itemGroup =>
                        (itemGroup.groupTitle.toUpperCase() === itemQuestion.QuestionGroup.toUpperCase()) || (path === `${constPathRoot.pathRoot}/inbox/new-file`)
                            ? {
                                ...itemGroup, questions: itemGroup.questions.map(itemQ =>
                                    itemQ.Name.toUpperCase() === itemQuestion.Question.toUpperCase()
                                        ? { ...itemQ, Required: itemQuestion.Requiered, Visible: itemQuestion.Requiered }
                                        : itemQ)
                            }
                            : itemGroup
                    )
                    arrayOriginal = updatedOSArray
                })


                let newUpdatearray = arrayOriginal.map(itemGroup => itemGroup.groupTitle.toUpperCase() === itemCurrent?.GroupName?.toUpperCase() || (path === `${constPathRoot.pathRoot}/inbox/new-file`)
                    ? {
                        ...itemGroup, questions: itemGroup.questions.map(itemQ =>
                            itemQ.Name.toUpperCase() === itemCurrent.Name.toUpperCase()
                                ? { ...itemQ, Value: value }
                                : itemQ)
                    }
                    : itemGroup
                )
                setQuestions([])

                /* const gruposFiltrados = newUpdatearray.filter((grupo) => {
                    return grupo.questions.some((pregunta) => pregunta.Visible);
                }); */

                console.log('gruposFiltrados 0', arrayOriginal)
                setQuestions(newUpdatearray)


                const productosAplican = [
                    "Contratación ML Chile Masivo",
                    "Contratación ML Chile Estándar",
                    "Contratación ML Chile Seguros",
                    "Contratación ML Chile Provida",
                    "Contratación PROVIDA SPEN"
                ];

                if (itemCurrent.Name.toUpperCase() == "GRATIFICACIÓN (%)" && props.StageCurrent !== undefined && props.StageCurrent !== null && productosAplican.includes(props.StageCurrent?.Product)) {
                    let optionCombo = watch(`${itemCurrent.Id}`)
                    let idGratificacion = newUpdatearray.find((itemG) => itemG.groupTitle.toUpperCase().toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "GRATIFICACIÓN")?.Id
                    let idGratificacionTexto = newUpdatearray.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "GRATIFICACIÓN EN TEXTO")?.Id
                    let idSueldoBruto = newUpdatearray.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO TOTAL")?.Id
                    let idSueldoBrutoTexto = newUpdatearray.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO - TOTAL EN TEXTO")?.Id

                    if (optionCombo?.toUpperCase() == "25% SOBRE SALARIO BASE" || optionCombo?.toUpperCase() == "50% SOBRE SALARIO BASE") {
                        let salarioMoneda = watch(`${newUpdatearray.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions.find((item) => item.Name.toUpperCase() == 'SALARIO EN MONEDA')?.Id}`)

                        const textoModificado = CurrencyFormat(salarioMoneda, 'CurrencyEuropean') //salarioMoneda?.replace(/\./g, '')?.replace(/,/g, '.')?.replace(/\$/g, '');
                        console.log("textoModificadoMila--------5---CurrencyFormat: ", textoModificado)
                        const flotanteConvertido = parseFloat(textoModificado);

                        let porcentajeATomar = optionCombo?.toUpperCase() == "50% SOBRE SALARIO BASE" ? .50 : .25;
                        let newGratificacion = (flotanteConvertido * porcentajeATomar);
                        console.log("newGratificacion--------5---CurrencyFormat: ", newGratificacion)
                        const flotanteGratificacion = parseFloat(newGratificacion);
                        console.log("flotanteGratificacion--------5---CurrencyFormat: ", flotanteGratificacion)
                        let sueldoBrutoTotal = (flotanteGratificacion + flotanteConvertido)
                        console.log("sueldoBrutoTotal--------5---CurrencyFormat: ", sueldoBrutoTotal)

                        if (idGratificacion !== undefined && idGratificacion !== null) {
                            setValue(`${idGratificacion}`, newGratificacion)
                        }
                        if (idGratificacionTexto !== undefined && idGratificacionTexto !== null) {
                            setValue(`${idGratificacionTexto}`, NumeroALetras(flotanteGratificacion, t))
                        }
                        if (idSueldoBruto !== undefined && idSueldoBruto !== null) {
                            setValue(`${idSueldoBruto}`, sueldoBrutoTotal)
                        }
                        if (idSueldoBrutoTexto !== undefined && idSueldoBrutoTexto !== null) {
                            setValue(`${idSueldoBrutoTexto}`, NumeroALetras(sueldoBrutoTotal, t))
                        }
                    }
                    else {
                        if (idSueldoBruto !== undefined && idSueldoBruto !== null) {
                            setValue(`${idSueldoBruto}`, null)
                        }
                        if (idSueldoBrutoTexto !== undefined && idSueldoBrutoTexto !== null) {
                            setValue(`${idSueldoBrutoTexto}`, null)
                        }
                        if (idGratificacion !== undefined && idGratificacion !== null) {
                            setValue(`${idGratificacion}`, null)
                        }
                        if (idGratificacionTexto !== undefined && idGratificacionTexto !== null) {
                            setValue(`${idGratificacionTexto}`, null)
                        }
                    }
                }

            } else if (itemCurrent.Name.toUpperCase() == "GRATIFICACIÓN VENTAS / MILA") {
                const productosAplican = [
                    "Contratación ML Chile Masivo",
                    "Contratación ML Chile Estándar",
                    "Contratación ML Chile Seguros",
                    "Contratación ML Chile Provida",
                    "Contratación PROVIDA SPEN"
                ];
                if (productosAplican.includes(props.StageCurrent?.Product)) {
                    let salarioMoneda = watch(`${questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == 'SALARIO EN MONEDA')?.Id}`)
                    let gratificacionMILA = watch(`${questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == itemCurrent.Name.toUpperCase())?.Id}`)
                    let idGratificacionTexto = questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == "GRATIFICACIÓN EN TEXTO").Id
                    let idSueldoBruto = questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO TOTAL")?.Id
                    let idSueldoBrutoTexto = questions?.find((itemG) => itemG.groupTitle.toUpperCase() == itemCurrent.GroupName.toUpperCase()).questions?.find((item) => item.Name.toUpperCase() == "SUELDO BRUTO - TOTAL EN TEXTO")?.Id

                    const textoModificado = CurrencyFormat(salarioMoneda, 'CurrencyEuropean') //salarioMoneda?.replace(/\./g, '')?.replace(/,/g, '.')?.replace(/\$/g, '');
                    console.log("textoModificadoMila--------6---CurrencyFormat: ", textoModificado)
                    const textoModificadoMila = CurrencyFormat(gratificacionMILA, 'CurrencyEuropean') //gratificacionMILA?.replace(/\./g, '')?.replace(/,/g, '.')?.replace(/\$/g, '');
                    console.log("textoModificadoMila--------7---CurrencyFormat: ", textoModificadoMila)
                    const sueldoBaseConvertido = parseFloat(textoModificado);
                    const flotanteGratificacion = parseFloat(textoModificadoMila);
                    const newCalculoBruto = (flotanteGratificacion + sueldoBaseConvertido)
                    const floatNewCalculoBruto = parseFloat(newCalculoBruto);
                    console.log("textoModificadoMila--------7---idSueldoBruto: ", floatNewCalculoBruto)
                    setValue(`${idGratificacionTexto}`, NumeroALetras(flotanteGratificacion, t))
                    setValue(`${idSueldoBruto}`, floatNewCalculoBruto)
                    setValue(`${idSueldoBrutoTexto}`, NumeroALetras(floatNewCalculoBruto, t))
                }

            } else if (itemCurrent.ExecuteSearchFunction === "GetBySelectFilter") {
                let value = valueParam === undefined || valueParam === null || valueParam === "" ? watch(`${itemCurrent.Id}`) : valueParam
                if (value !== undefined && value !== null && value !== "") {
                    setLoadView(true)
                    let requestConsult = {
                        File_Id: props.fileId,
                        QuestionGroup: itemCurrent?.ExecuteSearchDefinition[0]?.FilterQuestion?.QuestionGroup,
                        Question: itemCurrent?.ExecuteSearchDefinition[0]?.FilterQuestion?.Question,
                        FilterColumn: itemCurrent?.ExecuteSearchDefinition[0]?.MetadataFilter,
                        FilterValue: value,
                        ClasificationId: props.DataControls?.Id,
                    }
                    // console.log("--*-----autocomplete------requestConsult.....", requestConsult)
                    await executeSearchRequests.GetByCustomCatalogAsync(requestConsult).then(response => {
                        // console.log("-----autocomplete------requestConsult......Respuesta: ", response)
                        var QuestionResponse = response.Body
                        console.log("-----QuestionResponse------QuestionResponse......Respuesta: ", QuestionResponse)
                        // console.log("-----autocomplete------QuestionResponse: ", QuestionResponse)
                        // console.log("-----autocomplete------QuestionResponse262: ", QuestionResponse?.QuestionId, QuestionResponse?.Values[0])

                        let arrayOriginal = questions
                        console.log("-----autocomplete------arrayOriginal FD1: ", arrayOriginal)
                        let listOptions = QuestionResponse?.Values?.map(item => {
                            console.log("QuestionResponse------: ", item)
                            return {
                                Option: item,
                                Value: item
                            }
                        })
                        const updatedOSArray = arrayOriginal.map(itemGroup =>
                            itemGroup.groupTitle.toUpperCase() === QuestionResponse.QuestionGroup.toUpperCase()
                                ? {
                                    ...itemGroup, questions: itemGroup.questions.map(itemQ =>
                                        itemQ.Name.toUpperCase() === QuestionResponse.Question.toUpperCase()
                                            ? { ...itemQ, Options: listOptions, Value: QuestionResponse?.Values[0] }
                                            : itemQ)
                                }
                                : itemGroup?.questions?.filter(item => item.QuestionGroup.toUpperCase() == QuestionResponse.QuestionGroup.toUpperCase()).length > 0
                                    ? {
                                        ...itemGroup, questions: itemGroup.questions.map(itemQ =>
                                            itemQ.Name.toUpperCase() === QuestionResponse.Question.toUpperCase()
                                                ? { ...itemQ, Options: listOptions, Value: QuestionResponse?.Values[0] }
                                                : itemQ)
                                    }
                                    : itemGroup
                        )
                        console.log("-----autocomplete------updatedOSArray FD1: ", updatedOSArray)
                        arrayOriginal = updatedOSArray

                        itemCurrent?.ExecuteSearchDefinition[0]?.OptionsRequiered?.forEach(itemRequired => {
                            // console.log("OptionsRequiered----...itemRequired....----:", itemRequired)
                            if (ValidarSICumpleCondiciones(itemRequired.Operador, value, itemRequired.Option)) {
                                //   console.log("-----autocomplete------No cumple la condicion itemRequired?.Requiered: ", itemRequired?.Requiered)

                                itemRequired?.Requiered?.forEach(itemQuestion => {
                                    const updatedOSArray = arrayOriginal.map(itemGroup =>
                                        itemGroup.groupTitle.toUpperCase() === itemQuestion.QuestionGroup.toUpperCase()
                                            ? {
                                                ...itemGroup, questions: itemGroup.questions.map(itemQ =>
                                                    itemQ.Name.toUpperCase() === itemQuestion.Question.toUpperCase()
                                                        ? { ...itemQ, Visible: itemQuestion.Requiered }
                                                        : itemQ)
                                            }
                                            : itemGroup
                                    )
                                    //    console.log("*****---------....itemQuestion.....---*****: ", itemQuestion)
                                    arrayOriginal = updatedOSArray
                                })

                                // console.log("*****---------....arrayOriginal.....---*****: ", arrayOriginal)

                                let newUpdatearray = arrayOriginal.map(itemGroup => itemGroup.groupTitle.toUpperCase() === itemCurrent.GroupName.toUpperCase()
                                    ? {
                                        ...itemGroup, questions: itemGroup.questions.map(itemQ =>
                                            itemQ.Name.toUpperCase() === itemCurrent.Name.toUpperCase()
                                                ? { ...itemQ, Value: value }
                                                : itemQ)
                                    }
                                    : itemGroup
                                )
                                arrayOriginal = newUpdatearray

                            }
                        })
                        console.log("ValoresFiltrados", arrayOriginal)
                        setExpanded(itemCurrent.QuestionGroupId);
                        // setQuestions([])
                        setQuestions(arrayOriginal)
                        setLoadView(false)
                    });
                    setLoadView(false)
                }
            } else if (itemCurrent.DataType === "Fecha") {
                let arrayOriginal = questions;
                console.log(
                  "itemCurrent FECHA",
                  itemCurrent,
                  arrayOriginal,
                  valueParam
                );

                let newUpdatearray = arrayOriginal.map((itemGroup) =>
                  itemGroup.groupTitle.toUpperCase() ===
                  itemCurrent?.GroupName?.toUpperCase()
                    ? {
                        ...itemGroup,
                        questions: itemGroup.questions.map((itemQ) =>
                          itemQ.Name.toUpperCase() ===
                          itemCurrent.Name.toUpperCase()
                            ? { ...itemQ, Value: valueParam }
                            : itemQ
                        ),
                      }
                    : itemGroup
                );
                arrayOriginal = newUpdatearray;
                setQuestions(arrayOriginal);
            }
        } catch (e) {
            console.log(".:UpdateCtrlQuestionsAsync:. Error: ", e)
            setLoadView(false)
            Alert({
                icon: 0,
                title: e?.message,
            });
        }
    }

    useEffect(() => {
        if (dataControls?.length > 0) {
            // console.log('dataControlsAjustes', dataControls)
            dataControls.forEach(itemGroup => {
                itemGroup.questions?.forEach(itemQuestion => {
                    if (itemQuestion.QuestionType == "Fecha") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion.Metadata?.Value))
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion?.Value))
                        }
                    } else if (itemQuestion.QuestionType === "FechaPrevia" || itemQuestion.QuestionType === "FechaPosterior") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion.Metadata?.Value))
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion?.Value))
                        }
                    } else if (itemQuestion.QuestionType === "Radio") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value)
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, ForceFormatDate(itemQuestion?.Value))
                        }
                    } else if (itemQuestion.QuestionType === "AutoComplete") {
                        if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
                            setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value)
                        } else if (itemQuestion.Value != null && itemQuestion.Value !== undefined) {
                            setValue(`${itemQuestion.Id}`, itemQuestion?.Value)
                        }
                    } else {
                        setValue(`${itemQuestion.Id}`, itemQuestion.Metadata !== null ? itemQuestion.Metadata?.Value : '');
                    }
                    if (itemQuestion.ExecuteSearchDefinition && itemQuestion.ExecuteSearchFunction !== "GetBySelectFilter") {
                        UpdateCtrlQuestionsAsync(itemQuestion, itemQuestion?.Metadata?.Value)
                    }
                });
            });
            setInitLoad(false)
            clearErrors()
        } else if (props.StageCurrent !== null || props.StageCurrent !== undefined) {
            if (props.StageCurrent?.Stage !== undefined || props.StageCurrent?.Stage !== null) {
                props.StageCurrent?.Stage?.QuestionGroups.forEach(itemGroup => {
                    itemGroup?.Questions?.forEach(itemQuestion => {
                        if (itemQuestion.QuestionType == "Fecha") {
                            if (itemQuestion.Metadata != null || itemQuestion.Metadata !== undefined) {
                                setValue(`${itemQuestion.Id}`, itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '')
                            }
                        } else if (itemQuestion.QuestionType === "FechaPrevia" || itemQuestion.QuestionType === "FechaPosterior") {
                            if (itemQuestion.Metadata != null || itemQuestion.Metadata !== undefined) {
                                setValue(`${itemQuestion.Id}`, itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '')
                            }
                        } else if (itemQuestion.QuestionType === "Radio") {
                            setValue(`${itemQuestion.Id}`, itemQuestion.Metadata !== null ? itemQuestion.Metadata?.Value : '')
                        } else if (itemQuestion.QuestionType === "AutoComplete") {
                            setValue(`${itemQuestion.Id}`, itemQuestion.Metadata !== undefined ? itemQuestion.Metadata?.Value : (itemQuestion.Value != null ? itemQuestion.Value : ""))
                        } else {
                            setValue(`${itemQuestion.Id}`, itemQuestion.Metadata !== null ? itemQuestion.Metadata?.Value : '')
                        }
                        if (itemQuestion.ExecuteSearchDefinition && itemQuestion.ExecuteSearchFunction !== "GetBySelectFilter") {
                            console.log('UPDATESCONTOLES', itemQuestion)
                            UpdateCtrlQuestionsAsync(itemQuestion, itemQuestion?.Metadata?.Value)
                        }
                    });
                });
                setInitLoad(false)
                clearErrors()
            }
        }
    }, [dataControls, props.StageCurrent])

    const handleChange = (panel) => (event, isExpanded) => {
        // console.log("handleChange*************-----****: ", isExpanded, panel)
        setExpanded(isExpanded ? panel : false);
    };

    return (<Box sx={{
        display: 'flex !important',
        flexDirection: 'column !important',
        alignItems: 'center !important',
        position: 'relative',
        width: '100% !important',
        padding: 3,
        overflowY: 'auto',
        ['@media only screen and (max-width: 812px)']: {
            height: '60vh',
        }
    }}>
        <FormProvider {...formMethods}>
            {loadView ? <CustomLoadingOverlay
                active={loadView}
                text={t("general_loading_message")}
            /> : null}
            {/* {console.log("....QuestionsFormProvider.....1.:", questions)} */}
            {questions.length > 0 && questions.map((qn, firstIndex) => qn.questions.every((questionInput) => !questionInput.Visible) && path !== `${constPathRoot.pathRoot}/inbox/new-file` ? null :
                (<Accordion style={{
                    width: '75%'
                }}
                    // defaultExpanded={true}
                    expanded={questions.length > 1 ? expanded === qn.llave : true}
                    onChange={handleChange(qn.llave)}
                >
                    <AccordionSummary
                        expandIcon={questions.length > 1 ? <ExpandMore /> : null}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography key={qn.llave} style={{ fontSize: 18, fontWeight: 600 }}>
                            {path === `${constPathRoot.pathRoot}/inbox/new-file` ?
                                t("title_form_assignment")
                                : questions.length >= 1 ? `${qn?.description ?? qn?.groupTitle}` : questions.length === 1
                                    ? 'Completa el siguiente formulario con la información solicitada para registrar la contratación del candidato.'
                                    : null}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }} >
                        {/* {console.log("....questions.....PROVIDER.....1.:", questions)} */}
                        {qn.questions?.map((itemInput, index) => {
                            // console.log('itemInputitemInput', itemInput)

                            if (itemInput.Visible === false) return null;
                            switch (itemInput.QuestionType.toUpperCase()) {
                                case 'PHONE':
                                    return (<InputField
                                        inputType="phone"
                                        defaultValue={itemInput.Value}
                                        regexPhone={itemInput.RegularExpression}
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />)
                                case "LABEL":
                                    return (
                                        <InputField
                                            inputType="label"
                                            label={itemInput.Description}
                                        />
                                    );
                                case 'RADIO':
                                    return (<InputField
                                        inputType="radio"
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        radioValues={itemInput.Options}
                                        required={itemInput.Required}
                                        validations={{ required: true }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        onSelect={(e, value) => {
                                            console.log("RADIOe: ", itemInput, value, e)
                                            UpdateCtrlQuestionsAsync(itemInput, value)
                                        }}
                                    />)
                                case 'COMBO':
                                    return (<InputField
                                        inputType="select"
                                        control={control}
                                        register={register}
                                        defaultValue={itemInput.Value}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        optionsList={itemInput.Options}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e) => {
                                            setValue(`${itemInput.Id}`, e.target.value)
                                            clearErrors(`${itemInput.Id}`)
                                            UpdateCtrlQuestionsAsync(itemInput, "")
                                        }}
                                    />)
                                case "AUTOCOMPLETE":
                                    return (
                                        <InputField
                                            inputType="autocomplete"
                                            control={control}
                                            name={`${itemInput.Id}`}
                                            label={itemInput.Description}
                                            placeholder={itemInput.Description}
                                            defaultValue={itemInput.Value}
                                            // loadingParticipant={loadingParticipant}
                                            optionsList={itemInput.Options}
                                            validations={{ required: itemInput.Required }}
                                            error={!!formState.errors[`${itemInput.Id}`]}
                                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                                            required={itemInput.Required}
                                            onSelect={(e) => {
                                                setValue(`${itemInput.Id}`, e)
                                                UpdateCtrlQuestionsAsync(itemInput, "")
                                                // clearErrors(`${itemInput.Id}`)
                                                // UpdateCtrlQuestionsAsync(itemInput, "")
                                            }}
                                            onInputChange={(e, searchValue) => {
                                                // console.log("--*-----autocomplete------itemInput.....", itemInput)
                                                // console.log("--*-----autocomplete------v.....", searchValue, searchValue?.length)
                                                console.log("--*-----autocomplete------e.....", props.DataControls?.Id, e?.type, e)
                                                if (searchValue?.length > 3 && e?.type == "click" && props.DataControls?.Id !== undefined) {
                                                    let requestConsult = {
                                                        SearchText: searchValue,
                                                        QuestionGroup: itemInput?.QuestionGroup,
                                                        Question: itemInput?.Name,
                                                        ClasificationId: props.DataControls?.Id,
                                                    }
                                                    console.log("--*-----autocomplete------requestConsult.....", requestConsult)
                                                    executeSearchRequests.GetByCustomCatalogAsync(requestConsult).then(response => {
                                                        console.log("-----autocomplete------requestConsult......Respuesta: ", response)
                                                    });
                                                }
                                                // setsearchUser(v);
                                            }}
                                        // open={openAutocomplete}
                                        // onOpen={() => {
                                        //     setOpenAutocomplete(true);
                                        // }}
                                        // onClose={(e, r) => {
                                        //     console.log("ONCLOSE.......r, e: ", r, e)
                                        //     if (r === "removeOption") {
                                        //         resetField(`${itemInput.Id}`);
                                        //     }
                                        //     setOpenAutocomplete(false);
                                        // }}
                                        />
                                    )
                                case 'FECHA':
                                    return (<InputField
                                        inputType="date"
                                        register={register}
                                        control={control}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e) => {
                                            console.log("Evento de selecxcion de fecha: ", e)
                                            setValue(`${itemInput.Id}`, e)
                                            clearErrors(`${itemInput.Id}`)
                                            UpdateCtrlQuestionsAsync(itemInput, "")
                                        }}
                                    />)
                                case 'FECHAPREVIA':
                                    return (<InputField
                                        inputType="date"
                                        register={register}
                                        control={control}
                                        maxDate={new Date()}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e) => {
                                            console.log("Evento de selecxcion de fecha: ", e)
                                            clearErrors(`${itemInput.Id}`)
                                            setValue(`${itemInput.Id}`, e)
                                            UpdateCtrlQuestionsAsync(itemInput, "")
                                        }}
                                        disabled={itemInput.Disabled}
                                    />);
                                case 'FECHAPOSTERIOR':
                                    return (<InputField
                                        inputType="date"
                                        register={register}
                                        control={control}
                                        minDate={new Date()}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e) => {
                                            console.log("Evento de selecxcion de fecha: ", e)
                                            clearErrors(`${itemInput.Id}`)
                                            setValue(`${itemInput.Id}`, e)
                                            UpdateCtrlQuestionsAsync(itemInput, "")
                                        }}
                                        disabled={itemInput.Disabled}
                                    />);
                                case 'CHECKBOX':
                                    return (<InputField
                                        inputType="check"
                                        control={control}
                                        register={register}
                                        defaultValue={itemInput.Value}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        radioValues={itemInput.Options}
                                        validations={{ required: true }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />);
                                case 'TEXTAREA':
                                    return (<InputField
                                        inputType="text"
                                        control={control}
                                        register={register}
                                        regexGeneric={itemInput.RegularExpression}
                                        multiline={true}
                                        minRows={3}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        defaultValue={itemInput.Value}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />)
                                case 'CURRENCY':
                                    return (<InputField
                                        inputType="currency"
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        defaultValue={itemInput.Value}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e) => {
                                            console.log("Evento CURRENCY: ", e)
                                            console.log("Evento CURRENCY : itemInput.Id", itemInput.Id, e)
                                            UpdateCtrlQuestionsAsync(itemInput, e)
                                            setValue(`${itemInput.Id}`, e)
                                            clearErrors(`${itemInput.Id}`)
                                        }}
                                    />);
                                case 'CURRENCYEUROPEAN':
                                    return (<InputField
                                        inputType="currencyEuropean"
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        defaultValue={itemInput.Value}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e) => {
                                            console.log("Evento CURRENCY: ", e)
                                            console.log("Evento CURRENCY : itemInput.Id", itemInput.Id, e)
                                            UpdateCtrlQuestionsAsync(itemInput, e)
                                            setValue(`${itemInput.Id}`, e)
                                            clearErrors(`${itemInput.Id}`)
                                        }}
                                    />);
                                case 'EMAILMETLIFE':
                                    console.log(`EMAILMETLIFE/// ${itemInput.Id}`, itemInput.Id)
                                    return (<InputField
                                        inputType="emailMetlife"
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        defaultValue={itemInput.Value}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />);
                                case 'EMAIL':
                                    return (<InputField
                                        inputType="email"
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        defaultValue={itemInput.Value}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />);
                                case 'CLABE':
                                    return (<InputField
                                        inputType="clabe"
                                        control={control}
                                        register={register}
                                        defaultValue={itemInput.Value}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />);
                                case 'NUMBER':
                                    return (<InputField
                                        inputType="number"
                                        control={control}
                                        register={register}
                                        defaultValue={itemInput.Value}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e) => {
                                            console.log("EventoNUMBER: ", e)
                                            UpdateCtrlQuestionsAsync(itemInput, e)
                                        }}
                                    />);
                                case 'HOUR':
                                    return (<InputField
                                        inputType="hour"
                                        register={register}
                                        control={control}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                        disabled={itemInput.Disabled}
                                    />);
                                case 'PASSWORD':
                                    return (<InputField
                                        inputType="password"
                                        regexGeneric={itemInput.RegularExpression}
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        defaultValue={itemInput.Value}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />);
                                default:
                                    return (<InputField
                                        inputType="text"
                                        regexGeneric={itemInput.RegularExpression}
                                        control={control}
                                        register={register}
                                        name={`${itemInput.Id}`}
                                        label={itemInput.Description}
                                        validations={{ required: itemInput.Required }}
                                        defaultValue={itemInput.Value}
                                        error={!!formState.errors[`${itemInput.Id}`]}
                                        helperText={formState.errors[`${itemInput.Id}`]?.message}
                                        required={itemInput.Required}
                                    />)
                            }
                        })}
                    </AccordionDetails>
                </Accordion>)
            )}
            {/* {console.log("props.loadingButton", props.loadingButton)} */}
            <Button
                id="btnDynFormSave"
                disabled={props.loadingButton != undefined || props.loadingButton != null ? !props.loadingButton : null}
                fullWidth
                variant="contained"
                sx={{ height: 45, px: 10, marginTop: '0.5em', minWidth: 220, maxWidth: 350 }}
                onClick={handleSubmit(props.handleSave)} >
                {props.TextButton === undefined || props.TextButton === null ? t("general_save") : props.TextButton}
            </Button>
        </FormProvider>
    </Box>)
}