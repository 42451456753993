import { alpha } from "@mui/material";

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#28A745",
  darker: "#08660D",
  contrastText: "#212B36",
};

/* const ERROR = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
}; */

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const INFO = {
  main: "#FFC222",
};

export const palette = {
  primary: {
    dark: "#0061A0",
    main: "#0060A2",
    main100: alpha("#0060A2"),
    main80: alpha("#0060A2", 0.8),
    main60: alpha("#0060A2", 0.6),
    main40: alpha("#0060A2", 0.4),
    main20: alpha("#0060A2", 0.2),
    main10: alpha("#0060A2", 0.1),
    lightContrast: "#FCFCFC",
    darkContrast: "#000000",
    contrastText: "#F2F2F2",
    TableHeader: "#0060A2",
    TableHeader2: "#0060A2",
    text: "#FFFFFF",
    selectMenu: "#A4CE4E",
    borderASummary: "#EEEEEE",
    mainLyrics: '#FFFFFF',
    tableMain: "#0060A2",
    tableSecondary: "#FFB8B8",
    tableText: "#FFFFFF",
    tableHover: '#F2F2F2',
  },
  secondary: {
    dark: "#000000",
    main: "#A4CE4E",
    main100: alpha("#A4CE4E"),
    main80: alpha("#A4CE4E", 0.8),
    main60: alpha("#A4CE4E", 0.6),
    main40: alpha("#A4CE4E", 0.4),
    main20: alpha("#A4CE4E", 0.2),
    main10: alpha("#A4CE4E", 0.1),
    lightContrast: "#F7F7F3",
    darkContrast: "#000000",
    contrastText: "#FFFFFF",
    light: '#F4F4F4',
  },
  nabvar: {
    main: "#0060A2",
  },
  check: {
    main: '#0060A2',
    text: '#1E1E1E',
  },
  tab: {
    text: '#0060A2',
  },
  footerBar: {
    main: '#424242',
    text: '#ffffff',
  },
  menuColor: {
    main: '#A4CE4E',// Color de backgroung
    text: '#0060A2',
  },
  userStyle: {
    icNotificaciones: '#292B30',
    icUser: '#0060A2',
    textDetails: '#292B30',
    textInstruccions: '#292B30',
  },
  iconPrincButton: {
    main: '#0060A2',
    bgIcon: '#FFFFFF',
    text: '#FFFFFF',
  },
  iconNamePrincipal: {
    main: '#0060A2',
    text: '#ffffff'
  },
  linkButton: {
    main: '#0060A2', // color de fondo button
  },
  actorsButton: {
    main: '#0060A2',
    bgIcon: '#FFFFFF'
  },
  status: {
    info: '#000000',// Color de textos detalle
  },
  selectTab: {
    info: '#0060A2', // Tabs selected color
    icon: '#484D58',
    textColor: '#FFFFFF',
    indicator: '#0060A2',
    border: '#0060A2',
    infoDisabled: '#F6F6F6',
    textColorDisabled: '#000000',
  },
  accordion: {
    main: '#243746 ',
    line: '#FFF0E8'
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  success: { ...SUCCESS },
  error: { ...ERROR },
  info: { ...INFO },
};
