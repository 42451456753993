
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphActions } from '../../store/actions/GraphActions';
import { Box, Grid } from '@mui/material';
import ChartBarras from './ChartBarras';
import ChartBar from './ChartBar';
import ChartPieRadius from './ChartPieRadius';
import { Buttons, CustomLoadingOverlay, InputField } from '../../components';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Charts = () => {
    const { control, formState, handleSubmit, register } = useForm({ mode: "onBlur" });
    const dispatch = useDispatch();
    const stateGraphics = useSelector(state => state.GetGenericGraphics)
    const authData = useSelector(state => state.Authentication)

    const [loadingCharts, setLoadingCharts] = React.useState(false)
    const [datChartsReturn, setDatChartsReturn] = useState(null)
    const [dataWorksFlows, setDataWorksFlows] = useState([{ Option: "", Value: "" }])
    const { t } = useTranslation();


    React.useEffect(() => {
        setLoadingCharts(true)
        let newDataWork = authData?.items?.WorkFlowListData?.map((objeto) => ({
            Option: objeto.WorkFlowDescription,
            Value: objeto.WorkFlowId,
        }));
        setDataWorksFlows(newDataWork)
        console.log("DATA", {
            "WorkflowId": authData?.items?.WorkFlowListData[0].WorkFlowId,
            "Status": "Otro",
            "FechaInicio": "",
            "FechaFin": ""
        })
        dispatch(graphActions.GetGenericGraphics({
            "WorkflowId": authData?.items?.WorkFlowListData[0].WorkFlowId,
            "Status": "Otro",
            "FechaInicio": "",
            "FechaFin": ""
        }))
    }, [])

    useEffect(() => {
        if (stateGraphics.items?.Body !== undefined && stateGraphics.items?.Body !== null && stateGraphics.items?.IsOK == true) {
            setDatChartsReturn(stateGraphics.items?.Body)
            setLoadingCharts(false)
        } else if (stateGraphics.items?.IsOK == false || stateGraphics.error !== undefined || stateGraphics.error !== null) {
            setLoadingCharts(false)
        }
    }, [stateGraphics])


    const chartsReturns = (dataChart) => {
        switch (dataChart.ChartType) {
            case "Pie":
                // CONTRATACION
                return <Grid item lg={4}>
                    <ChartPieRadius data={dataChart} />
                </Grid>
            case "Barras":
                // ESTATUS
                return <Grid item lg={4}>
                    <ChartPieRadius data={dataChart} />
                </Grid>
            case "Donout":
                // PROCESOS
                return <Grid item lg={4}>
                    <ChartPieRadius data={dataChart} />
                </Grid>
            default:
                break;
        }
    }

    const chartsReturnsResto = (dataChart) => {
        switch (dataChart.ChartType) {
            case "Barras2":
                // EMPLEADO
                return <Grid item lg={10} sx={{ height: 370 }}>
                    <ChartBarras data={dataChart} />
                </Grid>
            case "Pie2":
                // COMPAÑIA
                return <Grid item lg={10} sx={{ height: 370 }}>
                    <ChartBarras data={dataChart} />
                </Grid>
            default:
                break;
        }
    }

    const reloadData = (data) => {
        setLoadingCharts(true)
        console.log('DATAENVIO', data)
        dispatch(graphActions.GetGenericGraphics({
            "WorkflowId": data.WorkFlowId,
            "Status": "Otro",
            "FechaInicio": data.dateInicio,
            "FechaFin": data.dateFin
        }))
    }

    return (
        <Box>
            <CustomLoadingOverlay
                active={loadingCharts} text={t("general_loading_message")}
            />
            <Grid container items>
                <Grid lg={6}>
                    <Box sx={{ m: 1, mt: 2 }}>
                        <InputField
                            inputType="select"
                            control={control}
                            register={register}
                            name={'WorkFlowId'}
                            optionsList={dataWorksFlows}
                            error={!!formState.errors['WorkFlowId']}
                            helperText={formState.errors['WorkFlowId']?.message}
                            onSelect={(e, value) => {
                                console.log("RADIO itemInput", value)
                            }}
                        />
                    </Box>
                </Grid>
                <Grid lg={2}>
                    <Box sx={{ m: 1 }}>
                        <InputField
                            inputType="date"
                            control={control}
                            name={'dateInicio'}
                        />
                    </Box>
                </Grid>
                <Grid lg={2}>
                    <Box sx={{ m: 1 }}>
                        <InputField
                            inputType="date"
                            control={control}
                            name={'dateFin'}
                        />
                    </Box>

                </Grid>
                <Grid lg={2}>
                    <Box sx={{ m: 1 }}>
                        <Buttons ConfirmButtonText={t("request_update")}
                            onClickConfirm={handleSubmit(reloadData)}
                        />
                    </Box>

                </Grid>

            </Grid>
            <Grid container items alignContent={"center"} justifyContent={'center'} justifyItems={"center"}>
                {datChartsReturn?.map((item) => (chartsReturns(item)))}
                <Grid container items lg={6} justifyContent={'center'} alignItems={'center'} sx={{ height: 700, mt: 3 }} >
                    {datChartsReturn?.map((item) => item?.ChartType === "Bar" ? (<ChartBar data={item} />) : null)}
                </Grid>
                <Grid container items lg={6} justifyContent={'center'} sx={{ alignContent: 'center' }} >
                    {datChartsReturn?.map((item) => (chartsReturnsResto(item)))}
                </Grid>
            </Grid>
        </Box >
    );
};
export default Charts;