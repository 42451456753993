import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from "moment"
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Tooltip as tooltipClasses, useTheme
} from '@mui/material';
import { Icon } from '@iconify/react'

import { ForceFormatDate } from '../../utils';
import { useStyles } from '../../components/table/TableStyle'

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 15,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  }
}));


export function TableDataUsersMasive({ data, groupData }) {
  const theme = useTheme()
  console.log(data)
  const localStyle = useStyles(theme)
  let path = window.location.pathname


  const newData = data?.map((col) => {
    console.log("............TableDataUsersMasive...................: col: ", col)
    return {
      Clave: groupData.ClaveGrupo,
      Nombre: col.PrimerNombre,
      aPaterno: col.PrimerApellido,
      aMaterno: col.SegundoApellido,
      numRequi: col.AditionalData?.find((value) => value.Campo === "# de Requisición (Avature)" || value.Campo === "Requisição # (Avature)" )?.Valor,
      email: col.CorreoElectronico,
      identificador: col.AditionalData?.find((value) => value.Campo === "Identificador Nacional" || value.Campo === "CPF" )?.Valor,
      cargo: col.AditionalData?.find((value) => value.Campo === "Nombre del Cargo" || value.Campo === "Nome do cargo em inglês")?.Valor,
      date: ForceFormatDate(col.AditionalData?.find((value) => value.Campo === "Fecha de inicio de contrato" || value.Campo === "Data de início do contrato")?.Valor, true),
      status: col.State,
      Error: col.ErrorToolTip
    }
  })

  console.log('newData', data, newData)

  const createData = (Clave, Nombre, aMaterno, aPaterno, numRequi, email, identificador, cargo, date, status, Error) => {
    return { Clave, Nombre, aMaterno, aPaterno, numRequi, email, identificador, cargo, date, status, Error }
  }

  console.log("dataINFO", groupData, data, newData)

  const rows = newData?.map((r) => {
    return createData(r.Clave, r.Nombre, r.aMaterno, r.aPaterno, r.numRequi, r.email, r.identificador, r.cargo, r.date, r.status, r.Error)
  })

  const columns = [
    { id: 'Clave', label: 'Clave Grupal', minWidth: 100 },
    { id: 'Nombre', label: 'Nombre (s)', minWidth: 100 },
    { id: 'aPaterno', label: 'Apellido Paterno', minWidth: 100 },
    { id: 'aMaterno', label: 'Apellido Materno', minWidth: 100 },
    { id: 'numRequi', label: 'Núm de requisición', minWidth: 100 },
    { id: 'email', label: 'Correo electrónico', minWidth: 100 },
    { id: 'identificador', label: 'Identificador', minWidth: 100 },
    { id: 'cargo', label: 'Cargo', minWidth: 100 },
    { id: 'date', label: 'Fecha de inicio', minWidth: 100 },
    { id: 'status', label: 'Estatus', minWidth: 100 },
  ]

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  const handlerSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property)
  }

  return (
    <div style={{ margin: '0 1em 0 1em' }}>
      <TableContainer className={localStyle.container}>
        <Table size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow key={"headerTable"}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  className={localStyle.head}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => { //sortRowInformation(rows, getComparator(orderDirection,valueToOrderBy))
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}
                    className={localStyle.tableRow}
                    style={{ background: row.status == false ? '#FFEEEE' : null }}
                  >
                    {console.log('columns', row, columns)}
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'status' && value === true ?
                            <Icon color="#0FA958" fontSize={28} icon="material-symbols:check-circle-outline-rounded" /> :
                            column.id === 'status' && value === false ?
                              <Tip title={row.Error} placement="top"><Icon color="#F24E1E" fontSize={28} icon="mdi:close-circle-outline" /></Tip> : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[0]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}