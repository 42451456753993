import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import { Footer } from "../../components/Footer";
import { Box, styled } from "@mui/material";
import { HeaderAppBar } from "../../components/HeaderAppBar";
import { Header } from "../../components";
import { Drawer } from "../../components/Drawer";
import { useSelector } from "react-redux";

const OutletWrapper = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 18px 53px rgba(0, 0, 0, 0.14)",
  borderRadius: "10px",
  margin: "15px",
  // border: '1px solid blue',
  padding:"0.5em", //"1.5em",
  // backgroundColor: "#CACCCE",
  [theme.breakpoints.up("lg")]: {
    margin: "10px 15px 5px 15px",
    height: "100vh",
  },
  [theme.breakpoints.down("md")]: {
    margin: "10px 10px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "10px 0px",
    // margin: "0px",
    padding: "0.6em",
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "none",
    marginBottom: 0,
  },
  overflowY: "auto !important",
}));

const MarginPrincipal = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    margin: "0px 45px 0px 0px",
    display: "grid !important",
    gridTemplateColumns: "17% 83%",
    gridAutoRows: "100vh !important",
    overflowY: "hidden !important",
    "&::-webkit-scrollbar": {
      width: "0",
      background: "transparent",
    },
    overflowX: "hidden",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
}));

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#F7F8FA",
  width: "100%",
  minHeight: "calc(100vh - 120px)",
  margin: "0px auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

export default function Home() {
  const [drawer, setdrawer] = useState(false);
  const openDrawer = () => setdrawer(true);
  const closeDrawer = () => setdrawer(false);
  const info = useSelector((state) => state);

  console.log('REDUXDATA', info )

  return (
      <MarginPrincipal>
        <HeaderAppBar open={drawer} openDrawer={openDrawer} />
        <RootStyle>
          <Box>
            <Header />
          </Box>
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
          <Footer />
        </RootStyle>
        <Drawer open={drawer} onClose={closeDrawer} />
      </MarginPrincipal>
  );
}
