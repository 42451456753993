export const roleConstants = {
  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

  GET_TENANTS_REQUEST: 'GET_TENANTS_REQUEST',
  GET_TENANTS_SUCCESS: 'GET_TENANTS_SUCCESS',
  GET_TENANTS_FAILURE: 'GET_TENANTS_FAILURE',
}

