import { combineReducers } from "redux";

import { Authentication } from "./AuthReducer"; //Importar reducer
import {
  FileInfo,
  SearchByUser,
  InfoBySearch,
  RecordInfo,
  ActorsInfo,
  ActorInfo,
  Folio,
  FileUpdateMetData,
  GenerateFolioHiringData,
  UserCurentData,
  GetFileEditCustomMetData,
  GetEstatusProceso,
} from "./FileReducer";
import {
  GetByUsuarioRolDetalleGrupo,
  GetByUsuarioGruposStatus,
  GetByUsuarioRolBusquedaPortalGrupos,
  UploadBatchDocumentProcess,
  GetUsersInformationMetadadaMasive,
  UploadUsersInformationMetadadaMasive,
} from "./FileMasiveReducer";
import {
  UploaBatchFile2Data,
  InitializeBatchiFleData,
  GetFileStageData,
  GetAllBulkFileData,
  UploadBulkFileData,
  UploadBatchFileGEIDData,
  GetListGEIDData,
  GetBulkFileDetails,
  GetAllBulkMetadata,
  GetBulkMetadataDetails,
  UploadBulkFileMetadata,
} from "./FlujoMasivoReducer";
import { BinnacleInfo, ContinueWorkFlowData } from "./WorkFlowReducer";
import {
  FileValidation,
  DocumentValidation,
  catpchaValidation,
  PinValidation,
  DocExternalVal,
  RegisGeolocationData,
  CustomValidationFile,
  ProcessValRulesData,
} from "./ValidationReducer";

import {
  Document,
  BioDocument,
  DocumentUpdateMetData,
  UploadSections,
  CargaDocWithSecData,
  RegisterMetadaData,
  OcrData,
  GetDocumentsGroupByRol,
  GetDocumentGeneratorSelection,
  RegisterDocumentGeneratorSelection,
  DownloadDocumentGeneratorSelectionContent,
  ProcessDocumentGeneratorSelection,
} from "./DocumentReducer";

import { ProductRemote, ProductHiringData } from "./ProductReducer";
import {
  ChangePass,
  UsersAll,
  RegisterUser,
  UpdateUser,
  RegisterUserAuthenticatorRoleData,
  GetAllUsersData,
} from "./UserReducer";
import { BiometricData } from "./BiometricReducer";
import { NewPin, GetNotificationsUser } from "./NotificationReducer";
import { RoleAll, GetTenantsData } from "./RoleReduce";
import { ListBranchOffice } from "./ExternalReducer";
import {
  SearchData,
  SelectOptionData,
  CustomCatalogData,
} from "./ExecuteSearchReducer";
import { PresentationVCRemote } from "./PresentationVCReducer";
import { GetGenericGraphics } from "./GraphReducer";
import { SetDefualtLanguage } from "./GenericReducer";
import { ReportsData, GetReports } from "./ReportsReducers";

const rootReducer = combineReducers({
  Authentication,
  PresentationVCRemote,
  FileInfo,
  SearchByUser,
  InfoBySearch,
  RecordInfo,
  ActorsInfo,
  ActorInfo,
  Folio,
  BinnacleInfo,
  FileValidation,
  DocumentValidation,
  catpchaValidation,
  PinValidation,
  DocExternalVal,
  Document,
  BioDocument,
  ProductRemote,
  ChangePass,
  BiometricData,
  NewPin,
  RegisGeolocationData,
  RoleAll,
  ListBranchOffice,
  UsersAll,
  RegisterUser,
  UpdateUser,
  CustomValidationFile,
  FileUpdateMetData,
  ContinueWorkFlowData,
  ProcessValRulesData,
  DocumentUpdateMetData,
  ProductHiringData,
  GenerateFolioHiringData,
  CargaDocWithSecData,
  UploadSections,
  RegisterMetadaData,
  OcrData,
  SearchData,
  UserCurentData,
  GetByUsuarioRolBusquedaPortalGrupos,
  GetByUsuarioRolDetalleGrupo,
  GetByUsuarioGruposStatus,
  UploadBatchDocumentProcess,
  GetUsersInformationMetadadaMasive,
  UploadUsersInformationMetadadaMasive,
  GetDocumentsGroupByRol,
  GetNotificationsUser,
  SelectOptionData,
  CustomCatalogData,
  UploaBatchFile2Data,
  InitializeBatchiFleData,
  GetFileStageData,
  GetAllBulkFileData,
  UploadBulkFileData,
  GetDocumentGeneratorSelection,
  RegisterDocumentGeneratorSelection,
  DownloadDocumentGeneratorSelectionContent,
  ProcessDocumentGeneratorSelection,
  UploadBatchFileGEIDData,
  GetFileEditCustomMetData,
  RegisterUserAuthenticatorRoleData,
  GetAllUsersData,
  GetTenantsData,
  GetListGEIDData,
  GetBulkFileDetails,
  GetAllBulkMetadata,
  GetBulkMetadataDetails,
  UploadBulkFileMetadata,
  GetEstatusProceso,
  GetGenericGraphics,
  SetDefualtLanguage,
  ReportsData,
  GetReports,
});

export default rootReducer;
