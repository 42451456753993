//configuracion para sso con microsoft
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_MSAL_CLIENT,
    authority: process.env.REACT_APP_AZURE_MSAL_AUTHORITY,
    redirectUri: process.env.REACT_APP_AZURE_MSAL_REDIRECT,
    postLogoutRedirectUri: process.env.REACT_APP_AZURE_MSAL_REDIRECT
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, 
  },
};
