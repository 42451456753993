import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  VirtualScroll,
  PdfExport,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import NotReport from "../../assets/NotReports.png";
import { L10n } from "@syncfusion/ej2-base";
import { useDispatch, useSelector } from "react-redux";
import { reportsActions } from "../../store/actions";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { DynamicFormFlex } from "../../components/LEGOS/DynamicForm/DynamicFormFlex";
import { makeStyles } from "@mui/styles";
import { CustomLoadingOverlay, UseImage } from "../../components";
import { useTranslation } from "react-i18next";
import { AlertModal } from "../../components/common/Modals/Views/AlertModal";
import { Icon } from "@iconify/react";
import Empty from "../../assets/NoResultMeet.png";



const useStyles = makeStyles((theme) => ({
  dashboard: {
    width: "100%",
    height: "80vh",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewReportsData() {
  const { t } = useTranslation();
  L10n.load({
    "en-ES": {
      pager: {
        currentPageInfo: "",
        totalItemsInfo: "{1} de {2} de {0}",
      },
      grid: {
        EmptyDataSource: t("reports_no_data"),
        NoRecords: t("reports_no_records"),
        Cancel: t("general_cancel"),
        Delete: t("general_delete"),
        Add: t("general_add"),
        Edit: t("general_edit"),
        Excelexport: t("reports_excel_export"),
        Pdfexport: t("reports_pdf_export"),
        Csvexport: t("reports_csv_export"),
      },
    },
  });

  let gridInstance;
  const theme = useTheme();
  const mainPage = useStyles(theme);

  const dispatch = useDispatch();
  const location = useLocation();
  const { DataReport } = location.state || {};
  const fileInfoResponse = useSelector((state) => state.ReportsData);
  const userId = useSelector(
    (state) => state.Authentication.items.UserLoginData.Id
  );
  const formMethods = useForm({
    mode: "onBlur",
  });

  const [dataTableUsers, setDataTableUsers] = useState([]);
  const [dataFilters, setDataFilters] = useState([]);
  const [dataFiltersComplete, setDataFiltersComplete] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [filtersCont, setFiltersCont] = useState(null);
  const [open, setOpen] = React.useState(false);
  const pageSettings = { pageSize: 50 };

  const toolbarOptions = ["ExcelExport", "PdfExport", "CsvExport"];
  const rules = { required: true };

  const handleClose = () => {
    setOpen(false);
  };

  const toolbarClick = (args) => {
    switch (args.item.id) {
      case "DefaultExport_pdfexport":
        gridInstance.pdfExport();
        break;
      case "DefaultExport_excelexport":
        gridInstance.excelExport();
        break;
      case "DefaultExport_csvexport":
        gridInstance.csvExport();
        break;
    }
  };

  useEffect(() => {
    setShowLoader(fileInfoResponse.loading);
    if (fileInfoResponse.items != null) {
      setDataTableUsers(fileInfoResponse.items);
    } else if (fileInfoResponse.error != null) {
      AlertModal("Error", fileInfoResponse.error, null, t);
    }
  }, [fileInfoResponse]);

  useEffect(() => {
    setDataTableUsers([]);
    if (DataReport?.InitialResult && DataReport.Url == null) {
      dispatch(
        reportsActions.GetReportData({
          IdReport: DataReport?.Id,
          Filters: [],
          IdUser: userId,
        })
      );
    }

    if (DataReport?.Filters.length != 0) {
      const nuevosDatos = DataReport?.Filters.map(
        ({ Name, Description, ...resto }) => ({
          Id: Name,
          Required: false,
          Description: Description,
          ...resto,
        })
      );
      setFiltersCont(nuevosDatos.length > 2);
      setDataFilters([
        {
          groupTitle: "",
          llave: "",
          Questions: nuevosDatos.slice(0, 2),
          Description: "",
        },
      ]);
    }
  }, [DataReport]);

  const handleClickOpen = () => {
    setOpen(!open);
    const nuevosDatos = DataReport?.Filters.map(
      ({ Name, Description, ...resto }) => ({
        Id: Name,
        Required: false,
        Description: Description,
        ...resto,
      })
    );
    setDataFiltersComplete([
      {
        groupTitle: "",
        llave: "",
        Questions: nuevosDatos,
        Description: "",
      },
    ]);
  };

  const handleSearch = (data) => {
    setDataTableUsers([]);
    const newFilter = Object.keys(data).map((key) => ({
      Name: key,
      Value: data[key],
    }));

    dispatch(
      reportsActions.GetReportData({
        IdReport: DataReport?.Id,
        Filters: newFilter,
        IdUser: userId,
      })
    );
    handleClose();
  };

  if (DataReport.Url) {
    return (
      <Box style={{ width: "100%", height: "auto" }}>
        <div>
          <iframe
            className={mainPage.dashboard}
            frameborder="0"
            src={`${DataReport.Url}`}
            allowFullScreen
          ></iframe>
        </div>
      </Box>
    );
  }

  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          justifyItems: "flex-start"
        }}
      >
        <Grid sx={{ width: "80%" }} /* item  lg={filtersCont ? 8 : 10} */>
          {/* <Box> */}
          {dataFilters.length != 0 && (
            <Box sx={{ mt: 1 }}>
              <FormProvider {...formMethods}>
                <DynamicFormFlex
                  questionsGroups={dataFilters}
                  handleSave={handleSearch}
                  TextButton={t("search")}
                  displayProps="flex"
                />
              </FormProvider>
            </Box>
          )}
          {/* </Box> */}
        </Grid>
        <Grid display={'flex'} flexDirection={'row'} alignItems={'start'} justifyContent={'flex-start'} /* item lg={2} */>
          <Box
            sx={{
              p: 1,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Button
              sx={{ width: 180, height: 45, alignSelf: "end" }}
              color="primary"
              variant="outlined"
              onClick={() => {
                setDataTableUsers([]);
                if (DataReport?.InitialResult && DataReport.Url == null) {
                  dispatch(
                    reportsActions.GetReportData({
                      IdReport: DataReport?.Id,
                      Filters: [],
                      IdUser: userId,
                    })
                  );
                }
              }}
            >
              {t("search_clean_basic")}
            </Button>
          </Box>
        </Grid>
        {filtersCont ? (
          <Grid item lg={2}>
            <Box
              sx={{
                p: 1,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Button
                sx={{ width: 180, height: 45, alignSelf: "end" }}
                color="primary"
                variant="outlined"
                onClick={() => handleClickOpen()}
                startIcon={<Icon icon="cil:filter" />}
              >
                {t("search_filter")}
              </Button>
            </Box>
          </Grid>
        ) : null}
      </Grid>
      {dataTableUsers.Rows != undefined && dataTableUsers.Rows.length > 0 ? (
        <Box sx={{ mt: 2, p: 2 }}>
          <GridComponent
            locale="en-ES"
            id="DefaultExport"
            dataSource={dataTableUsers?.Rows}
            rowHeight={40}
            height="65vh"
            enableVirtualization={true}
            pageSettings={pageSettings}
            toolbar={toolbarOptions}
            allowPdfExport={true}
            allowExcelExport={true}
            allowSorting={true}
            allowResizing={true}
            toolbarClick={toolbarClick}      
            ref={(g) => (gridInstance = g)}
          >
            <Inject
              services={[VirtualScroll, Sort, PdfExport, ExcelExport, Toolbar]}
            />
            <ColumnsDirective>
              {dataTableUsers?.Headers?.map((item) => (
                <ColumnDirective
                  field={item.Id}
                  headerText={item.Name}
                  textAlign="Left"
                  width={200}
                  isPrimaryKey={true}
                  validationRules={rules}
                />
              ))}
            </ColumnsDirective>
          </GridComponent>
        </Box>
      ) : dataTableUsers.Rows != null ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "55vh",
            width: "80vw",
            mt: 5,
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <UseImage src={Empty} type="img" style={{ maxWidth: 300 }} />
            </Box>
            <Typography sx={{ mt: 2 }} variant="subtitle2">
              {t("general_not_request")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "55vh",
            width: "80vw",
            mt: 5,
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <UseImage width="200px" src={NotReport} type="img" />
            </Box>
            <Typography sx={{ mt: 2 }} variant="subtitle2">
              {t("reports_instructions_2")}
            </Typography>
          </Box>
        </Box>
      )}

      {showLoader && (
        <CustomLoadingOverlay
          active={showLoader}
          text={t("general_loading_message")}
        />
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Filtros"}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <FormProvider {...formMethods}>
            <DynamicFormFlex
              questionsGroups={dataFiltersComplete}
              handleSave={handleSearch}
              TextButton={t("general_search")}
              displayProps=""
            />
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* <Box sx={{ mt: 3 }}>
        <GridComponent
          id="DefaultExport"
          dataSource={dataTableUsers}
          height={300}
          enableInfiniteScrolling={true}
          pageSettings={pageSettings}
          toolbar={toolbarOptions}
          allowPdfExport={true}
          allowExcelExport={true}
          allowSorting={true}
          toolbarClick={toolbarClick}
          ref={(g) => (gridInstance = g)}
        >
          <Inject
            services={[
              VirtualScroll,
              InfiniteScroll,
              Sort,
              PdfExport,
              ExcelExport,
              Toolbar,
            ]}
          />
          <ColumnsDirective>
            {fileInfoResponse.items?.Headers?.map((item) => (
              <ColumnDirective
                field={item.Id}
                headerText={item.Name}
                width="100"
                textAlign="Left"
                isPrimaryKey={true}
                validationRules={rules}
              />
            ))}
          </ColumnsDirective>
        </GridComponent>
      </Box> */}

      {/* <Box sx={{ mt: 3 }}>
        <GridComponent
          id="DefaultExport"
          dataSource={dataTableUsers}
          height={300}
          toolbar={toolbarOptions}
          allowPaging={true}
          allowPdfExport={true}
          allowExcelExport={true}
          allowSorting={true}
          toolbarClick={toolbarClick}
          ref={(g) => (gridInstance = g)}
          pageSettings={{ pageCount: 4, pageSizes: true }}
        >
          <Inject services={[Page, Sort, PdfExport, ExcelExport, Toolbar]} />
          <ColumnsDirective>
            {fileInfoResponse.items?.Headers?.map((item) => (
              <ColumnDirective
                field={item.Id}
                headerText={item.Name}
                width="100"
                textAlign="Left"
                isPrimaryKey={true}
                validationRules={rules}
              />
            ))}
          </ColumnsDirective>
        </GridComponent>
      </Box> */}
    </Box>
  );
}
