import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const graphRequest = {}

graphRequest.GetGenericGraphics = (data) => {
    return axios.post(
        AppUrls.GetGenericGraphics,
        Config.SetParameters(data, '', '', ''),
        Config.ConfigAuth()
    ).then(handleResponse)
}

let handleResponse = (response) => {
    console.log('responseBulk', response)
    let respObject = response.data;//BodySecurityResponse(response.data);
    if (!respObject.IsOK) {
        let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
        let error = (respObject.Message || responseObject);
        console.log(responseObject)
        console.log(error)
        return Promise.reject(error);
    }
    return respObject;
}