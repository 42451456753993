import React from 'react'
import { Dialog, Typography } from '@mui/material'

// import { CustomCounterAppBar } from './'
import { mainStyles } from '../theme/mainStyles'

export const CustomAlertHelpDialog= (props) => {
    const classes = mainStyles.customHelpDialogStyles11()

    return (
        <Dialog fullScreen onClose={props.closeAction}
            open={props.open}
            className={classes.mainContainer}>
            <div className={classes.container}>
                <div className={classes.leftColumn}></div>
                <div className={classes.centerColumn}>
                    <div className={classes.centerColumnGral}>
                        {/* <CustomCounterAppBar viewMenu={false}
                            viewButtonClose={(props.viewButtonClose == null ||
                                props.viewButtonClose == undefined ? true : props.viewButtonClose)}
                            closeClick={props.closeAction}
                            initialStep={props.initialStep}
                            endingStep={props.endingStep}
                        /> */}
                        <div className={classes.centerContentGral}>
                            <div className={classes.contentBodyGralOne}>
                                {props.title !== undefined && props.title !== "" ?
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className={classes.title}>{props.title}</Typography>
                                    : null
                                }
                                {props.body}
                            </div>
                            <div className={classes.contentButtonsGralOne}  >
                                {props.Buttons}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.rightColumn}></div>
            </div>
        </Dialog >
    )
}