export const userConstants = {
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  GETALL_USERS_REQUEST: 'GETALL_USERS_REQUEST',
  GETALL_USERS_SUCCESS: 'GETALL_USERS_SUCCESS',
  GETALL_USERS_FAILURE: 'GETALL_USERS_FAILURE',

  REGISTER_USER_AUTHENTICATOR_REQUEST: 'REGISTER_USER_AUTHENTICATOR_REQUEST',
  REGISTER_USER_AUTHENTICATOR_SUCCESS: 'REGISTER_USER_AUTHENTICATOR_SUCCESS',
  REGISTER_USER_AUTHENTICATOR_FAILURE: 'REGISTER_USER_AUTHENTICATOR_FAILURE',
}
