import { BarSeries, Inject, ChartComponent, DataLabel, Legend, SeriesCollectionDirective, SeriesDirective, Tooltip, Category } from '@syncfusion/ej2-react-charts';
import React from 'react'

export default function ChartBar({ data }) {

    const primaryyAxis = {
        title: 'Cantidad',
        labelFormat: '{value}'
    };
    const primaryxAxis = { valueType: 'Category' };

    return (
        <ChartComponent id={data.ChartName} primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} title={data.ChartName}>
            <Inject services={[BarSeries, Legend, Tooltip, DataLabel, Category]} />
            <SeriesCollectionDirective>
                <SeriesDirective dataSource={data?.ChartData} pointColorMapping="HexColor" xName='Name' yName='Value' name={data.ChartName} type='Bar'>
                </SeriesDirective>
            </SeriesCollectionDirective>
        </ChartComponent>

    )
}
