import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress, Box, Typography, Button,
} from '@mui/material'
import { fileActions, productActions } from '../../store/actions'
import protect from '../../utils/protect'
import {
  FormDynamic,
  Alert, InformationalMessageComponent, CustomLoadingOverlay,
} from '../../components'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import InboxRegistrationsCandidates from './InboxRegistrationsCandidates'
import { useTranslation } from 'react-i18next';

function RegistrationCandidatesComponent(props) {
  const navigation = useNavigate()
  const [listControls, setListControls] = useState(null)
  const [isRegister, setIsRegister] = useState(null)
  const [dataCurrent, setDataCurrent] = useState(null)
  const [loadingButton, setLoadingButton] = useState(true)
  const [tenantActive, setTenantActive] = useState(false)

  const { t } = useTranslation();

  const productData = useSelector(state => state.ProductHiringData)
  const generateFolioData = useSelector(state => state.GenerateFolioHiringData)
  const dispatch = useDispatch()

  useEffect(() => {
    console.log()
    dispatch(productActions.GetProducts(""))
  }, [])

  useEffect(() => {
    if (productData.items !== undefined) {
      // console.log("productData--- Items-----------: ", productData.items)
      if (productData.items.length == 1) {
        setListControls(productData.items[0])
        setTenantActive(false)
      } else {
        setListControls(productData.items)
        setTenantActive(true)
      }
    } else if (productData.error !== undefined) {
      console.log(productData.error)
      // AlertCustom({
      //   icon: 0,
      //   title: productData.error,
      // });
    }
  }, [productData])

  useEffect(() => {
    if (generateFolioData.items !== undefined && isRegister) {
      // console.log("generateFolioData--- Items-----------: ", generateFolioData)
      setDataCurrent(generateFolioData.items)
      setListControls(null)
      setIsRegister(false)
      setLoadingButton(!loadingButton)
      Alert({
        icon: "success",
        text: t("successful_registration"),
      });
      navigation('/inbox/homeComponent')

      // dispatch(productActions.GetProductHiring(""))
    } else if (generateFolioData.error !== undefined && isRegister) {
      setIsRegister(false)
      setLoadingButton(!loadingButton)
      Alert({
        icon: 0,
        text: generateFolioData.error,
      });
    }
  }, [generateFolioData])


  const handleSaveFile = async (formData, e) => {
    let newListMetdata = []
    listControls.QuestionDefinitions.forEach((itemMetadata, i) => {
      if (itemMetadata.Name !== 'Primer Nombre' && itemMetadata.Name !== 'Segundo Nombre' &&
        itemMetadata.Name !== 'Primer Apellido' && itemMetadata.Name !== 'Segundo Apellido' &&
        itemMetadata.Name !== 'Correo electrónico' && itemMetadata.Name !== 'Teléfono') {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: itemMetadata.Name,
          Value: formData[itemMetadata.Id],
        })
      }
    })

    let requestData = {
      Name: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Primer Nombre")?.Id],
      SecondName: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Segundo Nombre")?.Id],
      FatherLastName: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Primer Apellido")?.Id],
      MotherLastName: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Segundo Apellido")?.Id],
      Email: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Correo electrónico")?.Id],
      PhoneNumber: formData[listControls.QuestionDefinitions?.find(item => item.Name == "Teléfono de Contacto")?.Id],//formData["Teléfono"],
      Metadata: newListMetdata?.length > 0 ? newListMetdata : null,
      ProductId: listControls.Id,
    }
    console.log('requestData', requestData)
    dispatch(fileActions.GenerateFolioHiring(requestData))
    setLoadingButton(!loadingButton)
    setIsRegister(true)
  }

  const handleResset = (e) => {
    // console.log("Ejecuta action resset:")
    setDataCurrent(null)
    Alert({
      icon: "success",
      text: "Usuario activado.",
    });
    dispatch(productActions.GetProductHiring(""))
  }

  return (
    <div >
      {productData.loading ?
        <CustomLoadingOverlay
          active={productData.loading} text={t("general_loading_message")}
        /> :
        listControls !== null && listControls.length == undefined ?
          <>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {console.log('listControls', listControls)}
              {/* {listControls.Id == 4 && listControls.Name == "Contratación Colombia Estándar" ? null : ( */}
              <Box sx={{ m: 1 }}>
                <Button
                  id="btnCandidateMsv"
                  variant="contained"
                  startIcon={<Icon fontSize={25} icon="ph:users-three" />}
                  sx={{ height: 45, px: 5, maxWidth: 300 }}
                  onClick={() => {
                    navigation('/inbox/bulkUsers', { state: { ProductId: listControls.Id, WorkFlowName: listControls.Name } })
                  }} >
                  {t("candidates_massive")}
                </Button>
              </Box>
              {tenantActive == true ? (
                <Box sx={{ m: 1 }}>
                  <Button
                    id="btnCandidatesReturn"
                    variant="outlined"
                    startIcon={<Icon fontSize={25} icon="ph:users-three" />}
                    sx={{ height: 45, px: 5, maxWidth: 300 }}
                    onClick={() => setListControls(productData.items)} >
                    {t("login_return")}
                  </Button>
                </Box>
              ) : null}
            </Box>
            <FormDynamic TextButton={t("candidates_register")} loadingButton={loadingButton} DataControls={listControls} handleSave={handleSaveFile} />
          </>
          : listControls !== null && listControls.length > 1 ? (
            <InboxRegistrationsCandidates Data={listControls} setListControls={setListControls} />
          ) : null
      }
    </div>)
}

export default protect(RegistrationCandidatesComponent)