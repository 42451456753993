//The difference between ActotMVisual and ModalVisual are the conditions to show the approbal buttons
import React, { useState, useEffect } from "react";
import { UseImage,CustomLoadingOverlay } from "../index";

import { useDispatch, useSelector } from "react-redux";
import { documentActions, validationActions } from '../../store/actions';

import {
  Typography,
  Dialog,
  CircularProgress,
  Grid,useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FiberManualRecord, Cancel } from '@mui/icons-material';

import { useStyles } from "./ModalStyles";
import procesandoVideo from "../../assets/procesando_video.gif";
import { useTranslation } from "react-i18next";

export function ModalVisualDoc({
  docName,
  idDoc,
  statusVideo,
  isApprovedDocument,
  open,
  handleClose
}) {
  const theme = useTheme()
  const localStyles = useStyles(theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const document = useSelector((state) => state.Document);

  useEffect(() => {
    dispatch(documentActions.GetDocument(idDoc))
  }, [idDoc]);

  return (<Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
    fullWidth
    maxWidth="lg"
    PaperProps={{
      style: {
        overflow: "hidden",
        minHeight: "80%",
      },
    }}
  >
    <div className={localStyles.containerResult}>
      <div className={localStyles.leftColumnResult}>
        <Typography
          variant="h4"
          style={{ padding: "30px 20px 0px 20px" }}
        >
          {docName}
          {isApprovedDocument === "true" ? (
            <CheckCircleIcon style={{ color: "#28A745" }} />
          ) :
            isApprovedDocument === "false" ? (
              <Cancel style={{ color: "#DC3545", }} />
            ) : (
              <FiberManualRecord
                style={{
                  color: "#28A745",
                  fontSize: 40,
                  marginLeft: 7,
                  marginBottom: 3,
                }}
              />
            )}
        </Typography>
      </div>
      <div className={localStyles.rightColumnResult}>
        <CloseIcon
          style={{ color: "#898B8D" }}
          className={localStyles.closeIconNew}
          onClick={handleClose}
        />
      </div>
    </div>

    <Grid container spacing={2} className={localStyles.modalEditable}>
      <Grid item xs={12} sm={12} className={localStyles.containPdf} >
        {statusVideo !== "VideoCompleto" &&
          statusVideo !== "Rechazado" &&
          document.extension === ".mp4" &&
          (document.items === undefined || document.items === null) ? (
          <div>
            <div
              style={{
                float: "left",
                width: "100%",
                textAlign: "center",
              }}
            >
              <UseImage
                className={localStyles.imgProcesandoVideo1}
                src={procesandoVideo}
                type="img"
              />
            </div>
            <div
              style={{
                float: "left",
                width: "100%",
                textAlign: "center",
              }}
            >
              {statusVideo !== "VideoCompleto" &&
                statusVideo !== "Rechazado" &&
                (document.items === undefined ||
                  document.items === null) ? (
                <Typography
                  variant="p"
                  className={localStyles.modalEstatusProcesando}
                >
                  <b>Procesando video</b>
                </Typography>
              ) : document.extension === ".mp4" ? (
                <Typography
                  variant="p"
                  className={localStyles.modalEstatusProcesandoSucces}
                >
                  {statusVideo}
                </Typography>
              ) : null}
            </div>
          </div>
        ) : document.loading ? (
          <CustomLoadingOverlay
            active={document.loading} text={t("general_loading_message")}
          />
        ) : document.extension === ".mp4" ? (
          <video
            className="videoClass"
            width="100%"
            height="90%"
            controls
            autoplay
            loop
            poster="fotograma.png"
          >
            <source src={document.items} type={document.type} />
            <p>Tu navegador no soporta HTML5</p>
          </video>
        ) : (
          <object
            width="100%"
            height="100%"
            data={document.items}
            type="application/pdf"
          >
            {" "}
          </object>
        )}
      </Grid>
    </Grid>
  </Dialog>);
}