import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';
import { ReferCredentials } from '../utils';

export const presentationRequests = {}

presentationRequests.GetPresentation = (tokenApp) => {
  console.log('ReferCredentials', ReferCredentials, AppUrls.GetApiPresentation + tokenApp+ "&refer=" + ReferCredentials);
  return axios.post(
    AppUrls.GetApiPresentation + tokenApp + "&refer=" + ReferCredentials,
    Config.ConfigNoAuth()
  ).then(handleResponse)
}

let handleResponse = (response) => {
  let respObject = response;//BodySecurityResponse(response.data);

  return respObject;
}