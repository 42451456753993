function Unidades(num, t) {

    switch (num) {
        case 1: return t ? t("number_UNO") : "UNO";
        case 2: return t ? t("number_DOS") : "DOS";
        case 3: return t ? t("number_TRES") : "TRES";
        case 4: return t ? t("number_CUATRO") : "CUATRO";
        case 5: return t ? t("number_CINCO") : "CINCO";
        case 6: return t ? t("number_SEIS") : "SEIS";
        case 7: return t ? t("number_SIETE") : "SIETE";
        case 8: return t ? t("number_OCHO") : "OCHO";
        case 9: return t ? t("number_NUEVE") : "NUEVE";
    }

    return "";
}//Unidades()

function Decenas(num, t) {

    let decena = Math.floor(num / 10);
    let unidad = num - (decena * 10);
    //console.log("entra", decena, (decena * 10), unidad)

    switch (decena) {
        case 1:
            switch (unidad) {
                case 0: return t ? t("number_DIEZ") : "DIEZ";
                case 1: return t ? t("number_ONCE") : "ONCE";
                case 2: return t ? t("number_DOCE") : "DOCE";
                case 3: return t ? t("number_TRECE") : "TRECE";
                case 4: return t ? t("number_CATORCE") : "CATORCE";
                case 5: return t ? t("number_QUINCE") : "QUINCE";
                default: return (t ? t("number_DIECI") : "DIECI") + Unidades(unidad, t);
            }
        case 2:
            switch (unidad) {
                case 0: return t ? t("number_VEINTE") : "VEINTE";
                default: return t ? DecenasY(t("number_VEINTI"), unidad, t) : ("VEINTI" + Unidades(unidad, t));
            }
        case 3: return DecenasY(t ? t("number_TREINTA") : "TREINTA", unidad, t);
        case 4: return DecenasY(t ? t("number_CUARENTA") : "CUARENTA", unidad, t);
        case 5: return DecenasY(t ? t("number_CINCUENTA") : "CINCUENTA", unidad, t);
        case 6: return DecenasY(t ? t("number_SESENTA") : "SESENTA", unidad, t);
        case 7: return DecenasY(t ? t("number_SETENTA") : "SETENTA", unidad, t);
        case 8: return DecenasY(t ? t("number_OCHENTA") : "OCHENTA", unidad, t);
        case 9: return DecenasY(t ? t("number_NOVENTA") : "NOVENTA", unidad, t);
        case 0: return Unidades(unidad, t);
    }
}//Unidades()

function DecenasY(strSin, numUnidades, t) {
    let letter = t ? t("number_Y") : "Y";
    if (numUnidades > 0)
        return strSin + ` ${letter} ` + Unidades(numUnidades, t)

    return strSin;
}//DecenasY()

function Centenas(num, t) {
    let centenas = Math.floor(num / 100);
    let decenas = num - (centenas * 100);

    switch (centenas) {
        case 1:
            if (decenas > 0)
                return (t ? `${t("number_CIENTO")} ` : "CIENTO ") + Decenas(decenas, t);
            return t ? `${t("number_CIEN")} ` : "CIEN";
        case 2: return (t ? `${t("number_DOSCIENTOS")} ` : "DOSCIENTOS ") + Decenas(decenas, t);
        case 3: return (t ? `${t("number_TRESCIENTOS")} ` : "TRESCIENTOS ") + Decenas(decenas, t);
        case 4: return (t ? `${t("number_CUATROCIENTOS")} ` : "CUATROCIENTOS ") + Decenas(decenas, t);
        case 5: return (t ? `${t("number_QUINIENTOS")} ` : "QUINIENTOS ") + Decenas(decenas, t);
        case 6: return (t ? `${t("number_SEISCIENTOS")} ` : "SEISCIENTOS ") + Decenas(decenas, t);
        case 7: return (t ? `${t("number_SETECIENTOS")} ` : "SETECIENTOS ") + Decenas(decenas, t);
        case 8: return (t ? `${t("number_OCHOCIENTOS")} ` : "OCHOCIENTOS ") + Decenas(decenas, t);
        case 9: return (t ? `${t("number_NOVECIENTOS")} ` : "NOVECIENTOS ") + Decenas(decenas, t);
    }

    return Decenas(decenas, t);
}//Centenas()

function Seccion(num, divisor, strSingular, strPlural, t) {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let letras = "";

    if (cientos > 0)
        if (cientos > 1)
            letras = Centenas(cientos, t) + " " + strPlural;
        else
            letras = strSingular;

    if (resto > 0)
        letras += "";

    return letras;
}//Seccion()

function Miles(num, t) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)
    let letter = t ? t("number_MIL") : "MIL"

    let strMiles = Seccion(num, divisor, letter, letter, t);
    let strCentenas = Centenas(resto, t);

    if (strMiles == "")
        return strCentenas;

    return strMiles + " " + strCentenas;
}//Miles()

function Millones(num, t) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)
    let millon = t ? t("number_UN_MILLON") : "UN MILLON"
    let millones = t ? t("number_MILLONES") : "MILLONES"

    let strMillones = Seccion(num, divisor, millon, millones, t);
    let strMiles = Miles(resto, t);

    if (strMillones == "")
        return strMiles;

    return strMillones + " " + strMiles;
}//Millones()

export function NumeroALetras(num, t) {
    var data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: "",
        letrasMonedaPlural: '',//"PESOS", 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: '', //"PESO", 'Dólar', 'Bolivar', 'etc'

        letrasMonedaCentavoPlural: t ? t("number_CENTAVOS") : "CENTAVOS",
        letrasMonedaCentavoSingular: t ? t("number_CENTAVO") : "CENTAVO"
    };

    let letterCon = t ? `${t("number_CON")} ` : "CON ";
    if (data.centavos > 0) {
        data.letrasCentavos = letterCon + (function () {
            if (data.centavos == 1)
                return Millones(data.centavos, t) + " " + data.letrasMonedaCentavoSingular;
            else
                return Millones(data.centavos, t) + " " + data.letrasMonedaCentavoPlural;
        })();
    };

    let letterCero = t ? `${t("number_CERO")} ` : "CERO ";
    if (data.enteros == 0)
        return letterCero + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros == 1)
        return Millones(data.enteros, t) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
    else
        return Millones(data.enteros, t) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
}//NumeroALetras()