import { authConstants } from '../constants';
import { authRequests } from '../../services'

export const authActions = {}//Objeto agrupador de funciones

authActions.Authentication = (authData) => {

  return dispatch => {
    console.log('authenticationData', authData)
    dispatch(request())//Ejecución de inicialización del "request()"
    authRequests.AuthenticationEncripted(authData)//Ejecución de petición al API
      .then(//Respuesta de promesa
        authenticationData => dispatch(success(authenticationData)),//Respuesta correcta del API
        error => (dispatch(failure(error.message)))//Respuesta erronea del API
      )
  }

  function request() { //Ingresar la petición hacia las API's (Aquí mostrar gif loader)
    return { type: authConstants.AUTH_REQUEST }// => carpeta constants => AuthConstants.js
  }

  function success(authenticationData) { //Respuesta correcta de petición
    return { type: authConstants.AUTH_SUCCESS, authenticationData } // => carpeta constants => AuthConstants.js
  }

  function failure(error) { //Respuesta errone ade petición (Mostrar modal de cortesía)
    return { type: authConstants.AUTH_FAILURE, error } // => carpeta constants => AuthConstants.js
  }
}

authActions.AuthenticationSSO = (authData) => {

  return dispatch => {
    console.log('authenticationData', authData)
    dispatch(request())//Ejecución de inicialización del "request()"
    authRequests.SSOAuthenticate(authData)//Ejecución de petición al API
      .then(//Respuesta de promesa
        authenticationData => dispatch(success(authenticationData)),//Respuesta correcta del API
        error => (dispatch(failure(error.message)))//Respuesta erronea del API
      )
  }

  function request() { //Ingresar la petición hacia las API's (Aquí mostrar gif loader)
    return { type: authConstants.AUTH_REQUEST }// => carpeta constants => AuthConstants.js
  }

  function success(authenticationData) { //Respuesta correcta de petición
    return { type: authConstants.AUTH_SUCCESS, authenticationData } // => carpeta constants => AuthConstants.js
  }

  function failure(error) { //Respuesta errone ade petición (Mostrar modal de cortesía)
    return { type: authConstants.AUTH_FAILURE, error } // => carpeta constants => AuthConstants.js
  }
}

authActions.AuthenticationQA = (authData) => {
  return dispatch => {
    console.log('LLEGOAQUI')
    dispatch(request())
    setTimeout(() => {
      dispatch(success(authData))//Respuesta correcta del API
    }, 3000);
  }

  function request() { //Ingresar la petición hacia las API's (Aquí mostrar gif loader)
    return { type: authConstants.AUTH_REQUEST }// => carpeta constants => AuthConstants.js
  }

  function success(authenticationData) { //Respuesta correcta de petición
    return { type: authConstants.AUTH_SUCCESS, authenticationData } // => carpeta constants => AuthConstants.js
  }

  function failure(error) { //Respuesta errone ade petición (Mostrar modal de cortesía)
    return { type: authConstants.AUTH_FAILURE, error } // => carpeta constants => AuthConstants.js
  }
}
///Siguiente paso => Crear reducer
