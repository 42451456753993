import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box, Typography, Button, Grid, IconButton, Tooltip
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form';
import { flujoMasivoActions } from '../../store/actions'
import {
    UseImage, Alert, CustomLoadingOverlay, UploadDcumentsControl
} from '../../components'
import img from '../../assets/DownloadExcel.png';
import plantillaExcel from '../../assets/PlantillaCargaMasiva_MetLife.xlsx'
import plantillaExcelAR from '../../assets/PlantillaCargaMasiva_MetLife_Argentina.xlsx'
import plantillaExcelCLSTD from '../../assets/PlantillaCargaMasiva_CLSTD.xlsx'
import plantillaExcelCLMSV from '../../assets/PlantillaCargaMasiva_CLMSV.xlsx'
import plantillaExcelCLProvida from '../../assets/PlantillaCargaMasiva_CLPSTD.xlsx'
import plantillaExcelCOSTD from '../../assets/PlantillaCargaMasiva_COSTD.xlsx'
import plantillaExcelCOMSV from '../../assets/PlantillaCargaMasiva_COMSV.xlsx'
import plantillaExcelMXSTD from '../../assets/PlantillaCargaMasiva_MXSTD.xlsx'
import plantillaExcelMXEXP from '../../assets/PlantillaCargaMasiva_MXEXP.xlsx'
import plantillaExcelMXWSM from '../../assets/PlantillaCargaMasiva_MXWSM.xlsx'
import plantillaExcelUY from '../../assets/PlantillaCargaMasiva_MetLife_Uruguay.xlsx'
import plantillaExcelBRASTD from '../../assets/PlantillaCargaMasiva_BRASTD.xlsx'
import plantillaExcelCLProSPEN from '../../assets/PlantillaCargaMasiva_SPEN.xlsx'

import { TableDataUsersMasive } from './TableDataUsersMasive';
import { useTranslation } from 'react-i18next';

export default function RegistrationBulkUsersComponent() {
    const dispatch = useDispatch();
    const location = useLocation()

    const formMethods = useForm({ mode: "onChange" });
    const navigation = useNavigate();
    const [loadView, setloadView] = useState(false);
    const [dataUsersTable, setDataUsersTable] = useState([]);
    const dataUsers = useSelector((state) => state.UploaBatchFile2Data)
    const dataInitializeBatchiFleData = useSelector((state) => state.InitializeBatchiFleData)
    // const workFlowId = useSelector((state) => state.Authentication?.items?.WorkFlowData?.WorkFlowId);
    const [fileBase64, setFileBase64] = useState(null);
    const [workFlowId, setWorkFlowId] = useState(null);
    const [workFlowName, setWorkFlowName] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        setloadView(dataUsers.loading);
        console.log("dataUsers: ", dataUsers.items?.message?.length, dataUsers)
        if (dataUsers.items != null && (dataUsers.items?.message?.length == undefined || dataUsers.items?.message?.length == 0)) {
            console.log('DATOS', dataUsers)
            setloadView(false);
            setDataUsersTable(dataUsers?.items?.Result)
        }
        if (dataUsers?.error || dataUsers.items?.message?.length > 0 && fileBase64?.length > 0) {
            setFileBase64(null)
            Alert({
                icon: "error",
                title: dataUsers?.error ?? dataUsers.items?.message,
            });
            setloadView(false);
        }
    }, [dataUsers])

    useEffect(() => {
        setloadView(dataInitializeBatchiFleData.loading);
        if (dataInitializeBatchiFleData.items != null) {
            setloadView(false);
            setDataUsersTable(dataInitializeBatchiFleData?.items?.Result)
        }
        if (dataInitializeBatchiFleData?.error) {
            setFileBase64(null)
            Alert({
                icon: "error",
                title: dataInitializeBatchiFleData?.error,
            });
            setloadView(false);
            dispatch(flujoMasivoActions.UploadBatchFile2(null,true))
        }
    }, [dataInitializeBatchiFleData])

    useEffect(() => {
        console.log("location: ", location.state?.ProductId, " - ", location)
        setDataUsersTable();
    }, [])

    useEffect(() => {
        console.log("LOCATION", location)
        setWorkFlowId(location.state?.ProductId)
        setWorkFlowName(location.state?.WorkFlowName)
    }, [location.state?.ProductId])

    const sendDataMasive = () => {
        const batchUploadIds = dataUsers?.items?.Grupo.BatchUpload_Id;
        console.log('batchUploadIds', [batchUploadIds]);
        dispatch(flujoMasivoActions.InitializeBatchFile({
            BatchUpload_Ids: [batchUploadIds],
            WorkflowId: location.state?.ProductId
        }));
        Alert({
            icon: "success",
            title: t("process_started"),
        });
        setloadView(false);
        navigation('/inbox/homeComponent')
    }

    let handleFileChange = async (FileContent, fileB64Response, extension) => {
        console.log("--------handleFileChange......fileB64Response........: ", fileB64Response)
        if (fileB64Response !== undefined && fileB64Response !== null 
            && fileB64Response !== ""&& fileB64Response !== "null") {
            setFileBase64(fileB64Response)
            try {
                dispatch(flujoMasivoActions.UploadBatchFile2({
                    "FileContentB64": fileB64Response,
                    "WorkflowId": location.state?.ProductId
                }))
            } catch (error) {
                console.log(error)
            }
        }else{
            dispatch(flujoMasivoActions.UploadBatchFile2(null,true))
        }
    };

    return (
        <>
            {loadView === true ?
                <CustomLoadingOverlay
                    active={loadView} text={t("general_loading_message")}
                />
                : null}
            {dataUsersTable?.length > 0 ? (
                <Grid>
                    <TableDataUsersMasive data={dataUsersTable} groupData={dataUsers.items.Grupo} />
                    <Grid container display={'flex'} justifyContent='center' spacing={2}>
                        <Grid item lg={3} xs={12}>
                            <Button id="btnCandidateMsvSave" style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }}
                                disabled={dataUsersTable.filter((item) => item.State === false)?.length === 0 ? false : true}
                                variant='contained' color="primary" onClick={() => sendDataMasive()}>
                                Enviar
                            </Button>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Button id="btnCandidateMsvEdit" style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} variant='outlined' color="primary" onClick={() => { setDataUsersTable([]); setFileBase64(null) }}>
                                {t("edit_file")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid >
            ) : (
                <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                    <Grid item lg={10} xs={12}>
                        <Box textAlign={'left'}>
                            <Typography variant='subtitle'>
                                {t("general_upload_xlsx_indication_blank")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={1} xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Tooltip title={t("geid_download_template")} placement="top">
                                {console.log("template workflow........: ", workFlowId)}
                                <a href={workFlowId == 1 ?
                                    plantillaExcelAR :
                                    workFlowId == 2 ?
                                        plantillaExcelMXSTD :
                                        workFlowId == 3 ?
                                            plantillaExcelCLMSV :
                                            workFlowId == 4 ?
                                                plantillaExcelCOSTD :
                                                workFlowId == 5 ?
                                                    plantillaExcelCLSTD :
                                                    workFlowId == 6 ?
                                                        plantillaExcelCOMSV :
                                                        workFlowId == 7 ?
                                                            plantillaExcelMXEXP :
                                                            workFlowId == 8 ?
                                                                plantillaExcelCLProvida :
                                                                workFlowId == 9 ?
                                                                    plantillaExcelMXWSM :
                                                                   workFlowId == 10 ?
                                                                        plantillaExcelUY :
                                                                            workFlowId == 12 && workFlowName == "Contratación PROVIDA SPEN"  ?
                                                                                plantillaExcelCLProSPEN :
                                                                                    workFlowId == 12 ?
                                                                                    plantillaExcelBRASTD :
                                                                                            plantillaExcel} target="_blank" rel="noopener noreferrer" download="Plantilla Carga Masiva.xlsx">
                                    <IconButton xs={{ width: 60 }}>
                                        <UseImage src={img} type='img' />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item lg={10} xs={12}>
                        {fileBase64 ? null :
                            <FormProvider {...formMethods}>
                                <UploadDcumentsControl
                                    Excel={true}
                                    Title={t("general_upload_your_file_xlsx")}
                                    onChange={handleFileChange}
                                />
                            </FormProvider>
                        }
                    </Grid>
                    <Grid lg={5} xs={12}>
                        <Box display={'flex'} justifyContent='center'>
                            <Button id="btnCandidateMsvCancel" style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} variant='outlined' color="primary" onClick={() => navigation('/inbox/new-file')}>
                                {t("general_cancel")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )
            }
        </>
    )
}
