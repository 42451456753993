import { presentationCredentialConstants } from '../constants/index.js'


export const PresentationVCRemote = (state = {}, action) => {
    switch (action.type) {
        case presentationCredentialConstants.VERIFY_CREDENTIAL_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case presentationCredentialConstants.VERIFY_CREDENTIAL_SUCCESS:
            return {
                loading: false,
                objeto: action.object.data,
                requestId: action.object.data.requestId,
                error: undefined
            }
        //break;
        case presentationCredentialConstants.VERIFY_CREDENTIAL_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        case presentationCredentialConstants.VERIFY_CREDENTIAL_RESET:
            return {
                loading: false,
                items: undefined,
                error: undefined
            }
        //break;
        default:
            return state
    }
}

