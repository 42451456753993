import { documentConstants } from '../constants/index.js'
import {
    reorderDocumentalGroups
} from '../../utils'

export const Document = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOCUMENT_FILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_FILE_SUCCESS:
            return {
                loading: false,
                items: action.document.data,
                itemsURL: action.document.url,
                type: action.document.type,
                extension: action.document.extension,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_FILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const BioDocument = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOCUMENT_BIO_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_BIO_SUCCESS:
            return {
                loading: false,
                items: action.bioDocument.data,
                itemsURL: action.bioDocument.url,
                type: action.bioDocument.type,
                extension: action.bioDocument.extension,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_BIO_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}
export const DocumentUpdateMetData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DOCUMENT_UPDATEMETADATA_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break; //break;
        case documentConstants.DOCUMENT_UPDATEMETADATA_SUCCESS:
            return {
                loading: false,
                items: action.docUpdateData.Body,
                error: undefined
            }
        //break;
        case documentConstants.DOCUMENT_UPDATEMETADATA_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const UploadSections = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.UPLOAD_SECTION_REQUEST:
            return { loading: true, items: undefined, error: undefined };
        // //break;
        case documentConstants.UPLOAD_SECTION_SUCCESS:
            return {
                loading: false,
                items: action.uploadSecData.Body,
                error: undefined,
            };
        // //break;
        case documentConstants.UPLOAD_SECTION_FAILURE:
            return { loading: false, items: undefined, error: action.error };
        // //break;
        default:
            return state;
    }
};

export const CargaDocWithSecData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.CARGADOC_WITH_SECTION_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            };
        // //break;
        case documentConstants.CARGADOC_WITH_SECTION_SUCCESS:
            return {
                loading: false,
                items: action.cargaDocData.Body,
                error: undefined
            };
        // //break;
        case documentConstants.CARGADOC_WITH_SECTION_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            };
        // //break;
        default:
            return state;
    }
}

export const GetDocumentsGroupByRol = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_DOCUMENTS_BY_ROL_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            };
        case documentConstants.GET_DOCUMENTS_BY_ROL_SUCCESS:
            return {
                loading: false,
                items: action.documents.Body,
                itemsReorder: reorderDocumentalGroups(action?.documents?.Body.Groups),
                error: undefined
            };
        case documentConstants.GET_DOCUMENTS_BY_ROL_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            };
        default:
            return state;
    }
}

export const RegisterMetadaData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.REGISTER_DATA_REQUEST:
            return { loading: true, items: undefined, error: undefined };
        // //break;
        case documentConstants.REGISTER_DATA_SUCCESS:
            return {
                loading: false,
                items: action.metaData.Body,
                error: undefined,
            };
        // //break;
        case documentConstants.REGISTER_DATA_FAILURE:
            return { loading: false, items: undefined, error: action.error };
        // //break;
        default:
            return state;
    }
};

export const OcrData = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_OCRDATA_REQUEST:
            return { loading: true, items: undefined, error: undefined };
        // //break;
        case documentConstants.GET_OCRDATA_SUCCESS:
            return {
                loading: false,
                items: action.ocrData.Body,
                error: undefined,
            };
        // //break;
        case documentConstants.GET_OCRDATA_FAILURE:
            return { loading: false, items: undefined, error: action.error };
        // //break;
        default:
            return state;
    }
};


// ------------------------------------------------------------------

export const GetDocumentGeneratorSelection = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.GET_DOCUMENT_GENERATOR_SELECTION_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}

export const RegisterDocumentGeneratorSelection = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.REGISTER_DOCUMENT_GENERATOR_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.REGISTER_DOCUMENT_GENERATOR_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.REGISTER_DOCUMENT_GENERATOR_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}

export const DownloadDocumentGeneratorSelectionContent = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.DONWLOAD_DOCUMENT_GENERATOR_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.DONWLOAD_DOCUMENT_GENERATOR_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.DONWLOAD_DOCUMENT_GENERATOR_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}

export const ProcessDocumentGeneratorSelection = (state = {}, action) => {
    switch (action.type) {
        case documentConstants.RPOCESS_DOCUMENT_GENERATOR_REQUEST:
            return { loading: true, items: undefined, error: undefined };

        case documentConstants.RPOCESS_DOCUMENT_GENERATOR_SUCCESS:
            return { loading: false, items: action.cargaDocData.Body, error: undefined };

        case documentConstants.RPOCESS_DOCUMENT_GENERATOR_FAILURE:
            return { loading: false, items: undefined, error: action.error };

        default:
            return state;
    }
}