import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import { UseImage } from "./UseImage";
import img from "../assets/Procesando_MC.gif";
import imgPT from "../assets/Procesando_MC_PT.gif";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme) => ({
  imageNotFound: {
    maxWidth: 600,
  },
}));

export default function Summary() {
  const classes = useStyles();
  const { i18n } = useTranslation();

 const currentLanguage = i18n.language || "es";

  return (
    <div>
      <Grid item lg={12} xs={12}>
        <Box display={"flex"} justifyContent="center" m={5}>
          <UseImage
            src={currentLanguage === "pt" ? imgPT : img}
            type="img"
            className={classes.imageNotFound}
          />
        </Box>
        {/* <Box textAlign={'center'} m={5}>
                    <Typography variant='h5'>
                        No tienes acciones asignadas en este momento
                    </Typography>
                </Box> */}
      </Grid>
    </div>
  );
}
