export function getUserEmployes(data) {
    // console.log("itemCtrl: --data***********- ", data)
    if (data.length > 0) {
        // if (data?.questionDefinitions?.length > 0) {
        // console.log("itemCtrl: --data.questionDefinitions***********- ", data.QuestionDefinitions)
        let dataFilter = data.map((itemCtrl) => {
            let userFind = itemCtrl.Roles.filter(item => item.Name === "Empleado" || item.Name === "Usuario Autoconsumo")
            // console.log("itemCtrl: --data.Users***********- ", itemCtrl)
            if (userFind.length > 0) {
                return itemCtrl
            }
        })
        // console.log("itemCtrl: --data.Users****dataFilter*******- ", dataFilter)        
        // console.log("itemCtrl: --data.Users****dataFilter**** dataFilter.filter(item => item !== undefined || item !== null)***- ",  dataFilter.filter(item => item !== undefined && item !== null))

        return dataFilter.filter(item => item !== undefined && item !== null)
    } else {
        return []
    }
}

export function getFormatRoleOptions(data) {
    // console.log("itemCtrl: --data***********- ", data)
    if (data.length > 0) {
        // if (data?.questionDefinitions?.length > 0) {
        // console.log("itemCtrl: --data.questionDefinitions***********- ", data.QuestionDefinitions)
        let dataFilter = data.map((itemCtrl) => {
            return {
                Option: itemCtrl.Description, Value: itemCtrl.Id
            }
        })
        return dataFilter;
    } else {
        return []
    }
}

export function getFormatTenantOptions(data) {
    // console.log("itemCtrl: --data***********- ", data)
    if (data.length > 0) {
        // if (data?.questionDefinitions?.length > 0) {
        // console.log("itemCtrl: --data.questionDefinitions***********- ", data.QuestionDefinitions)
        let dataFilter = data.map((itemCtrl) => {
            return {
                Option: itemCtrl.Name, Value: itemCtrl.Id
            }
        })
        return dataFilter;
    } else {
        return []
    }
}

