import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";

import './index.css';
import './assets/Tipografias/CircularPro-Medium_0.otf';
import { ThemeConfig } from "./theme/ThemeConfig.jsx";
import storepersist from "./store";
import packageJson from "../package.json";
import { Inbox } from './pages/Inbox';
import { PrivateRoute } from './components/PrivateRoute';
import Home from './pages/home/Home';
import LoadingOverlay from 'react-loading-overlay';

// import {
//   theme//Téma con colores de DS
// } from './styles'//Se importan los estilos genéricos

import LoginComponent from './pages/login/LoginComponent'
import DownloadCredentials from './pages/login/DownloadCredentials'
import RecordInfoIndex from './components/tabs/RecordInfoIndex.js';
import MetadataEditingInfoIndex from './components/tabs/MetadataEditingInfoIndex.js';
// import HomeComponent from './pages/home/HomeComponent'
// import SearchComponent from './pages/search/SearchComponent'
// import DashboardComponent from './pages/dashboard/DashboardComponent'
// import NotificationsComponent from './pages/notifications/NotificationsComponent'
// import DetailsComponent from './pages/details/DetailsComponent'
// import SingUpComponent from './pages/singUp/SingUpComponent'
// import GetPassword from './pages/getPassword/GetPassword';
// import UserManagerComponent from './pages/UserManager/UserManagerComponent'
// import BlackListComponent from './pages/BlackList/BlackListComponent'
// import CustomersComponent from './pages/Customers/CustomersComponent'
// import DependenciesComponent from './pages/Dependencies/DependenciesComponent'
// import DocumentsComponent from './pages/Documents/DocumentsComponent'
// import EmployeesComponent from './pages/employees/EmployeesComponent'
// import NewEmployeesComponent from './pages/employees/NewEmployeesComponent'
// import NewEmployeesComponent from './pages/employees/NewEmployeesComponent'
// import RegistrationCandidatesComponent from './pages/RegistrationCandidates/RegistrationCandidatesComponent'

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './msalConfig';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const { store, persistor } = storepersist;

  //useEffect(() => {
  //  // Obtén la URL del archivo CSS desde la variable de entorno
  //  const cssUrl = process.env.REACT_APP_URL_FONTS;
  //
  //  // Crea un elemento link para importar el archivo CSS
  //  const link = document.createElement('link');
  //  link.rel = 'stylesheet';
  //  link.href = cssUrl;
  //
  //  // Agrega el elemento link al head del documento
  //  document.head.appendChild(link);
  //
  //  // Limpia el elemento link al desmontar el componente
  //  return () => {
  //    document.head.removeChild(link);
  //  };
  //}, []); 

  useEffect(() => {
    localStorage.setItem('inicioApp', "inicio");
    caching()
  }, [])

  let caching = async () => {
    let version = localStorage?.getItem('versionApp');
    console.log("version: ", version)
    console.log(" packageJson.version: ", packageJson.version)
    if (version != packageJson.version) {
      if ('caches' in window) {
        simulateAsyncOperation()
          .catch(error => {
            console.error("Caught an error:", error);
          });
        console.log("Ejecuto reload.")
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('versionApp', packageJson.version);
    }
  };

  function simulateAsyncOperation() {
    return new Promise((resolve, reject) => {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });
      resolve("Ejecucion correcta")
    });
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <BrowserRouter
        basename={
          process.env.NODE_ENV == "development"
            ? "/"
            : `${process.env.REACT_APP_BASENAME}`
        }
      >
        <Provider store={store}>
          <PersistGate loading="null" persistor={persistor}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <ThemeConfig>
                <MsalProvider instance={msalInstance}>
                  <Routes>
                    <Route path="/auth">
                      <Route index element={<Navigate to="login" />} />
                      <Route path="login" element={<LoginComponent />} />
                      <Route
                        path="Credentials"
                        element={<DownloadCredentials />}
                      />
                      <Route
                        path="RecordInfoIndex"
                        element={<RecordInfoIndex />}
                      />
                      <Route
                        path="MetadataEditing"
                        element={<MetadataEditingInfoIndex />}
                      />
                    </Route>

                    <Route path="/" element={<PrivateRoute />}>
                      <Route index element={<Navigate to="inbox" />} />
                      <Route path="inbox" element={<Home />}>
                        <Route index element={<Navigate to="dashboards" />} />
                        <Route path="*" element={<Inbox />} />
                      </Route>
                    </Route>

                    {/* <Route path={`/HomeComponent`} component={HomeComponent} />
                        <Route path={`/SearchComponent`} component={SearchComponent} />
                        <Route path={`/DashboardComponent`} component={DashboardComponent} />
                        <Route path={`/NotificationsComponent`} component={NotificationsComponent} />
                        <Route path={`/asignacion/:id`} component={DetailsComponent} />
                        <Route path={`/busqueda/:id`} component={DetailsComponent} />
                        <Route exact path={`/`} component={LoginComponent} />
                        <Route exact path={`/LoginWithId/:id`} component={LoginComponent} />
                        <Route exact path={`/SignUp`} component={SingUpComponent} />
                        <Route path={`/UserManager`} component={UserManagerComponent} />
                        <Route path={`/BlackListComponent`} component={BlackListComponent} />
                        <Route path={`/CustomersComponent`} component={CustomersComponent} />
                        <Route path={`/DependenciesComponent`} component={DependenciesComponent} />
                        <Route path={`/DocumentsComponent`} component={DocumentsComponent} />
                        <Route path={`/EmployeesComponent`} component={EmployeesComponent} />
                        <Route path={`/NewEmployeesComponent`} component={NewEmployeesComponent} /> */}
                    <Route from="*" to="/" />
                    {/* </Switch> */}
                  </Routes>
                </MsalProvider>
              </ThemeConfig>
            </LocalizationProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;