import { Alert } from '../../components';
import { flujoMasivoConstants } from '../constants';
import { flujoMasivoRequests } from '../../services'

export const flujoMasivoActions = {}

flujoMasivoActions.UploadBatchFile2 = (data, isReset = false) => {
  return dispatch => {
    if(isReset){
      dispatch(reset())
    }else{
    dispatch(request())
    flujoMasivoRequests.UploadBatchFile2(data)
      .then(
        dataExcel => dispatch(success(dataExcel)),
        error => dispatch(failure(error.message))
      )  
    }      
  }

  function request() {
    return { type: flujoMasivoConstants.UPLOAD_BATCHFILE_REQUEST }
  }

  function success(dataExcel) {
    console.log(dataExcel);
    return { type: flujoMasivoConstants.UPLOAD_BATCHFILE_SUCCESS, dataExcel }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.UPLOAD_BATCHFILE_FAILURE, error }
  }

  function reset() {
    return { type: flujoMasivoConstants.UPLOAD_BATCHFILE_RESET }
  }
}

flujoMasivoActions.InitializeBatchFile = (data) => {
  console.log('INICIAMASIVO', data)
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.InitializeBatchFile(data)
      .then(
        dataFile => dispatch(success(dataFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: flujoMasivoConstants.INITIALIZATE_BATCHFILE_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.INITIALIZATE_BATCHFILE_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.INITIALIZATE_BATCHFILE_FAILURE, error }
  }
}

flujoMasivoActions.GetFileStage = (data) => {
  console.log('GetFileStage', data)
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.GetFileStage(data)
      .then(
        dataFile => dispatch(success(dataFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: flujoMasivoConstants.GETFILE_STAGE_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.GETFILE_STAGE_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.GETFILE_STAGE_FAILURE, error }
  }
}

flujoMasivoActions.GetAllBulkFile = (data) => {
  console.log('GetAllBulkFile', data)
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.GetAllBulkFile(data)
      .then(
        dataFile => dispatch(success(dataFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: flujoMasivoConstants.GETALL_BULKFILE_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.GETALL_BULKFILE_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.GETALL_BULKFILE_FAILURE, error }
  }
}

flujoMasivoActions.UploadBulkFile = (data, isReset = false) => {
  console.log('UploadBulkFile', data)
  return dispatch => {
    if (isReset) {
      dispatch(reset())
    } else {
      dispatch(request())
      flujoMasivoRequests.UploadBulkFile(data)
        .then(
          dataFile => dispatch(success(dataFile)),
          error => dispatch(failure(error.message))
        )
    }
  }

  function request() {
    return { type: flujoMasivoConstants.UPLOAD_BULKFILE_REQUEST }
  }

  function success(dataFile) {
    console.log('UploadBulkFile dataFile', dataFile)
    console.log(dataFile);
    return { type: flujoMasivoConstants.UPLOAD_BULKFILE_SUCCESS, dataFile }
  }

  function failure(error) {
    console.log('UploadBulkFile error', error)
    return { type: flujoMasivoConstants.UPLOAD_BULKFILE_FAILURE, error }
  }

  function reset() {
    console.log('UploadBulkFile RESET')
    return { type: flujoMasivoConstants.UPLOAD_BULKFILE_RESET }
  }
}

flujoMasivoActions.GetBulkFileDetails = (data, isReset = false) => {
  console.log('GetBulkFileDetails', data)
  return dispatch => {
    if (isReset) {
      dispatch(reset())
    } else {
      dispatch(request())
      flujoMasivoRequests.GetBulkFileDetails(data)
        .then(
          dataFile => dispatch(success(dataFile)),
          error => dispatch(failure(error.message))
        )
    }
  }

  function request() {
    return { type: flujoMasivoConstants.GET_BULK_FILEDETAILS_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.GET_BULK_FILEDETAILS_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.GET_BULK_FILEDETAILS_FAILURE, error }
  }

  function reset() {
    console.log('GetBulkFileDetails RESET')
    return { type: flujoMasivoConstants.GET_BULK_FILEDETAILS_RESET }
  }
}

flujoMasivoActions.UploadBatchRegisterGEID = (data) => {
  console.log('UploadBatchRegisterGEID', data)
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.UploadBatchRegisterGEID(data)
      .then(
        dataFile => dispatch(success(dataFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: flujoMasivoConstants.UPLOAD_BATCHREGISTER_GEID_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.UPLOAD_BATCHREGISTER_GEID_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.UPLOAD_BATCHREGISTER_GEID_FAILURE, error }
  }
}

flujoMasivoActions.GetListGEID = (data) => {
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.GetListGEID(data)
      .then(
        dataFile => {
          dispatch(success(dataFile))
        },
        error => {
          dispatch(failure(error.message))
          // Alert({
          //   icon: 0,
          //   title: error.message,
          // })
        }
      )
  }

  function request() {
    return { type: flujoMasivoConstants.GET_LIST_GEID_MASIVE_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.GET_LIST_GEID_MASIVE_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.GET_LIST_GEID_MASIVE_FAILURE, error }
  }
}


flujoMasivoActions.GetAllBulkMetadata = (data) => {
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.GetAllBulkMetadata(data)
      .then(
        dataFile => {
          dispatch(success(dataFile))
        },
        error => {
          dispatch(failure(error.message))
          // Alert({
          //   icon: 0,
          //   title: error.message,
          // })
        }
      )
  }

  function request() {
    return { type: flujoMasivoConstants.GET_ALL_BULK_METADATA_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.GET_ALL_BULK_METADATA_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.GET_ALL_BULK_METADATA_FAILURE, error }
  }
}

flujoMasivoActions.GetBulkMetadataDetails = (data, isReset = false) => {
  return dispatch => {
    if(isReset){
      dispatch(reset())
    }else{
    dispatch(request())
    flujoMasivoRequests.GetBulkMetadataDetails(data)
      .then(
        dataFile => {
          dispatch(success(dataFile))
        },
        error => {
          dispatch(failure(error.message))
          // Alert({
          //   icon: 0,
          //   title: error.message,
          // })
        }
      )
    }
  }

  function request() {
    return { type: flujoMasivoConstants.GET_BULK_METADATA_DETAILS_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.GET_BULK_METADATA_DETAILS_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.GET_BULK_METADATA_DETAILS_FAILURE, error }
  }

  function reset() {
    return { type: flujoMasivoConstants.GET_BULK_METADATA_DETAILS_RESET }
  }
}

flujoMasivoActions.UploadBulkFileMetadata = (data, isReset = false) => {
  return dispatch => {
    if(isReset){
      dispatch(reset())
    }else{
    dispatch(request())
    flujoMasivoRequests.UploadBulkFileMetadata(data)
      .then(
        dataFile => {dispatch(success(dataFile))},
        error => {dispatch(failure(error.message))
          // Alert({
          //   icon: 0,
          //   title: error.message,
          // })
        }
      )
    }
  }

  function request() {
    return { type: flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_FAILURE, error }
  }

  function reset() {
    return { type: flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_RESET }
  }
}