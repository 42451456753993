import React from "react";
import { Box, Button, Dialog, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./ModalStyles";
import VisorPDF from "../Visor/VisorPDF";

export function ModalVisualPDF({ pdfUrl, open, handleClose }) {
  const theme = useTheme();
  const localStyles = useStyles(theme);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          overflow: "hidden",
          minHeight: "80%",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 0 }}>
        <IconButton size="small">
          <CloseIcon
            style={{ color: "#898B8D" }}
            className={localStyles.closeIconNew}
            onClick={handleClose}
          />
        </IconButton>
      </Box>
      <VisorPDF pdfUrl={pdfUrl} height="100vh" />
    </Dialog>
  );
}
