import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const flujoMasivoRequests = {}

flujoMasivoRequests.UploadBatchFile2 = (data) => {
  return axios.post(
    AppUrls.UploadBatchFile2,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.InitializeBatchFile = (data) => {
  return axios.post(
    AppUrls.InitializeBatchFile,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.UploadBulkFile = (data) => {
  return axios.post(
    AppUrls.UploadBulkFile,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.GetFileStage = (data) => {
  return axios.post(
    AppUrls.GetFileStage,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}


flujoMasivoRequests.GetBulkFileDetails = (data) => {
  return axios.post(
    AppUrls.GetBulkFileDetails,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.UploadBatchRegisterGEID = (data) => {
  return axios.post(
    AppUrls.UploadBatchRegisterGEID,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.GetListGEID = (data) => {
  return axios.post(
    AppUrls.GetListGEID,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.GetAllBulkFile = (data) => {
  return axios.post(
    AppUrls.GetAllBulkFile,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.GetAllBulkMetadata = (data) => {
  return axios.post(
    AppUrls.GetAllBulkMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.GetBulkMetadataDetails = (data) => {
  return axios.post(
    AppUrls.GetBulkMetadataDetails,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.UploadBulkFileMetadata = (data) => {
  return axios.post(
    AppUrls.UploadBulkFileMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

let handleResponse = (response) => {
  console.log('responseBulk', response)
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Message || responseObject);
    console.log(responseObject)
    console.log(error)
    return Promise.reject(error);
  }
  return respObject;
}