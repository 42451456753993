//Variable que indica la activacion de encriptacion a todas las peticiones
export const algorithm = process.env.REACT_APP_ENCRIPTED_ALGORITHM
export const prPublicKeyDSC = process.env.REACT_APP_ENCRIPTED_PUBLICDSC_KEY
export const prPublicKey = process.env.REACT_APP_ENCRIPTED_PUBLIC_KEY
export const prPrivateKey = process.env.REACT_APP_ENCRIPTED_PRIVATE_KEY
export const APIKEYGOOGLE = process.env.REACT_APP_GOOGLE_API_KEY
export const ReferCredentials = process.env.REACT_APP_REFER_CREDENTIALS

//Variable que indica la activacion de encriptacion a todas las peticiones
export const constEncripted = {
  encriptedBody: false
};

export const constPathRoot = {
  pathRoot: process.env.NODE_ENV === "development" ? '' : `${process.env.REACT_APP_BASENAME}`
}

export const ApiUrl = {
  ambient: `${process.env.REACT_APP_DSONBOARDING_API}`
}

export const ApiUrlVC = {
  ambientVC: `${process.env.REACT_APP_URL_VERIFY_API}`
}

/* export const ApiVC = {
 
  ambientVC: `https://verifycredentials.dscloud.com.mx/api/`
} */

export const constUrlGraficas = {
  ambient: `${process.env.REACT_APP_GRAFICAS}`
};