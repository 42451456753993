import moment from 'moment';
import { constPathRoot } from './PathPublish'
const { createHash } = require('crypto')

export const paths = {
  home: `${constPathRoot.pathRoot}/HomeComponent`,
  // search: `${constPathRoot.pathRoot}/SearchComponent`,
  report: `${constPathRoot.pathRoot}/DashboardComponent`,
  notice: `${constPathRoot.pathRoot}/NotificationsComponent`,
  folio: 'asignacion',
  folioSearch: 'busqueda',
  userManager: `${constPathRoot.pathRoot}/UserManager`,
  blackList: `${constPathRoot.pathRoot}/BlackListComponent`,
  customers: `${constPathRoot.pathRoot}/CustomersComponent`,
  dependencies: `${constPathRoot.pathRoot}/DependenciesComponent`,
  documents: `${constPathRoot.pathRoot}/DocumentsComponent`,
  employees: `${constPathRoot.pathRoot}/EmployeesComponent`,
  newEmployees: `${constPathRoot.pathRoot}/NewEmployeesComponent`,
  RegistrationCandidates: `${constPathRoot.pathRoot}/Registration`,
  search: `${constPathRoot.pathRoot}/inbox/search`,
  homeComponent: `${constPathRoot.pathRoot}/inbox/homeComponent`,
  homeComponentSingle: `${constPathRoot.pathRoot}/inbox/homeComponent/single`,
  homeComponentMasive: `${constPathRoot.pathRoot}/inbox/homeComponent/masive`,
}

export const routes = {
  home: '/inbox/homeComponent',
  search: '/inbox/busqueda',
  report: '/inbox/dashboards',
  notice: '/NotificationsComponent',
  userManager: '/UserManager',
  blackList: '/BlackListComponent',
  customers: '/CustomersComponent',
  dependencies: '/DependenciesComponent',
  documents: '/DocumentsComponent',
  employees: '/EmployeesComponent',
  newEmployees: '/NewEmployeesComponent',
  RegistrationCandidates: '/Registration',
  busqueda: `/inbox/busqueda`,
  asignacion: `/inbox/asignacion`,
}

export const getDateHour = (dateHour) => {
  let theDate = dateHour.split("T")[0]
  let hour = dateHour.split("T")[1].split(".")[0].split(":")[0]
  let min = dateHour.split("T")[1].split(".")[0].split(":")[1]
  let seg = dateHour.split("T")[1].split(".")[0].split(":")[2]
  // let twelveHour = () => {
  //   if (hour > 11) {
  //     return hour - 12
  //   } else {
  //     return hour
  //   }
  // }
  let amOrPm = () => {
    if (hour > 12) {
      return "PM"
    } else {
      return "AM"
    }
  }
  // let formatDate = `${theDate} ${twelveHour()}:${min}:${seg} ${amOrPm()}`
  let formatDate = `${theDate} ${hour}:${min}:${seg}`

  return formatDate
}

export function getHash(string) {
  return createHash('sha256').update(string).digest('hex');
}

export function getFormatComboArray(data) {
  if (data?.length > 0) {

    let listOptions = data.map((item, index) => {
      return {
        id: item.Id,
        label: item.Name,
        value: item.Id,
        required: true
      }
    }
    )

    console.log("listOptions: --------------------------- ", listOptions)
    return listOptions
  } else {
    return []
  }
}

// export const B64toBlobAsync = async (b64Data, contentType = '', sliceSize = 512) => {
//   let atob = require('atob');
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// }

export const convertPdfToB64 = async (file) => {
  const fileConverted = await toBase64Async(file);
  const base64result = fileConverted.split(",")[1];
  // const wordUrl = URL.createObjectURL(file);
  // return { base64result, wordUrl };
  return { base64result }
};

const toBase64Async = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};


export const blobToBase64 = blob => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  } catch (e) {
    console.log("blobToBase64: ", e)
  }
};

// export const B64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
//   let atob = require('atob');
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// }

export const B64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function obtenerDireccionIP(callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      const respuesta = JSON.parse(xhr.responseText);
      const ip = respuesta.ip;
      callback(ip);
    } else {
      callback(null);
    }
  };
  xhr.open('GET', 'https://api.ipify.org?format=json');
  xhr.send();
}

export const IsDevice = () => {
  let IsAndroid = navigator.userAgent.match(/Android/i) ? true : false
  let IsIOS = navigator.userAgent.match(/iPhone/i) ? true : false
  let IsMobile = (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) ? true : false

  return { IsAndroid, IsIOS, IsMobile }
}

export const ValidarSICumpleCondiciones = (expresion, value1, value2) => {
  if (expresion.toUpperCase() == "equals".toUpperCase() || expresion == "==") {
    return value1 == value2;
  } else if (expresion.toUpperCase() == "not equals".toUpperCase() || expresion == "!=" || expresion == "<>") {
    return value1 != value2;
  } else {
    return false
  }
}

export const generateUniqueId = () => {
  const timestamp = Date.now(); // Obtiene el timestamp actual
  const randomNum = Math.floor(Math.random() * 10000); // Genera un número aleatorio
  return `${timestamp}-${randomNum}`;
}

export const ForceFormatDate = (currentDate, isGrid = false) => {
  if (currentDate == undefined || currentDate == null || currentDate == '') {
    return ''
  }
  console.log("ForceFormatDate  Date: ", currentDate)

  try {
    if (currentDate.toString()?.toUpperCase()?.includes("DD/MM/AA") || currentDate.toString()?.toUpperCase()?.includes("DD/MM/AAAA")) {
      console.log("ForceFormatDate parsedDate Date: ", currentDate, currentDate.toString()?.length)
      return `Fecha ${currentDate} inválida`;
    } else if (currentDate.toString()?.includes("/") && currentDate.toString()?.length <= 8) {
      console.log("ForceFormatDate parsedDate Date: ", currentDate, currentDate.toString()?.length)
      return `Fecha ${currentDate}(dd/MM/aa) inválida, fecha dd/MM/aaaa válida`;
    }
    else if (currentDate.toString()?.includes("/") && isGrid) {
      const [day, month, year] = currentDate.toString()?.split('/'); // Split the string by '/'
      let parsedDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)); // Create a new Date object
      console.log("ForceFormatDate parsedDate Grid Date: ", parsedDate)
      let parsedDateFormat = moment(new Date(parsedDate), "DD/MM/YYYY").toISOString();
      console.log("ForceFormatDate parsedDateFormat Grid Date: ", parsedDateFormat)
      if (parsedDateFormat.toString()?.includes("-") && parsedDateFormat.toString()?.includes("T")) {
        console.log("ForceFormatDate parsedDate2--1 Grid Date: ", parsedDateFormat)
        const parsedDate = moment(parsedDateFormat, 'YYYY/MM/DD').format('DD/MM/YYYY');
        return parsedDate;
      } else {
        return parsedDateFormat;
      }
    } else if (currentDate.toString()?.includes("/")) {
      const [day, month, year] = currentDate.toString()?.split('/'); // Split the string by '/'
      const parsedDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)); // Create a new Date object
      console.log("ForceFormatDate parsedDate simple Date: ", parsedDate)
      return parsedDate;
    } else if (currentDate.toString()?.includes("-") && currentDate.toString()?.includes("T")) {
      console.log("ForceFormatDate parsedDate2 Date: ", parsedDate)
      const parsedDate = moment(new Date(currentDate), "DD/MM/YYYY").toISOString();
      return parsedDate;
    } else {
      console.log("ForceFormatDate moment Date: ", moment(currentDate, "DD/MM/YYYY").toISOString())
      return moment(currentDate, "DD/MM/YYYY").toISOString();
    }
  } catch {
    console.log("ForceFormatDate Catch Date: ", currentDate)
    return new Date(currentDate)
  }
}

export const CurrencyFormat = (inputText, type = 'Currency') => {
  console.log("------***CurrencyFormat****----Entrada--:", inputText, ", type:", type)
  switch (type) {
    case 'Currency':
      console.log("------***CurrencyFormat****---Currency-Salida--:", inputText?.replace("$", "")?.replace(/\,/g, ''), ", type:", type)
      return inputText?.replace("$", "")?.replace(/\,/g, '');
    case 'CurrencyEuropean':
      let formatReturned = 0
      if (inputText?.toString()?.includes('$') && inputText?.toString()?.includes(',') && inputText?.toString()?.includes('.')) {
        formatReturned = inputText?.toString()?.replace("$", "")?.replace(/\./g, '')?.replace(",", ".");
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-1--:", formatReturned, ", type:", type)
      }
      else if (inputText?.toString()?.includes('$') && inputText?.toString()?.includes('.')) {
        formatReturned = inputText?.toString()?.replace("$", "")?.replace(/\./g, '')
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-2--:", formatReturned, ", type:", type)
      }
      else if (!inputText?.toString()?.includes('$') && inputText?.toString()?.includes(',')) {
        formatReturned = inputText?.toString()?.replace("$", "")?.replace(/\,/g, '.')
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-3--:", formatReturned, ", type:", type)
      }
      else if (!inputText?.toString()?.includes('$') && inputText?.toString()?.includes('.')) {
        formatReturned = inputText?.toString()
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-4--:", formatReturned, ", type:", type)
      } else{
        formatReturned = inputText?.toString()
        console.log("------***CurrencyFormat****---CurrencyEuropean-Salida-formatReturned-5--:", formatReturned, ", type:", type)
      }   
      return formatReturned
    default:
      console.log("------***CurrencyFormat****---default-Salida--:", inputText?.replace("$", "")?.replace(/\,/g, ''), ", type:", type)
      return inputText?.replace("$", "")?.replace(/\,/g, '');
  }
};