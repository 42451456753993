import { roleConstants } from '../constants';
import { roleRequests } from '../../services'

export const roleActions = {}

roleActions.GetRoles = (data) => {
  return dispatch => {
    dispatch(request())
    roleRequests.GetRoles(data)
      .then(
        roleData => dispatch(success(roleData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: roleConstants.GET_ROLES_REQUEST }
  }

  function success(roleData) {
    return { type: roleConstants.GET_ROLES_SUCCESS, roleData }
  }

  function failure(error) {
    return { type: roleConstants.GET_ROLES_FAILURE, error }
  }
}

roleActions.GetTenats = (data) => {
  return dispatch => {
    dispatch(request())
    roleRequests.GetTenats(data)
      .then(
        roleData => dispatch(success(roleData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: roleConstants.GET_TENANTS_REQUEST }
  }

  function success(roleData) {
    return { type: roleConstants.GET_TENANTS_SUCCESS, roleData }
  }

  function failure(error) {
    return { type: roleConstants.GET_TENANTS_FAILURE, error }
  }
}