import { graphConstants } from '../constants/index.js'

export const GetGenericGraphics = (state = {}, action) => {
    // console.log('actionaction', action)
    switch (action.type) {
        case graphConstants.GET_GENERIC_GRAPHS_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case graphConstants.GET_GENERIC_GRAPHS_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case graphConstants.GET_GENERIC_GRAPHS_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}