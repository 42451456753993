import React, { useEffect, useState } from "react";
import { List, arrayMove } from "react-movable";
import { Box } from "@mui/system";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import iconMenu from "../assets/icons/Dragable.svg";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  pointerEvents: "none",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.TableHeader,
    color: theme.palette.primary.mainLyrics,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.lightContrast,
  },
  "&:last-child td, &:last-child`  th": {
    border: 0,
  },
}));

export default function ReorderDocuments(propsComponent) {
  const { t } = useTranslation();

  useEffect(() => {
    if (propsComponent.data !== null && propsComponent.data.length > 0) {
      propsComponent.setNewOrdenDocs(propsComponent.data);
    } else {
      propsComponent.setNewOrdenDocs([]);
    }
  }, [propsComponent.data]);

  return (
    <div>
      {" "}
      <Box sx={{ height: "40vh", overflow: "auto" }}>
        <List
          values={propsComponent.data}
          onChange={({ oldIndex, newIndex }) => {
            propsComponent.setDocumentNewList(
              arrayMove(propsComponent.newOrdenDocs, oldIndex, newIndex)
            );
          }}
          renderList={({ children, props }) => (
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ color: "primary.main80" }}>
                <TableRow>
                  <StyledTableCell>{t("text_selection_table")}</StyledTableCell>
                  <StyledTableCell>{t("text_order_table")}</StyledTableCell>
                  <StyledTableCell>{t("text_type_table")}</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: 150 }}>
                    {t("text_platilla_table")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: 150 }}>
                    {t("request_actions")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody {...props}>{children}</TableBody>
            </Table>
          )}
          renderItem={({ value, props, isDragged }) => {
            const rowcontent = (
              <>
                {console.log("valuevalue", value)}
                <StyledTableCell sx={{ pointerEvents: "auto" }}>
                  <img src={iconMenu} alt="imagen" />
                  {/* <Menu sx={{ mt: "7px" }} /> */}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  <span>{isNaN(props.key + 1) ? "x" : props.key + 1}</span>
                </StyledTableCell>
                <StyledTableCell>{value.DocumentType_Name}</StyledTableCell>
                <StyledTableCell>{value.TemplateName}</StyledTableCell>
                <StyledTableCell>{value.invoice}</StyledTableCell>
              </>
            );
            if (isDragged) {
              return (
                <Box {...props} sx={{ cursor: "grabbing" }}>
                  <Table>
                    <TableBody>
                      <StyledTableRow>{rowcontent}</StyledTableRow>
                    </TableBody>
                  </Table>
                </Box>
              );
            }
            return (
              <StyledTableRow {...props} sx={{ cursor: "grab" }}>
                {rowcontent}
              </StyledTableRow>
            );
          }}
        />
      </Box>
      <Box
        sx={{
          mt: 3,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Button
            id="btnConfirmReorderDocs"
            variant="contained"
            sx={{ height: 45, minWidth: 300 }}
            type="submit"
            onClick={() => propsComponent.onClickButton()}
          >
            {propsComponent.textButton}
          </Button>
        </Box>
      </Box>
    </div>
  );
}
