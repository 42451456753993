import React, { useState } from 'react'
import { useStyles } from './TableStyle'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel , styled, useTheme
} from '@mui/material'

import { paths } from '../../utils'

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
}
}));

export const MainTableEmployees= (props) => {
  const theme = useTheme()
  const localStyle = useStyles(theme)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  let path = window.location.pathname

  // console.log("data: INFO", props.data)

  const newData = props.data?.map((col) => {
    return {
      Nombre: col.Name + ' ' + col.FatherLastName + ' ' + col.MotherLastName,
      Correo: col.Email,
      Usuario: col.Username,
      Telefono: col.PhoneNumber,
      Activo: col.Active,
      Bloqueado: col.Locked,
      Fecha: col.CreationDate,
      Id: col.Id,
    }
  })

  const columns = [
    { id: 'Nombre', label: 'Nombre', minWidth: 200, height: 74 },
    { id: 'Correo', label: 'Correo', minWidth: 170 },
    { id: 'Usuario', label: 'Usuario', minWidth: 70 },
    { id: 'Telefono', label: 'Telefono', minWidth: 70 },
    // { id: 'Activo', label: 'Activo', minWidth: 70 },
    // { id: 'Bloqueado', label: 'Bloqueado', minWidth: 70 },
    { id: 'Fecha', label: 'Fecha', minWidth: 70 },
  ]

  const createData = (Nombre, Correo, Usuario, Telefono, Activo, Bloqueado, Fecha, Id) => {
    return { Nombre, Correo, Usuario, Telefono, Activo, Bloqueado, Fecha, Id }
  }

  const rows = newData?.map((r) => {
    return createData(r.Nombre, r.Correo, r.Usuario, r.Telefono, r.Activo, r.Bloqueado, r.Fecha, r.Id)
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  const handlerSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property)
  }

  // const descendingCompatador = (a, b, orderBy) => {
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1
  //   } else if (b[orderBy] < a[orderBy]) {
  //     return 1
  //   } else {
  //     return 0
  //   }

  // }

  // const getComparator = (order, orderBy) => {
  //   return order === 'desc' ? (a, b) => descendingCompatador(a, b, orderBy) : (a, b) => -descendingCompatador(a, b, orderBy)
  // }

  // const sortRowInformation = (newArray, comparator) => {
  //   const stabilizedRowArray = newArray.map((element, index) => [element, index])
  //   stabilizedRowArray.sort((a, b) => {
  //     const order = comparator(a[0], b[0])
  //     if (order !== 0) {
  //       return order
  //     } else {
  //       return stabilizedRowArray.map((element) => element[0])
  //     }
  //   })
  // }

  return (
    <div className={localStyle.table2}>
      <TableContainer sx={{ width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => { //sortRowInformation(rows, getComparator(orderDirection,valueToOrderBy))
                return (
                  <TableRowCustom hover role="checkbox" tabIndex={-1} key={row.code}
                    onClick={props.onClick} >
                    {columns.map((column) => {
                      const value1 = row[column.id];
                      const value = value1 == true ? 'SI' : value1 == false ? 'NO' : value1

                      return (
                        <TableCell id={row.Id} key={column.id} align={column.align}
                          style={{ minWidth: column.minWidth, height: column.height }} >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={localStyle.tablePaginacion2}
        rowsPerPageOptions={[6]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  )
}