import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from '../../../components/table/TableStyle'

import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Tooltip as tooltipClasses,useTheme
} from '@mui/material';
import { Icon } from '@iconify/react'

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 15,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  }
}));


export function TableDataUsers({ data }) {
  const theme = useTheme()
  console.log(data)
  const localStyle = useStyles(theme)
  let path = window.location.pathname

  // console.log("data: INFO", data)

  const newData = data?.map((col) => {
    return {
      Nombre: col.Nombre,
      aMaterno: col.ApellidoMaterno,
      aPaterno: col.ApellidoPaterno,
      Celular: col.Celular,
      Correo: col.Email,
      Area: col.Area,
      Puesto: col.Puesto,
      Pais: col.Pais,
      Clave: col.ClaveGrupo,
      Estatus: col.State,
      Error: col.ErrorToolTip
    }
  })

  const columns = [
    { id: 'cacidato', label: 'Candidato', minWidth: 100 },
    { id: 'contrato', label: 'Tipo de contratación', minWidth: 100 },
    { id: 'typeContrato', label: 'Tipo de contrato', minWidth: 100 },
    { id: 'noRequisicion', label: 'Núm. requisición', minWidth: 100 },
    { id: 'fecha', label: 'Fecha de Contratación', minWidth: 100 },
    { id: 'company', label: 'Compañía', minWidth: 100 },
    { id: 'tituloEspanol', label: 'Titulo español', minWidth: 100 },
    { id: 'baseSENA', label: 'Sueldo Base SENA', minWidth: 100 },
    { id: 'salarioBase', label: 'Salario Base ', minWidth: 100 },
    { id: 'salarioPlan', label: 'Salario Plan', minWidth: 100 },
  ]

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  const handlerSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property)
  }

  return (
    <div style={{ margin: '0 1em 0 1em' }}>
      <TableContainer className={localStyle.container}>
        <Table size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  className={localStyle.head}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => { //sortRowInformation(rows, getComparator(orderDirection,valueToOrderBy))
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}
                    className={localStyle.tableRow}
                  >
                    <TableCell key={row.id} >
                      {row['Candidato']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Tipo de Contratación']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Tipo de Contrato']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Número de Requisición']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Fecha de Contratación']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Compañia']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Título en español']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Sueldo base para SENA']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Salario Base']}
                    </TableCell>
                    <TableCell key={row.id} >
                      {row['Kit de contratación_Salary Plan']}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[0]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}