import { reportsConstants } from '../constants'
import { reportDataRequest } from '../../services'

export const reportsActions = {}

reportsActions.GetReportData = (data, isReset = false) => {
    return dispatch => {
        if (isReset) {
            dispatch(reset())
        } else {
            dispatch(request())
            reportDataRequest.getReportData(data)
                .then(
                    product => dispatch(success(product)),
                    error => dispatch(failure(error.message))
                )
        }

    }

    function request() {
        return { type: reportsConstants.GET_DATA_REPORTS_REQUEST }
    }

    function success(product) {
        return { type: reportsConstants.GET_DATA_REPORTS_SUCCESS, product }
    }

    function failure(error) {
        return { type: reportsConstants.GET_DATA_REPORTS_FAILURE, error }
    }
    function reset() {
        console.log('AutographSign RESET')
        return { type: reportsConstants.GET_DATA_REPORTS_RESET }
    }
}

reportsActions.GetReports = (data) => {
    return dispatch => {
        dispatch(request())
        reportDataRequest.getReports(data)
            .then(
                product => dispatch(success(product)),
                error => dispatch(failure(error.message))
            )
    }

    function request() {
        return { type: reportsConstants.GET_REPORTS_REQUEST }
    }

    function success(product) {
        return { type: reportsConstants.GET_REPORTS_SUCCESS, product }
    }

    function failure(error) {
        return { type: reportsConstants.GET_REPORTS_FAILURE, error }
    }

}