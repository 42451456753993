import { Alert } from '../../components';
import { graphConstants } from '../constants';
import { graphRequest } from '../../services'

export const graphActions = {}

graphActions.GetGenericGraphics = (data) => {
    console.log('INICIAMASIVO', data)
    return dispatch => {
      dispatch(request())
      graphRequest.GetGenericGraphics(data)
        .then(
          dataFile => dispatch(success(dataFile)),
          error => dispatch(failure(error.message))
        )
    }
  
    function request() {
      return { type: graphConstants.GET_GENERIC_GRAPHS_REQUEST }
    }
  
    function success(dataFile) {
      console.log('dataFile', dataFile);
      return { type: graphConstants.GET_GENERIC_GRAPHS_SUCCESS, dataFile }
    }
  
    function failure(error) {
      return { type: graphConstants.GET_GENERIC_GRAPHS_FAILURE, error }
    }
  }