import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { MainTableDinamyc } from "../../components/table/MainTableDinamyc";

export default function TableDataDocumentsFilters({
  data,
  OnClickData,
  CleanData,
  ReloadData,
}) {
  console.log("datadata", data);

  const columns = [
    { id: "identificador", label: "Identificador", minWidth: 170 },
    { id: "status", label: "Estatus", minWidth: 170 },
    { id: "folioAsociado", label: "Folio asociado", minWidth: 170 },
    { id: "date", label: "Fecha de asociación", minWidth: 170 },
    { id: "message", label: "Mensaje", minWidth: 170 },
  ];

  const newData = data?.map((col) => {
    return {
      identificador: col.Identifier,
      status: col.Status,
      folioAsociado: col.AssociatedFolio,
      date: col.CreationDate,
      message: col.ErrorToolTip,
    };
  });

  const createData = (identificador, status, folioAsociado, date, message) => {
    return { identificador, status, folioAsociado, date, message };
  };

  const rows = newData?.map((r) => {
    return createData(
      r.identificador,
      r.status,
      r.folioAsociado,
      r.date,
      r.message
    );
  });

  return (
    <Grid>
      <Grid container justifyContent={"flex-end"} alignContent={"flex-end"}>
        <Grid item lg={2} sx={{ m: 0.8 }}>
          <Button
            variant="outlined"
            sx={{ height: 45, m: 1 }}
            fullWidth
            color="primary"
            onClick={CleanData}
          >
            Regresar
          </Button>
        </Grid>
        <Grid item lg={2} sx={{ m: 0.8, mr: 3 }}>
          <Button
            variant="contained"
            sx={{ height: 45, m: 1 }}
            fullWidth
            color="primary"
            onClick={ReloadData}
          >
            Actualizar
          </Button>
        </Grid>
      </Grid>
      <MainTableDinamyc
        Rows={rows}
        Columns={columns}
        OnClickData={OnClickData}
      />
    </Grid>
  );
}
