import { Avatar, Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'

export default function InboxRegistrationsCandidates({ Data, setListControls }) {
    console.log('Paises', Data)
    return (
        <Grid container alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
            {Data.map((item) =>
                <Grid lg={11}>
                    <Paper elevation={4} sx={{ m: 2 }} onClick={() => setListControls(item)} >
                        <Box sx={{ p: 4, display: 'flex' }}>
                            <Box sx={{ p: 2, display: "flex", alignContent: 'center', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        color: "primary.mainLyrics",
                                        backgroundColor: "primary.main",
                                        fontWeight: 600,
                                        width: 50,
                                        height: 50,
                                        fontSize: 3,
                                        mt: 0.7,
                                    }}
                                    alt={'user'}
                                />
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Box>
                                    <Typography variant='h4'>
                                        {item.Name}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant='subtitle'>
                                        {item.Description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            )}
        </Grid>
    )
}
