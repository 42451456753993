import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button, styled, useTheme
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { withStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';

import { paths, getDateHour } from '../../utils'
import { UseImage, GenericHeaderActions } from '../index';
import { ModalApproval } from '../modals';
//ICONS
import loadDoc from '../../assets/loadDoc.svg'
import profile from '../../assets/perfil.png';
import groupPhoto from '../../assets/grupo.svg'

import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  color: `${theme.palette.secondary.dark} !important`,
  fontWeight: 'bold !important',
}));

export function GeneralInfo({ data, docs, val, actors, getInfoDispatch, EventButton }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const cardStyle = mainStyles.cards()
  const path = window.location.pathname

  const [rows, setRows] = useState([{ name: 'Folio:', details: data.Folio }])

  //*Tooltip

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.secondary.light,
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  //*General Data Table*
  const { id } = useParams()
  const createData = (name, details) => {
    return { name, details };
  }

  const getSomeMetada = (array) => {
    console.log("********************Datos generales¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨************: ", array)
    // const arrayData = array.filter((data) => data.Name === "Nivel de riesgo" || data.Name === "Tipo de crédito"
    //   || data.Name === "Dependencia" || data.Name === "Tipo de proceso")
    const arrayData = array.filter((data) => data.Name === "Puesto"
      || data.Name === "Dependencia" || data.Name === "Tipo de proceso" || data.Name === "RFC")
    //"Puesto"
    arrayData.forEach(element => {
      setRows(rows => [...rows, createData(`${element.Name}`, element.Value?.toUpperCase())])
    });
  }

  useEffect(() => {
    console.log("data*******: ", data)
    if (data.Name !== null) {
      setRows(rows => [...rows, createData('Nombre(s):', `${data.FullName}`)])
    }
    // if (data.FatherLastName !== null) {
    //   setRows(rows => [...rows, createData('Apellido Paterno:', data.FatherLastName)])
    // }
    // if (data.FatherLastName !== null) {
    //   setRows(rows => [...rows, createData('Apellido Materno:', data.MotherLastName)])
    // }
    if (data.PhoneNumber !== null) {
      setRows(rows => [...rows, createData('Celular:', data.PhoneNumber)])
    }
    if (data.Email !== null) {
      setRows(rows => [...rows, createData('Correo:', data.Email)])
    }
    if (data.CreationDateFormatted !== null) {
      setRows(rows => [...rows, createData('Fecha de creación:', data.CreationDateFormatted)])
    }
    // if (data.CreationDate !== null) {
    //   setRows(rows => [...rows, createData('Fecha de creación:', data.CreationDate)])
    // }
    if (data.Metadata.length > 0) {
      // console.log("data.Metadata***************", data.Metadata)
      getSomeMetada(data.Metadata)
    }
  }, [])

  return (
    <div className={docs.length > 0 ? localStyles.tabWithData : localStyles.tabWithoutDocs}>
      <div className={docs.length > 0 ? localStyles.data : localStyles.dataThree}>
        <GenericHeaderActions LabelTitle="Datos generales" />
        <div className={localStyles.personalInfo}>

          <UseImage src={actors?.length > 0 ? groupPhoto : profile} className={localStyles.img} />

          <TableContainer className={localStyles.table}>
            <Table>
              <TableBody>
                {rows.map((row) => (
                  <TableRow>
                    {console.log('ROW', row)}
                    <TableCellCustom align="left" style={{
                      borderBottom: "none",
                      paddingBottom: 10,
                    }} padding="none"
                    >{row.name}</TableCellCustom>
                    {row.name === "Nivel de riesgo" ? <TableCell align="left" style={{
                      borderBottom: "none",
                      paddingBottom: 10
                    }} padding="none"
                    >{row.details}</TableCell>
                      : <TableCell align="left" style={{
                        borderBottom: "none",
                        paddingBottom: 10
                      }} padding="none" >{row.details}</TableCell>}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="center" style={{
                    borderBottom: "none",
                    paddingBottom: 10,
                    width: "100%"
                  }} padding="none" colSpan={2} >
                    <Button style={{
                      height: 38,
                      width: '100%',
                      margin: '1em',
                      borderRadius: '25px',
                      fontSize: 16,
                      fontWeight: 500,
                      maxWidth: 350,
                    }} variant='contained' color="primary"
                      onClick={() => {

                        EventButton();
                      }}>
                      Editar información
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {path.includes(paths.folio)
            && (val.FileApproval === null
              && val.ShowSelectApproval === true
              && (val.ApprovalInDocument === "0" ||
                val.ApprovalInDocument === null ||
                val.ApprovalInDocument == false)
              && val.ActorVal === null)
            //   ||
            // (val.ManualDocValidation !== undefined && val.ManualDocValidation !== null
            //   && val.ManualDocValidation === "1" && val.ShowSelectApproval == true
            // )
            ?
            <ModalApproval folioId={data.Id} actorId={null} />
            : ""}
        </div>
      </div>
      {
        docs.length > 0 &&
        <div className={localStyles.dataTwo}>

          {/*lista de documentos*/}
          <div>
            <div className={localStyles.miniContainer}>
              <Typography variant="p" className={localStyles.title}>Documentación cargada
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography variant="p" className={localStyles.docs}>Estatus de la documentación:</Typography>
                      <br></br>
                      <Typography variant="p" className={localStyles.docs}> <CheckCircleIcon style={{ color: "#28A745" }} />Documento aprobado</Typography>
                      <br></br>
                      <Typography variant="p" className={localStyles.docs}> <CancelIcon style={{ color: "red" }} />Documento rechazado</Typography>
                      <br></br>
                      <Typography variant="p" className={localStyles.docs}> <CircleIcon style={{ color: "#28A745" }} />Documento cargado</Typography>
                    </React.Fragment>
                  }
                  placement='bottom-start'
                >
                  <HelpIcon />
                </HtmlTooltip></Typography>

              <Button
                variant="contained"
                sx={{ height: 45, px: 10, maxWidth: 300 }}
                startIcon={< RefreshIcon />}
                onClick={getInfoDispatch} >
                Actualizar
              </Button>
            </div>

            <div >
              {docs.map((card) => {
                if (card.grupo !== '.CER' && card.grupo !== '.KEY') {
                  return <Accordion
                    classes={{
                      root: localStyles.accordion
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={localStyles.textGroup} >{card.grupo}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* {console.log("Estatus de cards: --------: ", card)} */}
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {card.documentos.map((row) => (
                              <TableRow>
                                <TableCell align="left" style={{
                                  borderBottom: "none",
                                  paddingBottom: 10,
                                }} padding="none"
                                  width="4%"
                                  className={localStyles.docs}>
                                  {row.validacion === null ? <UseImage src={loadDoc} type='img' className={cardStyle.loadDocIcon} />
                                    : row.validacion ? <CheckCircleIcon style={{ color: "#28A745" }} />
                                      : row.validacion === false ? <CancelIcon style={{ color: "red" }} /> : ''}
                                </TableCell>
                                <TableCell align="left" style={{
                                  borderBottom: "none",
                                  paddingBottom: 10,
                                }} padding="none"
                                  width="41%"
                                  className={localStyles.docs}>
                                  {row.nombre}
                                </TableCell>
                                <TableCell align="center" style={{
                                  borderBottom: "none",
                                  paddingBottom: 10,
                                }} padding="none"
                                  width="25%"
                                  className={localStyles.information}>
                                  {row.fechaDeVal}
                                  {/* {row.fechaDeVal !== null ? getDateHour(row.fechaDeVal) : ''} */}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </AccordionDetails>
                  </Accordion>
                } else {
                  return ''
                }
              })}
            </div>

          </div>
        </div>
      }
    </div>
  )
}