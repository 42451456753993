import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

const getCurrentHost = process.env.NODE_ENV == "development" ? 'http://localhost:3000' : process.env.PUBLIC_URL

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: process.env.REACT_APP_LANGUAGE || "es",
        lng: process.env.REACT_APP_LANGUAGE || "es",
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
        }
    });

export default i18n;