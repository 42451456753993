import React, { useEffect, useState } from 'react';
import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'
import { UseImage } from '../index';

import ine from '../../assets/id.svg'
import passport from '../../assets/passport.svg'
import curp from '../../assets/CURP.svg'
import comprobante from '../../assets/ComprobanteGen.svg'
import consentimiento from '../../assets/consentimiento.svg'
import contrato from '../../assets/contrato.svg'
import privacidad from '../../assets/privacidad.svg'
import ingresos from '../../assets/ingresos.svg'
import nom from '../../assets/NOM.svg'
import docGenerico from '../../assets/docGenerico.svg'
import NoDocs from '../../assets/NoDocs.png'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import loadDoc from "../../assets/loadDoc.svg";
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import {
  Typography,
  Card,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box, useTheme, IconButton
} from '@mui/material';

import {
  ModalVisual,
  ActorMVisual,
} from '../modals';
import { CustomAlert } from "../CustomAlert";
import { documentActions } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { documentRequests } from '../../services';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export function Documentation({ cards, val, docsA, cActor, refresh, bioData, IsValidate, FinaliceValidaction, fileId, activaFinalizaValidacion }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const localStyles = useStyles(theme)
  const cardStyle = mainStyles.cards()
  const noInfo = mainStyles.noDataSections()

  const [openFinaliceValidation, setOpenFinaliceValidation] = useState(false);
  const [documentsPendientesValidacion, setDocumentsPendientesValidacion] = useState(false);
  const [documentsRechazadosValidacion, setDocumentsRechazadosValidacion] = useState(false);

  const { t } =  useTranslation();

  useEffect(() => {
    if (cards?.length > 0) {
      setDocumentsPendientesValidacion(null)
      setDocumentsRechazadosValidacion(null)

      let rechazados = cards.filter(item => item.documentos?.filter(itemDoc => itemDoc?.validacion == false).length > 0)
      console.log("***********Documentation cardsArray rechazados***************: ", rechazados)

      let pendientes = cards.filter(item => item.documentos?.filter(itemDoc => itemDoc?.validacion == null && itemDoc.required == true).length > 0)
      console.log("***********Documentation cards pendientes***************: ", pendientes)

      setDocumentsRechazadosValidacion(rechazados?.length > 0 ? true : false)
      setDocumentsPendientesValidacion(pendientes?.length > 0 ? true : false)
    }
  }, [cards])
  // const itIncludes = (oneString) => {
  //   if (oneString.includes("INE / IFE")) {
  //     return ine
  //   } else if (
  //     oneString.includes("PASAPORTE") ||
  //     oneString.includes("pasaporte") ||
  //     oneString.includes("Pasaporte")
  //   ) {
  //     return passport
  //   } else if (
  //     oneString.includes("curp") ||
  //     oneString.includes("CURP")
  //   ) {
  //     return curp
  //   } else if (
  //     (oneString.includes("consentimiento") ||
  //       oneString.includes("Consentimiento")) &&
  //     (oneString.includes("INE") ||
  //       oneString.includes("IFE"))) {
  //     return consentimiento
  //   } else if (
  //     oneString.includes("contrato")
  //     || oneString.includes("Contrato")) {
  //     return contrato
  //   } else if (
  //     oneString.includes("privacidad") ||
  //     oneString.includes("Privacidad")) {
  //     return privacidad
  //   } else if (
  //     oneString.includes("nom") ||
  //     oneString.includes("NOM") ||
  //     oneString.includes("norma") ||
  //     oneString.includes("Nom") ||
  //     oneString.includes("Expediente") ||
  //     oneString.includes("expediente")
  //   ) {
  //     return nom
  //   } else if (
  //     oneString.includes("TELMEX") ||
  //     oneString.includes("Telmex") ||
  //     oneString.includes("CFE") ||
  //     oneString.includes("cfe") ||
  //     oneString.includes("domicilio") ||
  //     oneString.includes("Domicilio")) {
  //     return comprobante
  //   } else if (
  //     oneString.includes("Ingresos") ||
  //     oneString.includes("INGRESOS") ||
  //     oneString.includes("ingresos")) {
  //     return ingresos
  //   } else {
  //     return docGenerico
  //   }
  // }

  // const HtmlTooltip = withStyles((theme) => ({
  //   tooltip: {
  //     backgroundColor: theme.palette.secondary.light,
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: '1px solid #dadde9',
  //   },
  // }))(Tooltip);

  const descargaDocumento = async (idDocumento, name) => {
    try {
      const responseDoc = await documentRequests.GetDocumentZip(idDocumento);
      console.log('responseDoc', responseDoc);
      // Ya que responseDoc es un objeto que contiene la URL del archivo ZIP,
      // simplemente accede a la propiedad 'data' que contiene la URL
      const url = responseDoc.data;
      // Crea un enlace
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.zip`);
      // Haz clic en el enlace para iniciar la descarga
      link.click();
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  }


  return (
    <div>

      <div>
        {console.log("***********Documentation cards***************: ", cards)}
        {cards?.length > 0 ? cards?.map((card) => {
          if (card.grupo !== '.CER' && card.grupo !== '.KEY') {
            return <Accordion
              classes={{
                root: localStyles.accordion
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={localStyles.textGroup}>{card.grupoDescription}
                </Typography>
                <Box sx={{
                  padding: '1px 0.5em;',
                }}>
                  {console.log('cardDocx', card)}
                  {card.documentos?.filter(item => item?.validacion === undefined ||
                    item?.validacion === null)?.length ? (
                    <UseImage
                      src={loadDoc}
                      type="img"
                      className={cardStyle.loadDocIcon}
                    />
                  ) : card.documentos?.filter(item => item?.validacion == true)?.length ? (
                    <CheckCircleIcon style={{ color: "#28A745" }} />
                  ) : card.documentos?.filter(item => item?.validacion == false)?.length ? (
                    <CancelIcon style={{ color: "red" }} />
                  ) : (
                    ""
                  )}
                  {/* {getStatusIcon(card)} */}
                </Box>
              </AccordionSummary>
              <AccordionDetails className={localStyles.accordionDetail}>
                {card.documentos?.map((doc) => {
                  console.log("doc-----------: ", doc)
                  if (doc.nombre?.includes('Firma autógrafa')
                    || doc.nombre?.includes('Firma autografa')
                    || doc.extension === '.xml'
                    || doc.extension === '.wsq') {
                    return ''
                  } else if (doc.extension === ".zip") {
                    return (
                      <Box>
                        <IconButton id="btnDownloadDoc" onClick={() => descargaDocumento(doc.id, doc.nombre)}>
                          <Icon icon="formkit:zip" color='#A4CE4E' fontSize={90} />
                        </IconButton>
                      </Box>
                    )
                  } else if (Object.keys(cActor).length === 0) {
                    return (
                      <div className={localStyles.iconSection}>
                        {console.log("ITEMSDOCS*******************1*: ", IsValidate, doc.validacion, doc)}
                        <ModalVisual docName={doc.nombre} idDoc={doc.id} metaData={doc.metaData}
                          docVal={doc.validacion} extDocVal={doc.docExtVal} validation={val}
                          docsApproved={docsA} refresh={refresh}
                          statusVideo={bioData.Face?.Selfie?.ComparisonVsVideo?.StatusVideo} IsValidate={IsValidate} fileId={fileId} 
                          extension={doc.extension} docDescription={doc.Description} />
                      </div>
                    )
                  } else if (Object.keys(cActor).length !== 0) {
                    return (
                      <div className={localStyles.iconSection}>
                        {console.log("ITEMSDOCS*******************2*: ", IsValidate, doc.validacion, doc)}
                        <ActorMVisual docName={doc.nombre} idDoc={doc.id} metaData={doc.metaData}
                          docVal={doc.validacion} extDocVal={doc.docExtVal} validation={val}
                          docsApproved={docsA} actor={cActor} refresh={refresh} IsValidate={IsValidate} fileId={fileId} 
                          docDescription={doc.Description} />
                      </div>
                    )
                  }
                })}

              </AccordionDetails>
            </Accordion>
          } else {
            return ''
          }
        }) :
          <div className={noInfo.noData}>
            <UseImage src={NoDocs} type='img' className={localStyles.imageNotFound} />
            <Typography variant="h6" className={noInfo.h6}>{t("general_no_document")}</Typography>
          </div>
        }

        {/* {console.log("***---documentsPendientesValidacion,documentsRechazadosValidacion---**** ", documentsPendientesValidacion, documentsRechazadosValidacion)} */}
        {documentsPendientesValidacion == false && activaFinalizaValidacion == true ?
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1em',
            marginBottom: '0.5em',
          }}>
            <Button
              id="btnRequestValidate"
              variant="contained"
              sx={{ height: 45, px: 10, maxWidth: 300 }}
              onClick={() => { setOpenFinaliceValidation(true) }} >
              {t("finalize_validation")}
            </Button>
          </Box>
          : null}
      </div>
      <CustomAlert
        closeAction={() => {
          setOpenFinaliceValidation(false);
        }}
        open={openFinaliceValidation}
        title={t("finalize_validation")}
        body={t("finalize_validation_confirm")}
        leftButtonText={t("general_cancel")}
        leftButtonAction={() => {
          setOpenFinaliceValidation(false);
        }}
        rightButtonText={t("general_accept")}
        rightButtonAction={() => {
          FinaliceValidaction(documentsRechazadosValidacion)
          setOpenFinaliceValidation(false);
          // refresh()
        }}
      />
    </div>

  )
}


const getStatusIcon = (card, IsIcon = false) => {
  const cardStyle = mainStyles.cards()

  if (card.documentos?.filter(item => item?.validacion === null)?.length > 0) {
    return IsIcon ? <UseImage
      src={loadDoc}
      type="img"
      className={cardStyle.loadDocIcon}
    /> : "Pendiente"
  }
  else if (card.documentos?.filter(item => item?.validacion == true)?.length > 0) {
    return IsIcon ? <CheckCircleIcon style={{ color: "#28A745" }} /> : "Aprobado"
  } else if (card.documentos?.filter(item => item?.validacion == false)?.length > 0) {
    return IsIcon ? <CancelIcon style={{ color: "red" }} /> : "Rechazado"
  } else {
    return ""
  }
}