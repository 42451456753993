import {
  TextField as TextF,
  InputAdornment,
  Button,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from 'react-i18next';

const TextField = styled(TextF)(({ theme }) => ({
  height: 40,
  paddingTop: 3,
  padding: 0,
  border: "1px solid #DADADA",
  width: "100% !important",
  borderRadius: "5px !important",
}));

export const GenericHeaderActions = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} style={{ margin: "0 .8em .5em 0em" }}>
      <Grid
        item
        lg={lengthGrid(props)}
        xs={12}
        sx={{ paddingTop: "0px !important", paddingLeft: "0px !important" }}
      >
        {props.onChangeTextField != undefined ? (
          <TextField
            variant="standard"
            margin="none"
            size="normal"
            placeholder={props.placeholderTextField}
            onChange={props.onChangeTextField}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={(theme) => ({ marginLeft: theme.spacing(1) })}
                >
                  <Icon
                    fontSize={18}
                    icon="akar-icons:search"
                    color={"#9E9E9E"}
                  />
                  {/* <FolderIcon className={localStyles.iconMaterial} /> */}
                </InputAdornment>
              ),
            }}
          />
        ) : null}
        {props.LabelTitle != undefined ? (
          <Typography variant="h6">
            {props.LabelTitle} {props.toolTip}
          </Typography>
        ) : null}
      </Grid>
      {props.onClickEditMetadata != undefined ? (
        <Grid
          item
          lg={2.5}
          xs={12}
          sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }}
        >
          <Button
            id="btnHeaderEdit"
            variant="contained"
            fullWidth
            sx={{ height: 45 }}
            onClick={props.onClickEditMetadata}
            startIcon={props.EditMetadataStartIcon}
            disabled={props.EditMetadataDisabled}
            type="submit"
          >
            {props.EditMetadataText == undefined
              ? "Editar"
              : props.EditMetadataText}
          </Button>
        </Grid>
      ) : null}
      {props.onClickConfirm != undefined ? (
        <Grid
          item
          lg={2.5}
          xs={12}
          sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }}
        >
          <Button
            id="btnHeaderConfirm"
            variant="contained"
            fullWidth
            sx={{ height: 45 }}
            onClick={props.onClickConfirm}
            startIcon={props.ConfirmButtonStartIcon}
            disabled={props.ConfirmButtonDisabled}
            type="submit"
          >
            {props.ConfirmButtonText == undefined
              ? t("general_search")
              : props.ConfirmButtonText}
          </Button>
        </Grid>
      ) : null}
      {props.onClickCancel != undefined ? (
        <Grid
          item
          lg={2.5}
          xs={12}
          sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }}
        >
          <Button
            id="btnHeaderCancel"
            variant="outlined"
            fullWidth
            sx={{ height: 45 }}
            onClick={props.onClickCancel}
          >
            {props.CancelButtonText == undefined
              ? t("search_clean")
              : props.CancelButtonText}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

const lengthGrid = (props) => {
  if (props.onClickConfirm != undefined && props.onClickCancel != undefined 
    && props.onClickEditMetadata == undefined) {
    return 6.7;
  } else if (props.onClickConfirm != undefined && props.onClickCancel != undefined
    && props.onClickEditMetadata != undefined
  ) {
    return 4.2;
  } else if (
    props.onClickConfirm == undefined &&
    props.onClickCancel == undefined&&
    props.onClickEditMetadata == undefined
  ) {
    return 12;
  } else if (props.onClickCancel != undefined) {
    return 9;
  } else if (props.onClickConfirm != undefined) {
    return 9;
  } else {
    return 6.7;
  }
};
