export const InputTypeMappings = {
  PHONE: 'phone',
  RADIO: 'radio',
  COMBO: 'select',
  FECHA: 'date',
  DATE: 'date',
  FECHAPREVIA: 'date',
  CHECKBOX: 'check',
  CURRENCY: 'currency',
  EMAIL: 'email',
  CLABE: 'clabe',
  NUMBER: 'number',
  INT: 'number',
  HOUR: 'hour',
  TEXTO: 'text',
};