import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const executeSearchRequests = {}

executeSearchRequests.GetByCodigoPostal = (data) => {
    return axios.post(
        AppUrls.ExecuteSearchCp,
        Config.SetParameters(data, '', '', ''),
        Config.ConfigAuth()
    ).then(handleResponse)
}

executeSearchRequests.GetBySelectedOption = async (data) => {
    return await axios.post(
        AppUrls.GetBySelectedOption,
        Config.SetParameters(data, '', '', ''),
        Config.ConfigAuth()
    ).then(handleResponse)
}

executeSearchRequests.GetByCustomCatalog = (data) => {
    return axios.post(
      AppUrls.GetByCustomCatalog,
      Config.SetParameters(data, '', '', ''),
      Config.ConfigAuth()
    ).then(handleResponse)
  }

  executeSearchRequests.GetByCustomCatalogAsync = async (data) => {
    return axios.post(
      AppUrls.GetByCustomCatalog,
      Config.SetParameters(data, '', '', ''),
      Config.ConfigAuth()
    ).then(handleResponseAsync)
  }

let handleResponse = (response) => {
    let respObject = response.data;//BodySecurityResponse(response.data);
    if (!respObject.IsOK) {
        let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
        let error = (respObject.Body || responseObject);
        return Promise.reject(error);
    }
    return respObject;
}

let handleResponseAsync = async (response) => {
    console.log('response', response)
    let respObject = response.data;//BodySecurityResponse(response.data);
    if (!respObject.IsOK) {
        let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
        let error = (respObject.Body || responseObject);
        return Promise.reject(error);
    }
    return respObject;
}