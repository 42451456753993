import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button, useTheme
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { withStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';

import { ModalApproval } from '../modals';
import { paths, getDateHour } from '../../utils'
import { UseImage, GenericHeaderActions } from '../index';
//ICONS
import loadDoc from '../../assets/loadDoc.svg'
import profile from '../../assets/perfil.png'
import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'
import { useTranslation } from 'react-i18next';

export function ActorGralInfo({ data, docs, val, getInfoDispatch }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const cardStyle = mainStyles.cards()
  const path = window.location.pathname
  const { t } = useTranslation();

  const [rows, setRows] = useState([{ name: 'Id de actor:', details: data.Id }])

  //*Tooltip

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.secondary.light,
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  //*General Data Table*
  const { id } = useParams()
  const createData = (name, details) => {
    return { name, details };
  }
  useEffect(() => {
    console.log("**** data.***: ", data)
    if (data.Name !== null) {
      setRows(rows => [...rows, createData('Nombre(s):', data.Name)])
    }
    if (data.FatherLastName !== null) {
      setRows(rows => [...rows, createData('Apellido Paterno:', data.FatherLastName)])
    }
    if (data.FatherLastName !== null) {
      setRows(rows => [...rows, createData('Apellido Materno:', data.MotherLastName)])
    }
    if (data.TypeName !== null) {
      setRows(rows => [...rows, createData('Tipo de Actor', data.TypeName)])
    }
    if (data.PhoneNumber !== null) {
      setRows(rows => [...rows, createData('Celular:', data.PhoneNumber)])
    }
    if (data.Email !== null) {
      setRows(rows => [...rows, createData('Correo:', data.Email)])
    }
    if (data.CreationDateFormatted !== null) {
      setRows(rows => [...rows, createData('Fecha de creación:', data.CreationDateFormatted)])
    }
  }, [])


  return (
    <div className={docs.length > 0 ? localStyles.tabWithData : localStyles.tabWithoutDocs}>
      <div className={docs.length > 0 ? localStyles.data : localStyles.dataThree}>
        <Typography variant="p" className={localStyles.title}>{t("request_general")}
          {data.Validation.Approved === true ? <span className={localStyles.accepted}>/ Actor principal aprobado</span>
            : data.Validation.Approved === false ? <span className={localStyles.rejected}>/ Actor principal rechazado</span>
              : ""
          }
        </Typography>
        <div className={localStyles.personalInfo}>
          <UseImage src={profile} className={localStyles.img} type='img' />
          <TableContainer className={localStyles.table}>
            <Table>
              <TableBody>
                {rows.map((row) => (
                  <TableRow>
                    <TableCell align="left" style={{
                      borderBottom: "none",
                      paddingBottom: 10,
                    }} padding="none"
                      className={localStyles.names}>{row.name}</TableCell>
                    <TableCell align="left" style={{
                      borderBottom: "none",
                      paddingBottom: 10
                    }} padding="none"
                      className={localStyles.information}>{row.details}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {path.includes(paths.folio)
            && (val.ShowSelectApproval === true
              && (val.ApprovalInDocument === "0" ||
                val.ApprovalInDocument === null ||
                val.ApprovalInDocument == false)
              && val.ActorVal !== null
              && val.ActorVal?.Id === data.Id) ||
            (val.ManualDocValidation !== undefined && val.ManualDocValidation !== null
              && val.ManualDocValidation === "0")
            ? <ModalApproval folioId={id} actorId={data.Id} />
            : ""}

        </div>

      </div>
      {
        docs.length > 0 &&
        <div className={localStyles.dataTwo}>

          {/*lista de documentos*/}
          <div>
            <GenericHeaderActions LabelTitle="Documentación cargada"
              toolTip={<HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography variant="p" className={localStyles.docs}>Estatus de la documentación:</Typography>
                    <br></br>
                    <Typography variant="p" className={localStyles.docs}> <CheckCircleIcon style={{ color: "#28A745" }} />Documento aprobado</Typography>
                    <br></br>
                    <Typography variant="p" className={localStyles.docs}> <CancelIcon style={{ color: "red" }} />Documento rechazado</Typography>
                    <br></br>
                    <Typography variant="p" className={localStyles.docs}> <CircleIcon style={{ color: "#28A745" }} />Documento cargado</Typography>
                  </React.Fragment>
                }
                placement='bottom-start'
              >
                <HelpIcon />
              </HtmlTooltip>}
              onClickConfirm={getInfoDispatch}
              ConfirmButtonText="Actualizar" />
            <div>
              {docs.map((card) => {
                if (card.grupo !== '.CER' && card.grupo !== '.KEY') {
                  return <Accordion
                    classes={{
                      root: localStyles.accordion
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={localStyles.textGroup}  >{card.grupo}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                      <TableContainer>
                        <Table>
                          <TableBody>
                            {card.documentos.map((row) => (
                              <TableRow>
                                <TableCell align="left" style={{
                                  borderBottom: "none",
                                  paddingBottom: 10,
                                }} padding="none"
                                  width="4%"
                                  className={localStyles.docs}>
                                  {row.validacion === null ? <UseImage src={loadDoc} type='img' className={cardStyle.loadDocIcon} />
                                    : row.validacion ? <CheckCircleIcon style={{ color: "#28A745" }} />
                                      : row.validacion === false ? <CancelIcon style={{ color: "red" }} /> : ''}
                                </TableCell>
                                <TableCell align="left" style={{
                                  borderBottom: "none",
                                  paddingBottom: 10,
                                }} padding="none"
                                  width="41%"
                                  className={localStyles.docs}>
                                  {row.nombre}
                                </TableCell>
                                <TableCell align="center" style={{
                                  borderBottom: "none",
                                  paddingBottom: 10,
                                }} padding="none"
                                  width="25%"
                                  className={localStyles.information}>
                                  {row.fechaDeVal}
                                  {/* {row.fechaDeVal !== null ? getDateHour(row.fechaDeVal) : ''} */}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </AccordionDetails>
                  </Accordion>
                } else {
                  return ''
                }
              })}
            </div>

          </div>
        </div>
      }


    </div>

  )
}