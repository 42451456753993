import Swal from 'sweetalert2';

export const AlertModal = (title, text, icon, t) => {
  return Swal.fire({
    title: title || '',
    text: text || '',
    icon: icon || 'error',
    confirmButtonText: !!t ? t("general_close") : 'Cerrar',
  });
};
