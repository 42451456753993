import React, { useEffect, useState } from 'react'
import protect from '../../utils/protect'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography, Button, InputAdornment, CircularProgress, Grid, useTheme, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

import { fileActions } from '../../store/actions'
import TopImage from '../../assets/Busqueda_folio.png'
import noResult from '../../assets/NoResultMeet.png'

import { MainTable, UseImage, CustomLoadingOverlay, GenericHeaderActions } from '../../components'
import { mainStyles } from '../../theme/mainStyles'
import { useStyles } from './SearchStyles'
import { Icon } from '@iconify/react'
import DowloadSearch from '../../components/DowloadSearch';
import { useTranslation } from 'react-i18next';


function SearchComponent(props) {
    const theme = useTheme()
    const gridContainer = mainStyles.gridStyle()
    const noInfo = mainStyles.noDataSections()
    const localStyles = useStyles(theme)
    const [searching, setSearching] = useState("")
    const [search, setSearch] = useState("")

    const { t } = useTranslation();

    const info = useSelector(state => state.InfoBySearch)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fileActions.GetInfoBySearch({ "SearchText": search }))
    }, [search])

    console.log('searchComponent', search)

    return (
        <>
            {search === "" ? (
                <div className={gridContainer.centerColumn}>
                    <div className={gridContainer.centerContent}>
                        <UseImage src={TopImage} className={localStyles.topImage} type='img' />
                        <form className={localStyles.formContent} onSubmit={(event) => {
                            event.preventDefault()
                            setSearch(searching.trim())
                        }}>
                            <Typography variant='h6' >{t("search_folio")}</Typography>
                            <TextField
                                variant='standard'
                                margin='none'
                                className={localStyles.inputs}
                                sx={{ margin: '0.5em' }}
                                size="normal"
                                placeholder="Buscar"
                                onChange={(event) => {
                                    setSearching(event.target.value)
                                }}
                                InputProps={
                                    {
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position='start' className={localStyles.icon}>
                                                <SearchIcon className={localStyles.iconMaterial} />
                                            </InputAdornment>
                                        )
                                    }
                                }
                            />
                            <Button id="btnSearch"
                                fullWidth
                                variant="contained"
                                sx={{ height: 45, px: 10 }}
                                type="submit" >
                                {t("general_search")}
                            </Button>
                        </form>
                    </div>
                </div >
            ) :
                (
                    // className={mainPage.mainComponent}
                    <div>
                        <form className={localStyles.form} >
                            <GenericHeaderActions onChangeTextField={(event) => {
                                setSearching(event.target.value)
                            }}
                                placeholderTextField={t("search_candidates")}
                                onClickConfirm={(event) => {
                                    event.preventDefault()
                                    setSearch(searching.trim())
                                }}
                                ConfirmButtonText={t("general_search")}
                                CancelButtonText={t("search_clean")}
                                onClickCancel={() => {
                                    setSearch(0)
                                    setSearching('')
                                }} />
                            <Box>
                                <DowloadSearch searching={searching}/>
                            </Box>
                        </form>
                        {/* {info.error ?
                            <div className={noInfo.noResult}>
                                <UseImage src={noResult} type='img' style={{ maxWidth: 350 }} />
                                <Typography variant="h6" className={noInfo.h6}>No se encontraron resultados de busqueda</Typography>
                            </div> : null
                        } */}
                        {console.log('info.items?.length', info.items?.length)}
                        {info.loading === true ?
                            <CustomLoadingOverlay
                                active={info.loading} text={t("general_loading_message")}
                            /> : info.items?.length > 0 ?
                                <MainTable data={info.items} /> :
                                <div className={noInfo.noResult}>
                                    <UseImage src={noResult} type='img' style={{ maxWidth: 350 }} />
                                    <Typography variant="h6" className={noInfo.h6}>{t("no_results_found")}</Typography>
                                </div>
                        }

                    </div>
                )
            }
        </>
    )
}

export default protect(SearchComponent)