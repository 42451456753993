import { GetCurrencyFormatEuropean } from './index'
export function getDocumentationInfo(data) {
  if (data.DocumentalCategories?.length > 0) {
    const listOfDocGroups = data.DocumentalCategories[0].DocumentalGroups
      .map((group) => {
        return group.Documents.map((document) => {
          // console.log("group?.Code: ", group?.Code)
          return {
            grupo: (group.Code !== undefined && group.Code !== null ? group.Code + ' - ' + group.Name : group.Name),
            nombre: document.Name,
            grupoDescription: group.Description,
            validacion: document.Validation.Approved,
            fechaDeVal: document.Validation.ValidationDate
          }
        })
      })

    const documentation = listOfDocGroups.reduce((a, b) => [...a, ...b], []);

    return documentation
  } else {
    return []
  }
}

export function getCardDocumentationData(data) {
  if (data.DocumentalCategories?.length > 0) {
    console.log('DocumentalCategories', data.DocumentalCategories)
    //   const cardsArray = data.DocumentalCategories[0].DocumentalGroups
    //   .map((group)=>{ action.fileInfo.Body.DocumentalCategories
    //     return  {
    //       grupo:group.Name,
    //       documentos: group.Documents.map((document)=>{
    //         return{
    //           nombre: document.Name,
    //           validacion: document.Validation.Approved,
    //           id: document.Id,
    //           metaData: document.Metadata,
    //           fechaDeVal: document.Validation.ValidationDate,
    //           docExtVal: document.RequiresExternalApproval
    //         }
    //     })}
    // })

    // console.log("****data.DocumentalCategories*************", data.DocumentalCategories)
    const cardsArray = data.DocumentalCategories.map((level) => {
      return level.DocumentalGroups
    }).reduce((a, b) => [...a, ...b], []).map((group) => {
      return {
        grupo: (group.Code !== undefined && group.Code !== null ?
          group.Code == "Asegurado" ? "Documento llave" :
            group.Code //+ ' - ' + group.Name
          : group.Name),
        grupoDescription: group.Description,
        documentos: group.Documents.map((document) => {
          return {
            nombre: document.Name,
            Description: document.Description,
            validacion: document.Validation.Approved,
            id: document.Id,
            metaData: document.Metadata,
            fechaDeVal: document.Validation.ValidationDateFormat,
            docExtVal: document.RequiresExternalApproval,
            extension: document.Extension,
            required: true
          }
        })
      }
    })
    // console.log("***********cardsArray***************: ", cardsArray)

    // let rechazados = cardsArray.filter(item => item.documentos.filter(itemDoc => itemDoc.validacion == false).length > 0)
    // console.log("***********cardsArray rechazados***************: ", rechazados)

    // let pendientes = cardsArray.filter(item => item.documentos.filter(itemDoc => itemDoc.validacion == null).length > 0)
    // console.log("***********cardsArray pendientes***************: ", pendientes)
    return cardsArray
  } else {
    return []
  }
}

export function getDocActorInfo(data) {
  if (data.DocumentalCategories.length > 0) {
    const listOfDocGroups = data.DocumentalCategories[0].DocumentalGroups
      .map((group) => {
        return group.Documents.map((document) => {
          return {
            grupo: group.Name,
            nombre: document.Name,
            validacion: document.Validation.Approved,
            fechaDeVal: document.Validation.ValidationDate
          }
        })
      })

    const documentation = listOfDocGroups.reduce((a, b) => [...a, ...b], []);

    return documentation
  } else {
    return []
  }
}

export function getCardsActorData(data) {
  if (data.DocumentalCategories.length > 0) {
    const cardsArray = data.DocumentalCategories.map((level) => {
      return level.DocumentalGroups
    }).reduce((a, b) => [...a, ...b], []).map((group) => {
      return {
        grupo: group.Name,
        grupoDescription: group.Description,
        documentos: group.Documents.map((document) => {
          return {
            nombre: document.Name,
            Description: document.Description,
            validacion: document.Validation.Approved,
            id: document.Id,
            metaData: document.Metadata,
            fechaDeVal: document.Validation.ValidationDate,
            docExtVal: document.RequiresExternalApproval
          }
        })
      }
    })
    //   const cardsArray = data.DocumentalCategories[0].DocumentalGroups
    //   .map((group)=>{
    // return  {
    //   grupo:group.Name,
    //   documentos: group.Documents.map((document)=>{
    //     return{
    //       nombre: document.Name,
    //       validacion: document.Validation.Approved,
    //       id: document.Id,
    //       metaData: document.Metadata,
    //       fechaDeVal: document.Validation.ValidationDate,
    //       docExtVal: document.RequiresExternalApproval
    //     }
    //     })}
    // })
    return cardsArray
  } else {
    return []
  }
}

export function reorderDocumentalGroups(data) {
  console.log('++++++++++dataReorder----------------: ', data)
  const nuevoArreglo = data.map((objeto) => ({
    grupo: objeto.GroupName,
    grupoDescription: objeto?.Description,
    documentos: objeto.Documents.map((documento) => ({
      nombre: documento.Name,
      Description: document.Description,
      validacion: documento.Validation.Approved,
      id: documento.Id,
      metaData: documento.Metadata,
      fechaDeVal: documento.Validation.ValidationDateFormat,
      docExtVal: documento.RequiresExternalApproval,
      extension: documento.Extension,
      required: true
    }))
  }));

  return nuevoArreglo;

}

export function getDocsForApproval(data) {

  if (data.Stage?.DocumentalCategory !== null && data.Stage?.DocumentalCategory !== undefined) {
    let docstoApproved = data.Stage.DocumentalCategory.DocumentalGroups.map((docGroup) => {
      return docGroup.DocumentTypes
        .filter(docType => docType.Document !== null)
    }).filter(doc => doc.length > 0).map((document) => {
      return document.map((validDoc) => {
        return validDoc?.Document?.Id
      })
    }).reduce((a, b) => [...a, ...b], []);

    return docstoApproved

  } else {
    return []
  }


}

export function getValidation(data) {
  // Hay 2 validaciones por la solicitud y por el documento 
  let firstValidation
  let secondValidation
  // console.log('data.StageName************data***************: ', data)
  // console.log('data.StageName**********data.Validation*****************: ', data.Validation)

  let pendientes = data.Stage.DocumentalCategory?.DocumentalGroups
    ?.filter(itemGroup => itemGroup.DocumentTypes.filter(item => item.Document !== null)?.length > 0)
    ?.filter((group) => group.DocumentTypes.filter(item => item.Document?.Validation?.Approved === null)?.length > 0);
  // console.log('data.StageName**********data.pendientes*****************: ', pendientes)
  //Si no está ni aprobada ni rechazada y que esté en el Stage X
  // => si debe dar la opción de aprobar o rechazar
  if ((data.Validation.Approved === null || pendientes?.length > 0) && (data.StageName === "Videoconferencia" || data.StageName === "Validación documental"
    || data.StageName === "Validación Documental" || data.StageName === "Validación de documentos")) {
    // console.log("data.Validation.Approved === null && data.StageName", data.Validation.Approved, data.StageName)
    firstValidation = true
  } else {
    firstValidation = false
  }

  // donde debe ir la opción en los documentos o en el file: 
  if (data.Stage.Properties.length > 0) {
    data.Stage.Properties.map((property) => {
      if (property.Name === "ValidateByDocument") {
        secondValidation = property.Value
      }
    })
  } else {
    secondValidation = false
  }


  let metadataFind = data?.Metadata?.find(item => item.Name === "ManulaSkipDocumentValidation")
  console.log("metadataFind..... ManulaSkipDocumentValidation  ", metadataFind)
  return {
    // si esta aprobada o rechazada mostrar el status
    FileStatus: data.Validation.Status,
    FileApproval: data.Validation.Approved,
    ActorVal: data.Stage.CurrentActor,
    ShowSelectApproval: firstValidation,
    ApprovalInDocument: secondValidation,
    ManualDocValidation: metadataFind?.Value
  }
}
export function getValidationRuleData(data) {
  if (data.Stage?.DocumentalCategory?.DocumentalGroups?.length > 0) {
    const listArrayDocuments = []

    data.Stage.DocumentalCategory.DocumentalGroups.forEach((group) => {
      // console.log("data.Stage.DocumentalCategory.DocumentalGroups:*************************** ", group)
      return group.DocumentTypes.forEach((docType) => {
        // console.log("group.DocumentTypes:************** ", docType)
        let document = docType.Document;
        if (document !== undefined && document !== null && document !== "") {
          let itemObject = {
            nombre: document.Name,
            description: `${document.Name} (${docType.Description})`,
            validacion: document.Validation.Approved === undefined
              || document.Validation.Approved === null ?
              document.ValidationRule?.Results?.length > 0 ?
                null : true
              : document.Validation.Approved,
            id: document.Id,
            // metaData: document.Metadata,
            // fechaDeVal: document.Validation.ValidationDateFormat,
            // docExtVal: document.RequiresExternalApproval,
            extension: document.Extension,
            validation: document.Validation,
            validationRule: document.ValidationRule,
          }
          listArrayDocuments.push(itemObject)
        }
      })
    })
    return listArrayDocuments
  } else {
    return []
  }
}

// export function getValidationRuleData(data) {
//   if (data.Actors.length > 0) {
//     const listArrayDocuments = []

//     data.Actors.map((level) => {
//       return level.DocumentalGroups
//     }).reduce((a, b) => [...a, ...b], []).forEach((group) => {
//       return group.Documents.forEach((document) => {
//         let itemObject = {
//           nombre: document.Name,
//           validacion: document.Validation.Approved === undefined
//             || document.Validation.Approved === null ? null : document.Validation.Approved,
//           id: document.Id,
//           metaData: document.Metadata,
//           fechaDeVal: document.Validation.ValidationDateFormat,
//           docExtVal: document.RequiresExternalApproval,
//           extension: document.Extension,
//           validation: document.Validation,
//           validationRule: document.ValidationRule,
//         }
//         listArrayDocuments.push(itemObject)
//       })

//       // return {
//       //   grupo: (group.Code !== undefined && group.Code !== null ? group.Code + ' - ' + group.Name : group.Name),
//       //   documentos: group.Documents.map((document) => {
//       //     return {
//       //       nombre: document.Name,
//       //       validacion: document.Validation.Approved,
//       //       id: document.Id,
//       //       metaData: document.Metadata,
//       //       fechaDeVal: document.Validation.ValidationDateFormat,
//       //       docExtVal: document.RequiresExternalApproval,
//       //       extension: document.Extension,
//       //       validationRule: document.ValidationRule,
//       //     }
//       //   })
//       // }
//     })
//     return listArrayDocuments
//   } else {
//     return []
//   }
// }


export function getCardValidationDocumentationData(data) {
  // console.log("****data.DocumentalCategories   ***** DATA *************", data)
  // console.log("****!!!!!data.DocumentalCategories   ***** DATA DocumentalGroups*************", data?.DocumentalCategory)
  if (data?.DocumentalCategory !== undefined && data?.DocumentalCategory !== null) {
    // console.log("****data.DocumentalCategory******getCardValidationDocumentationData*******", data.DocumentalCategory)
    const cardsArray = data.DocumentalCategory.DocumentalGroups
      .filter(itemGroup => itemGroup.DocumentTypes.filter(item => item.Document !== null)?.length > 0)
      .map((group) => {
        // console.log("****group*************", group)
        return {
          grupo: (group.Code !== undefined && group.Code !== null ? group.Code + ' - ' + group.Name : group.Name),
          grupoDescription: group?.Description,
          documentos: group.DocumentTypes.filter(item => item.Document !== undefined
            && item.Document !== null).map((document) => {
              // console.log("****document*************", document)
              return document.Document == undefined || document.Document == null ? document.Document
                : {
                  nombre: document.Document.Name,
                  Description: document.Description,
                  validacion: document.Document.Validation.Approved,
                  id: document.Document.Id,
                  metaData: document.Metadata === undefined ? [] : document.Metadata,
                  fechaDeVal: document.Document.Validation.ValidationDateFormat,
                  docExtVal: document.RequiresExternalApproval,
                  extension: document.Document.Extension,
                  required: document.Required
                }
            })
        }
      })
    // console.log("***********cardsArray***************: ", cardsArray)
    // let rechazados = cardsArray.filter(item => item.documentos.filter(itemDoc => itemDoc.validacion == false).length > 0)
    // console.log("***********cardsArray rechazados***************: ", rechazados)

    // let pendientes = cardsArray.filter(item => item.documentos.filter(itemDoc => itemDoc.validacion == null && itemDoc.required == true).length > 0)
    // console.log("***********cardsArray pendientes***************: ", pendientes)
    return cardsArray.filter(item => item.documentos.length > 0)
  } else {
    return []
  }
}

export function getFormatQuestionsEditMetadata(data) {
  console.log("---+++...............getFormatQuestionsEditMetadata.............objectFormat**-----DATA--------+++++: ", data)
  if (data.QuestionGroups?.length > 0) {
    return data.QuestionGroups.map((itemsGroups) => {
      return {
        ...itemsGroups,
        Questions: itemsGroups.Questions.map(itemQuestion => {
          var responseValue = data.ResultCustomMetadata?.find(item => item.QuestionId == itemQuestion.Id)?.MetadataValue;
          return {
            ...itemQuestion,
            Value: responseValue,
            Visible: true,
            Metadata: {
              Value: responseValue,
            },
            ExecuteSearchDefinition: null,
            ExecuteSearchFunction: null
          }
        })
      }
    })
  } else {
    return []
  }

  // if (data.QuestionDefinitions?.length > 0) {

  //   let listQuestion = [];
  //   data.QuestionDefinitions.forEach((itemsQuestions) => {
  //     var responseValue = data.ResultCustomMetadata?.find(item => item.QuestionId == itemsQuestions.Id)?.MetadataValue;
  //     if (responseValue != undefined && responseValue != null && responseValue != "" && responseValue?.length > 0) {
  //       let questionAdd = {
  //         ...itemsQuestions,
  //         Value: responseValue,
  //         Visible: true,
  //         Metadata: {
  //           Value: responseValue,
  //         },
  //         ExecuteSearchDefinition: null,
  //         ExecuteSearchFunction: null
  //       }
  //       listQuestion.push(questionAdd)
  //     }
  //   })

  //   let objectFormat = {
  //     llave: data.FileId,
  //     groupTitle: "Edición de metadatos",
  //     questions: listQuestion
  //   }
  //   console.log("---+++...........getFormatQuestionsEditMetadata.................objectFormat**-----Response--------+++++: ", objectFormat)
  //   return objectFormat;
  // } else {
  //   return []
  // }
}

// export function getItemsFormatCurrencyEuropean(data) {

//   if (data?.Stage?.QuestionGroups?.length > 0) {
//     return {
//       ...data,
//       QuestionGroups: data?.Stage?.QuestionGroups.map(group => {
//         return {
//           ...group,
//           Questions: group.Questions.map(question => {
//             return {
//               ...question,
//               Metadata: question.Metadata != undefined && question.Metadata != null ? {
//                 ...question.Metadata,
//                 Value: GetCurrencyFormatEuropean(question.Metadata?.Value, question.QuestionType)
//               } : question.Metadata
//             }
//           })
//         }
//       })
//     }
//   } else {
//     return data
//   }
// }