import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Alert, UseImage, CustomLoadingOverlay, UploadDcumentsControl } from '../../../components'
import img from '../../../assets/DownloadExcel.png';
import { FormProvider, useForm } from 'react-hook-form';
import plantillaExcel from '../../../assets/PlantillaCargaMasiva.xlsx'
import { useNavigate } from 'react-router-dom';
import { fileMasiveActions } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TableDataUsers } from './TableDataUsers';
import { useTranslation } from 'react-i18next';

export default function UploadDocumentUsers() {
    const dispatch = useDispatch();
    const formMethods = useForm({ mode: "onChange" });
    const navigation = useNavigate();
    const [loadView, setloadView] = useState(false);
    const [dataUsersTable, setDataUsersTable] = useState();
    const dataUsers = useSelector((state) => state.GetBatchFile)
    const dataFlujo = useSelector((state) => state.InitializeBatchFile)
    const { t } = useTranslation();

    console.log(dataUsers)

    let handleFileChange = async (FileContent, fileB64Response, extension) => {
        try {
            dispatch(fileMasiveActions.GetBatchFile({
                "FileContentB64": fileB64Response
            }))

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (dataUsers?.items) {
            console.log('DATOS')
            setloadView(false);
            setDataUsersTable(dataUsers?.items?.Result)
        }
        if (dataUsers?.error) {
            setloadView(false);
        }
    }, [dataUsers.items])

    const sendDataMasive = () => {
        const batchUploadIds = dataUsers?.items?.Grupos.map(obj => obj.BatchUpload_Id);
        console.log('batchUploadIds',batchUploadIds); 
        dispatch(fileMasiveActions.InitializeBatchFile({
            BatchUpload_Ids: batchUploadIds
        }));
        Alert({
            icon: "success",
            title: t("process_started"),
        });
        setloadView(false);
        navigation('/inbox/homeComponent')
    }

    useEffect(() => {
        setDataUsersTable();
    }, [])

    useEffect(() => {
        // if (dataFlujo.items !== undefined || dataFlujo.items !== null) {
        //     Alert({
        //         icon: "success",
        //         title: "Proceso iniciado correctamente",
        //     });
        //     setloadView(false);
        //     navigation('/inbox/homeComponent')
        // }
    }, [dataFlujo?.items])



    return (
        <>
            {loadView === true ?
                <CustomLoadingOverlay
                    active={loadView} text={t("processing_your_data")}
                />
                : null}
            {dataUsersTable?.length > 0 ? (
                <Grid>
                    <TableDataUsers data={dataUsersTable} />
                    <Grid container display={'flex'} justifyContent='center' spacing={2}>
                        <Grid item lg={3} xs={12}>
                            <Button style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} disabled={dataUsers?.items?.Grupos.length > 0 ? false : true} variant='contained' color="primary" onClick={() => sendDataMasive()}>
                                Enviar
                            </Button>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Button style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} variant='outlined' color="primary" onClick={() => setDataUsersTable([])}>
                                {t("edit_file")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                    <Grid item lg={11} xs={12}>
                        <Box textAlign={'center'}>
                            <Typography variant='h6'>
                                {t("general_upload_xlsx_indication_dash")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={1} xs={12}>
                        <Box>
                            <Tooltip title="Descargar el template" placement="top">
                                <a href={plantillaExcel} target="_blank" rel="noopener noreferrer" download="Plantilla Carga Masiva.xlsx">
                                    <IconButton xs={{ width: 60 }}>
                                        <UseImage src={img} type='img' />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item lg={10} xs={12}>
                        <FormProvider {...formMethods}>
                            <UploadDcumentsControl
                                Excel={true}
                                Title={t("general_upload_your_file_xlsx")}
                                onChange={handleFileChange}
                            />
                        </FormProvider>
                    </Grid>
                    <Grid lg={5} xs={12}>
                        <Box display={'flex'} justifyContent='center'>
                            <Button style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} variant='outlined' color="primary" onClick={() => navigation('/inbox/new-file')}>
                                {t("general_cancel")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )
            }
        </>
    )
}
