import { flujoMasivoConstants } from '../constants/index.js'

export const UploaBatchFile2Data = (state = {}, action) => {
    // console.log('actionaction', action)
    switch (action.type) {
        case flujoMasivoConstants.UPLOAD_BATCHFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BATCHFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataExcel.Body ?? action.dataExcel.error,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BATCHFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            case flujoMasivoConstants.UPLOAD_BATCHFILE_RESET:
                return {
                    loading: false,
                    items: undefined,
                    error: undefined
                }            
        default:
            return state
    }
}
export const InitializeBatchiFleData = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.INITIALIZATE_BATCHFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.INITIALIZATE_BATCHFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case flujoMasivoConstants.INITIALIZATE_BATCHFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}
export const GetFileStageData = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.GETFILE_STAGE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.GETFILE_STAGE_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case flujoMasivoConstants.GETFILE_STAGE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}
export const GetAllBulkFileData = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.GETALL_BULKFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.GETALL_BULKFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case flujoMasivoConstants.GETALL_BULKFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}
export const UploadBulkFileData = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.UPLOAD_BULKFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BULKFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BULKFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        case flujoMasivoConstants.UPLOAD_BULKFILE_RESET:
            return {
                loading: false,
                items: undefined,
                error: undefined
            }
        default:
            return state
    }
}

export const UploadBatchFileGEIDData = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.UPLOAD_BATCHREGISTER_GEID_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BATCHREGISTER_GEID_SUCCESS:
            return {
                loading: false,
                items: action.dataFile.Body,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BATCHREGISTER_GEID_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        case flujoMasivoConstants.UPLOAD_BATCHREGISTER_GEID_RESET:
            return {
                loading: false,
                items: undefined,
                error: undefined
            }
        default:
            return state
    }
}

export const GetListGEIDData = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.GET_LIST_GEID_MASIVE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.GET_LIST_GEID_MASIVE_SUCCESS:
            return {
                loading: false,
                items: action.dataFile.Body,
                error: undefined
            }
        case flujoMasivoConstants.GET_LIST_GEID_MASIVE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}


export const GetBulkFileDetails = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.GET_BULK_FILEDETAILS_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.GET_BULK_FILEDETAILS_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case flujoMasivoConstants.GET_BULK_FILEDETAILS_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        case flujoMasivoConstants.GET_BULK_FILEDETAILS_RESET:
            return {
                loading: false,
                items: null,
                error: null
            }
        default:
            return state
    }
}


export const GetAllBulkMetadata = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.GET_ALL_BULK_METADATA_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.GET_ALL_BULK_METADATA_SUCCESS:
            return {
                loading: false,
                items: action.dataFile.Body,
                error: undefined
            }
        case flujoMasivoConstants.GET_ALL_BULK_METADATA_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}

export const GetBulkMetadataDetails = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.GET_BULK_METADATA_DETAILS_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.GET_BULK_METADATA_DETAILS_SUCCESS:
            return {
                loading: false,
                items: action.dataFile.Body,
                error: undefined
            }
        case flujoMasivoConstants.GET_BULK_METADATA_DETAILS_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        case flujoMasivoConstants.GET_BULK_METADATA_DETAILS_RESET:
            return {
                loading: false,
                items: undefined,
                error: undefined
            }
        default:
            return state
    }
}

export const UploadBulkFileMetadata = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_SUCCESS:
            return {
                loading: false,
                items: action.dataFile.Body,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        case flujoMasivoConstants.UPLOAD_BULK_FILE_DETAILS_RESET:
            return {
                loading: false,
                items: undefined,
                error: undefined
            }

        default:
            return state
    }
}
