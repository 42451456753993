import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
} from '@mui/material'

export const CustomUploadCamera= (props) => {
  const takePicture = useRef(null)
  const [activeCamera, setActiveCamera] = useState(false)

  useEffect(() => {
    if (!activeCamera) {
      setActiveCamera(true)
      takePicture.current.click()
    }
  }, [takePicture.current])

  let handleCapture = async (event) => {
    if (event.target.files?.length > 0) {
      let archivo = event.target.files[0];
      const blob = await comprimirImagen(archivo, parseInt(30));
      props.onCapture(blob)
    }
  }

  return (<Box sx={{
    textAlign: 'center', display: 'flex',
    alignItems: 'center', flexDirection: 'column'
  }}>
    <input
      type='file'
      hidden
      multiple
      accept='image/*'
      capture='environment'
      onChange={handleCapture}
      ref={takePicture} />
  </Box>);
}

const comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const imagen = new Image();
    imagen.onload = () => {
      canvas.width = imagen.width;
      canvas.height = imagen.height;
      canvas.getContext("2d").drawImage(imagen, 0, 0);
      canvas.toBlob(
        (blob) => {
          if (blob === null) {
            return reject(blob);
          } else {
            resolve(blob);
          }
        },
        "image/jpeg",
        porcentajeCalidad / 100
      );
    };
    imagen.src = URL.createObjectURL(imagenComoArchivo);
  });
};
