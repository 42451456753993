import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/styles';
import { withStyles } from '@mui/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore'
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';

import noDataToShow from '../../assets/noDataToShow.svg'

import {
  workFlowActions,
  fileActions,
  biometricActions,
  validationActions,
  documentActions
} from '../../store/actions'
import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'
import {
  ActorGralInfo,
  Documentation,
  RecordInfo,
  EquivalenciasTables,
} from './index'
import { TableBinnacle } from '../table'
import {
  UseImage, FormDynamic, CustomLoadingOverlay, DocumentUploadComponent, InformationalMessageComponent,
  GenericHeaderActions,
  Alert
} from '../index';
import { CustomAlert } from "../CustomAlert";
import moment from "moment"
import NoShares from '../NoShares';
import Summary from '../Summary';
import IndexHiringKits from '../../pages/HiringKits/indexHiringKits';
import { GeneralInfoNew } from './GeneralInfoNew';
import { ForceFormatDate, CurrencyFormat } from '../../utils';
import { useTranslation } from 'react-i18next';

//*TABS*
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export function FullWidthTabs({ currentActor, actorId, multipleActors }) {
  const theme = useTheme();
  const localStyles = useStyles(theme)
  const navigation = useNavigate();
  const cardStyle = mainStyles.cards()
  const noInfo = mainStyles.noDataSections()

  const [actorCards, setActorCards] = useState([])
  const [infoFileItems, setInfoFileItems] = useState([])
  const [docsCard, setDocsCard] = useState([])
  const [validation, setValidation] = useState({})
  const [docsApproved, setDocsApproved] = useState([])
  const [listEquivalencias, setListEquivalencias] = useState([])
  const [value, setValue] = useState(0);

  const [openHelpFinalice, setOpenHelpFinalice] = useState(false)
  const [openHelpFinReject, setOpenHelpFinReject] = useState(false)
  const [wfContinueRequest, setWFContinueRequest] = useState(false)
  const [fileUpdateMetFile, setFileUpdateMetFile] = useState(false)
  const [approvedValideProcess, setApprovedValideProcess] = useState(false);
  const [activeButtonFinalize, setActiveButtonFinalize] = useState(false);
  const [viewButtonFinalice, setViewButtonFinalice] = useState(true);
  const [editMetadataCustomSend, setEditMetadataCustomSend] = useState(false);
  const [searchEditMetadata, setSearchEditMetadata] = useState(false)
  const [listEditMetadataCustom, setListEditMetadataCustom] = useState([])

  const [idUsuario, setIdUsuario] = useState("");

  const [sendCuestionarioContinue, setSendCuestionarioContinue] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(-1)

  const { t } = useTranslation();

  const dispatch = useDispatch()


  const handleChange = (event, newValue) => {
    console.log("handleChange........newValue: ", event, newValue)
    localStorage.removeItem('ActivaCuestionarioEdicion')
    if (event == true) {
      console.log('..............ActivaCuestionarioEdicion:. Id: ', id);
      //Peticion para edicion de metadatos sin Stage                   
      dispatch(fileActions.GetFileEditCustomMetadata(id))
      setSearchEditMetadata(true)
      setLoadView(true)
      localStorage.setItem('ActivaCuestionarioEdicion', "Activo");
      setValue(newValue);
    } else {
      setValue(newValue);
    }
  };

  const handleChangeIndex = (index) => {
    console.log("handleChangeIndex........index: ", index)
    setValue(index);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.secondary.light,
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  //*ACTORS*
  // this is used for 1 actor and it goes for GetBinnacleInfo and GetRecordInfo
  const { id } = useParams()

  //this is used for one actor in a group of multiple actors and it goes for GetBinnacleInfo and GetRecordInfo
  const actorBody = {
    File_Id: id,
    Actor_Id: actorId
  }

  const validateAuth = useSelector(state => state.Authentication)
  const actor = useSelector(state => state.ActorInfo)
  const fileUpdateData = useSelector(state => state.FileUpdateMetData)
  const processRulesData = useSelector((state) => state.ProcessValRulesData);
  const userCurrentRol = useSelector((state) => state.FileInfo?.items?.Stage?.Roles[0]?.Name)
  const userCurrentEmail = useSelector((state) => state.FileInfo?.items?.CurrentUser)
  const rolUser = useSelector((state) => state.Authentication.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name)
  const userEmail = useSelector((state) => state.Authentication.items?.UserLoginData?.Username)
  const documentsRolUser = useSelector((state) => state.GetDocumentsGroupByRol)
  const editCustomMetData = useSelector((state) => state.GetFileEditCustomMetData)
  const infoFile = useSelector(state => state.FileInfo)

  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId')
  const rolesData = validateAuth?.items?.UserLoginData?.SecurityLoginData?.Roles;
  const roleName = infoFile.items?.Stage?.Roles[0]?.Name;
  const isEqualToAny = rolesData.some(role => role.Name === roleName);
  const isDiferenceToAny = rolesData.some(role => role.Name !== roleName);

  const [loadView, setLoadView] = useState(false)

  const getInfoDispatch = () => {
    dispatch(fileActions.GetFileInfo({
      FileId: id,
      WorkFlowInstanceId: workFlowInstanceId
    }))
  }
  const getActorInfoDispatch = () => {
    dispatch(fileActions.GetActorInfo(actorId))
  }
  const setBioDispatch = () => {
    dispatch(biometricActions.GetBiometricData(actorBody))
  }

  const getGeoLocationDispatch = () => {
    dispatch(validationActions.GetRegisterGeolocationFile({
      File_Id: id
    }))
  }

  const regisGeoLocData = useSelector(state => state.RegisGeolocationData)

  useEffect(() => {
    console.log('idid', id, workFlowInstanceId)
    dispatch(workFlowActions.GetBinnacleInfo(actorBody))
    dispatch(fileActions.GetRecordInfo(actorBody))
    dispatch(fileActions.GetFileInfo({
      FileId: id,
      WorkFlowInstanceId: workFlowInstanceId
    }))
    dispatch(validationActions.GetRegisterGeolocationFile({
      File_Id: id
    }))
    dispatch(validationActions.GetCustomValidationFile(actorBody))
    getInfoDispatch()
    getActorInfoDispatch()
    setBioDispatch()
    // console.log('currentAct', currentActor)
  }, [])

  useEffect(() => {
    if (Object.keys(currentActor).length !== 0) {
      dispatch(workFlowActions.GetBinnacleInfo(actorBody))
      dispatch(fileActions.GetRecordInfo(actorBody))
      dispatch(validationActions.GetCustomValidationFile(actorBody))
      getActorInfoDispatch()
      setBioDispatch()
    }
  }, [currentActor])

  useEffect(() => {
    if (actor.itemsCardActor !== undefined) {
      setActorCards(actor.itemsCardActor)
    }
  }, [actor])


  //Binnacle
  const info = useSelector(state => state.BinnacleInfo)
  const [infoItems, setInfoItems] = useState([])

  useEffect(() => {
    if (info.items !== undefined) {
      setInfoItems(info.items)
    }
  }, [info])


  //RecodFile
  const record = useSelector(state => state.RecordInfo)
  const [infoRecordItems, setInfoRecordItems] = useState([])

  useEffect(() => {
    if (record.items !== undefined) {
      // console.log("setInfoRecordItems", record.items)
      setInfoRecordItems(record.items)
    }
  }, [record])

  //infoFile
  // Biometricos 
  const [biometricInfo, setBiometricInfo] = useState({})

  const biometrics = useSelector(state => state.BiometricData)
  const wfContinueData = useSelector(state => state.ContinueWorkFlowData)

  console.log("infoFileinfoFile", infoFile)

  useEffect(() => {
    setLoadView(infoFile.loading)
    if (infoFile.items !== undefined) {
      console.log('IDROLES', infoFile.items)
      dispatch(documentActions.GetDocumentsGroupByRol({ File_Id: infoFile.items.Id }))
      // console.log("infoFile.items************************************: ", infoFile)
      setInfoFileItems(infoFile.items)
      // console.log("setDocsCard(infoFile.itemsDocCard)", infoFile.itemsDocCard)
      setDocsCard(infoFile.itemsDocCard)
      setValidation(infoFile.itemsValidation)
      setDocsApproved(infoFile.itemsDocsForApproval)
      setListEquivalencias(infoFile.itemsEquivalencias)

      let validaCount = infoFile.itemsEquivalencias.filter(item => item.validacion === null)
      // console.log("validaCount*********************: ", validaCount)

      if (validaCount.length > 0) {
        setActiveButtonFinalize(false)
      } else {
        setActiveButtonFinalize(true)
      }

      if (infoFile.items?.Status === "Validación de Mesa de control" ||
        infoFile.items?.Status === "Validación documental" ||
        infoFile.items?.Status === "Validación de documentos") {
        setViewButtonFinalice(true)
      } else {
        setViewButtonFinalice(false)
      }
    }
  }, [infoFile])


  useEffect(() => {
    // console.log("regisGeoLocData.items--------------------------------------------: ", regisGeoLocData)
    if (regisGeoLocData.items !== undefined) {
      // console.log("regisGeoLocData.items--------------------------------------------: ", regisGeoLocData.items)
    } else if (regisGeoLocData.error) {
      // console.log("Error: ", regisGeoLocData.error)
    }
  }, [regisGeoLocData])

  useEffect(() => {
    // console.log("biometrics.items***********-------- ", biometrics)
    if (biometrics.items !== undefined) {
      // console.log("biometrics.items-------- ", biometrics.items)
      setBiometricInfo(biometrics.items)
    }
  }, [biometrics])

  useEffect(() => {
    console.log("biometrics.items***********-------- wfContinueRequest", wfContinueRequest, wfContinueData.items)
    if (wfContinueData.items !== undefined && wfContinueRequest) {
      console.log("Se recibe stage de confirmacion******wfContinueData.items ********: ", wfContinueData.items)
      setWFContinueRequest(false)
      getInfoDispatch()
    } else if (wfContinueData.error !== undefined && wfContinueRequest) {
      setWFContinueRequest(false)
      console.log("wfContinueData.error-------- ", wfContinueData.error)
    }
  }, [wfContinueData])

  const customValidFile = useSelector(state => state.CustomValidationFile)
  const [infoValidationFile, setInfoValidationFile] = useState([])

  useEffect(() => {
    if (customValidFile.items !== undefined) {
      // console.log("customValidFile.items: ", customValidFile.items)
      setInfoValidationFile(customValidFile.items)
    }
  }, [customValidFile])

  useEffect(() => {
    setLoadView(fileUpdateData.loading)
    if (fileUpdateData.items !== undefined && (fileUpdateMetFile || editMetadataCustomSend)) {
      if (editMetadataCustomSend) {
        Alert({
          icon: "success",
          title: t("successful_registration"),
        });
        setEditMetadataCustomSend(false)
        localStorage.setItem('ActivaCuestionarioEdicion', null);
        getInfoDispatch()
      } else {
        // console.log("fileUpdateData.items: ", fileUpdateData.items)
        setFileUpdateMetFile(false)
        if (sendCuestionarioContinue) {
          console.log("****-----sendCuestionarioContinue-----******************", sendCuestionarioContinue)
          setSendCuestionarioContinue(false)
          setLoadView(false)
          setTimerSeconds(3);
          getInfoDispatch()
        } else {
          // console.log("excute ProcessValidationRules fileId: ", id)
          setApprovedValideProcess(true)
          dispatch(validationActions.ProcessValidationRules(id))
        }
      }
    } else if (fileUpdateData.error !== undefined && fileUpdateMetFile) {
      console.log("fileUpdateData.error: ", fileUpdateData.error)
      setLoadView(false)
      setFileUpdateMetFile(false)
    }
  }, [fileUpdateData])

  useEffect(() => {
    if (processRulesData.error !== undefined && approvedValideProcess) {
      setLoadView(false)
      setApprovedValideProcess(false)
      alert(processRulesData.error);
    } else if (processRulesData.items !== undefined && approvedValideProcess) {
      setLoadView(false)
      setApprovedValideProcess(false)
      console.log("excute processRulesData.items  refresh: ", processRulesData.items)
      getInfoDispatch();
    }
  }, [processRulesData]);

  useEffect(() => {
    console.log("useEffect------setListEditMetadataCustom,----", searchEditMetadata, editCustomMetData)
    if (editCustomMetData.error !== undefined && searchEditMetadata) {
      setLoadView(false)
      setSearchEditMetadata(false)
      setListEditMetadataCustom(null)
      alert(editCustomMetData.error);
    } else if (editCustomMetData.items !== undefined && searchEditMetadata) {
      setLoadView(false)
      setSearchEditMetadata(false)
      setListEditMetadataCustom(editCustomMetData)
    }
  }, [editCustomMetData]);

  // useEffect(() => {
  //   // console.log("**********Ejecutar timer, timerSecond************s: ", timerSeconds)

  //   if (timerSeconds == 0) {
  //     //TODO:Ejecutar la accionb timer.      
  //     // console.log("Ejecutar timer, timerSeconds: ", timerSeconds)
  //     if (infoFile.items?.Stage?.Description === "Mensaje de asignación al Representante Legal") {
  //       setTimerSeconds(-2);
  //       setLoadView(false)
  //     } else {
  //       if (infoFile.items?.Stage?.Name === "Información de la Solicitud" && infoFile.items?.Stage?.Properties?.filter(item => item.Name === "GoToDashboard" && item.Value === "1")?.length > 0) {
  //         setTimerSeconds(-2);
  //         setLoadView(false)
  //       } else {
  //         setLoadView(true)
  //         setTimerSeconds(5);
  //         getInfoDispatch()
  //       }
  //     }
  //   }
  //   // salir cuando lleguemos a 0
  //   if (!timerSeconds) return;
  //   if (timerSeconds < -1) return;

  //   // save intervalId para borrar el intervalo cuando el componente vuelve a renderizar
  //   const intervalId = setInterval(() => {
  //     setTimerSeconds(timerSeconds - 1);
  //   }, 1500);

  //   // clear interval al volver a renderizar para evitar pérdidas de memoria
  //   return () => clearInterval(intervalId);
  // }, [timerSeconds]);


  // useEffect(() => {
  //   if (validate.error !== undefined) {
  //     alert(validate.error)
  //   }
  //   if (validate.items !== undefined) {

  //   }
  // }, [validate])

  const handleSendFinaliceProcess = (e) => {
    // console.log("listEquivalencias:****** ", listEquivalencias)

    let pedingValidation = listEquivalencias.filter(item => item.validacion == false)
    // console.log("pedingValidation:****** ", pedingValidation)
    //TOdo se agregara validacion para verificar que no haya documentos faltantes de validar o que fueron marcados como incorrectos.
    // si todo es correcto se manda el codigo siguiente. de lo contrario se manda el Modal de seleccion
    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true)
    } else {
      let objRequest = {
        File_Id: id,
        Status: "Completo",
        Result: "Completo",
        WorkFlowInstanceId: workFlowInstanceId
      }
      dispatch(workFlowActions.ContinueWorkFlow(objRequest))
      setWFContinueRequest(true)
      setOpenHelpFinalice(false);
      console.log('ENTRO AQUI A FINALIZAR')
      // navigation('/inbox/homeComponent');
      // console.log("objRequest: Finalice: ", objRequest)
    }
  };

  const handleSendLeftFinalice = (e) => {
    let objRequest = {
      File_Id: id,
      Status: "Completo",
      Result: "Rechazado",
      WorkFlowInstanceId: workFlowInstanceId
    }

    // console.log("objRequest: Finalice:  Rechazado", objRequest)
    dispatch(workFlowActions.ContinueWorkFlow(objRequest))
    setWFContinueRequest(true)
    setOpenHelpFinReject(false)
  };

  const handleSendRightFinalice = (e) => {
    let objRequest = {
      File_Id: id,
      Status: "Completo",
      Result: "Recaptura",
      WorkFlowInstanceId: workFlowInstanceId
    }

    dispatch(workFlowActions.ContinueWorkFlow(objRequest))
    setWFContinueRequest(true)
    // console.log("objRequest: Finalice Recaptura: ", objRequest)
    setOpenHelpFinReject(false)
  };

  const handleSaveFile = async (formData, e) => {
    // console.log("formData, e**********: ", formData, e)

    // let currentMetadata = infoFile.items?.Stage?.QuestionGroups?.find(itemDoc => itemDoc.Name === )
    // console.log("currentMetadata: ", currentMetadata)
    let ListMetdata = []

    infoFile.items?.Stage?.QuestionGroups?.forEach((meta, i) => {
      meta?.Questions.forEach(itemQ => {
        // console.log("itemQ********************: ", itemQ)
        ListMetdata.push({
          GroupName: meta.Name,
          Name: itemQ.Name,
          Value: itemQ.QuestionType === "Fecha" ?
            formData[itemQ.Id] === "" ? null : ForceFormatDate(formData[itemQ.Id])
            : formData[itemQ.Id],
          ApprovedConfidence: null,
          Confidence: null,
          MetadataType: null,
          ValueType: null,
        })
      })
    });

    // console.log("ListMetdata: ", ListMetdata)

    let Request = {
      ObjectId: id,
      Metadata: ListMetdata,
      ContinueWorkFlow: false,
      WorkFlowInstanceId: workFlowInstanceId
    }
    console.log("Request Update metadata**********************: ", Request)
    dispatch(fileActions.FileUpdateMetadata(Request))
    setFileUpdateMetFile(true)
    setLoadView(true)
  };

  const handleOneClickFinaliceProcess = (e) => {
    let pedingValidation = listEquivalencias.filter(item => item.validacion == false)
    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true)
    } else {
      setOpenHelpFinalice(true)
    }
  };

  const handleFinaliceValidaction = (IsRechazed, e) => {
    console.log("handleFinaliceValidaction sRechazed:****** ", IsRechazed)

    let objRequest = {
      File_Id: id,
      Status: "Completo",
      Result: IsRechazed === true ? "Rechazado" : "Completo",
      WorkFlowInstanceId: workFlowInstanceId
    }

    console.log("handleFinaliceValidaction**** objRequest: ", objRequest)
    dispatch(workFlowActions.ContinueWorkFlow(objRequest))
    setWFContinueRequest(true);
    console.log('ENTRO AQUI A FINALIZAR')
  };

  const handleContinueMessage = (e) => {
    setTimerSeconds(-1)
    navigation('/inbox/homeComponent');
  }




  useEffect(() => {
    console.log("infoFileinfoFile", infoFile.items?.Stage?.Name)
    let intervalId;

    if (infoFile.items !== undefined && (infoFile.items.Stage.Name === "RegisterDocumentMatches" ||
      infoFile.items.Stage.Name === "Notificación" || infoFile.items.Stage.Name === "Generación de documentos" ||
      infoFile.items.Stage.Name === "AsignacionMetadatos" || infoFile.items.Stage.Name === "AsignarSolicitud")) {
      if (!intervalId) {
        intervalId = setInterval(() => {
          console.log("infoFileinfoFile", id)
          dispatch(fileActions.GetFileInfo({
            FileId: id,
            WorkFlowInstanceId: workFlowInstanceId
          }))
        }, 7000);
      }
    }

    return () => {
      clearInterval(intervalId);
    };

  }, [infoFile.items]);


  console.log("idUsuario", infoFile)

  const openTableWindow = () => {
    const newWindow = window.open('/auth/RecordInfoIndex', '_blank', 'width=700,height=600');
    if (!newWindow) {
      alert('El navegador bloqueó la ventana emergente. Por favor, habilite las ventanas emergentes para este sitio web.');
    }
  };

  const openMetadataEditWindow = () => {
    const newWindow = window.open('/auth/MetadataEditing', '_blank', 'width=815,height=700');
    if (!newWindow) {
      alert('El navegador bloqueó la ventana emergente. Por favor, habilite las ventanas emergentes para este sitio web.');
    }
  };

  return (
    <div className={localStyles.container}>
      {loadView == true ?
        <CustomLoadingOverlay
          active={loadView} text={t("general_loading_message")}
        /> : null}

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: localStyles.indicator
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t("request_data")} {...a11yProps(0)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />
          <Tab label={t("request_information")} style={{ textTransform: "none" }} {...a11yProps(1)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />
          <Tab label={t("request_actions")} {...a11yProps(2)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />
          <Tab label={t("request_documentation")} {...a11yProps(3)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />
          <Tab label={t("request_binnacle")} style={{ textTransform: "none" }} {...a11yProps(4)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />
          {/* <Tab label="Validaciones" {...a11yProps(4)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} /> */}
          {/* <Tab label="Resultados biométricos" {...a11yProps(5)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} /> */}
          {/* <Tab label="Geolocalización" {...a11yProps(6)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />  */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div>
            {infoFile.error && <p>{infoFile.error}</p>}
            {infoFile.loading || actor.loading ?
              <div className={localStyles.spinSection}>
                <CustomLoadingOverlay
                  active={infoFile.loading} text={t("general_loading_message")}
                />
              </div> :
              Object.keys(currentActor).length !== 0
                && actor.items !== undefined
                ? <div className={localStyles.scroll}>
                  <ActorGralInfo data={actor.items} docs={actorCards} val={validation} getInfoDispatch={getActorInfoDispatch} />
                </div> : infoFile.items !== undefined &&
                <div className={localStyles.scroll}>
                  <GeneralInfoNew data={infoFile.items} />
                </div>

              // (validation.ManualDocValidation !== undefined && validation.ManualDocValidation !== null
              //   && validation.ManualDocValidation === "1" && validate.items !== undefined)
              //   ?
              //   <InformationMessage data={infoFile.items} />
              //   :
              //   Object.keys(currentActor).length !== 0
              //     && actor.items !== undefined
              //     ? <div className={localStyles.scroll}>
              //       <ActorGralInfo data={actor.items} docs={actorCards} val={validation} getInfoDispatch={getActorInfoDispatch} />
              //     </div> : infoFile.items !== undefined &&
              //     <div className={localStyles.scroll}>
              //       <GeneralInfo data={infoFile.items} docs={docsCard} val={validation} actors={multipleActors} getInfoDispatch={getInfoDispatch} />
              //     </div>
            }
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              {console.log("---------userCurrentRol tab 2--------------------: ",rolUser, userCurrentRol, " - rolUser: ", rolUser)}
              {/* <button onClick={openTableWindow}>Abrir Tabla en Nueva Ventana</button> */}
              <GenericHeaderActions LabelTitle={t("request_information")} onClickConfirm={() => {
                dispatch(fileActions.GetRecordInfo(actorBody))
                dispatch(validationActions.GetCustomValidationFile(actorBody))
              }} ConfirmButtonText={t("request_update")} onClickCancel={openTableWindow} CancelButtonText={"Separar"}
              // onClickEditMetadata={rolUser?.toLowerCase()?.includes('vendors') || rolUser?.toLowerCase()?.includes('vendor') ? (event) => {
              //   // Abre la ventana modal separada.
              //   openMetadataEditWindow()
              //   ////Se comenta evento  handleChange por que no se usara de manera local, se usara como modal
              //   ////handleChange(true, 2)
              // } : undefined}
              />
              {record.error && <p>{record.error}</p>}
              {record.loading ?
                <div className={localStyles.spinSection}>
                  <CustomLoadingOverlay
                    active={record.loading} text={t("general_loading_message")}
                  />
                </div>
                : infoRecordItems.length > 0 || infoValidationFile.length > 0
                  ? <RecordInfo data={infoRecordItems} dataValidationFile={infoValidationFile} />
                  : infoRecordItems.length === 0
                    ? <div className={noInfo.noData}>
                      <UseImage src={noDataToShow} type='img' />
                      <Typography variant="h6" className={noInfo.h6}>{t("general_no_data")}</Typography>
                    </div> : ''}
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          {localStorage.getItem('ActivaCuestionarioEdicion') == "Activo"
            && listEditMetadataCustom?.items !== undefined && listEditMetadataCustom?.items !== null ?
            <Box>
              <FormDynamic
                fileId={id}
                EditMetadataCustom={listEditMetadataCustom.items}
                handleSave={(formData, e) => {
                  let ListMetdata = []
                  console.log("FormDynamic formData: ", formData)
                  console.log("FormDynamic editCustomMetData.items: ", listEditMetadataCustom.items?.length, listEditMetadataCustom.items)
                  if (listEditMetadataCustom.items?.length > 0) {
                    listEditMetadataCustom.items?.forEach(itemGroup => {
                      itemGroup?.Questions?.forEach(itemQ => {
                        ListMetdata.push({
                          GroupName: itemGroup.Name,
                          Name: itemQ.Name,
                          Value: itemQ.QuestionType === "Fecha" ?
                            formData[itemQ.Id] === "" ? null : ForceFormatDate(formData[itemQ.Id])
                            : itemQ.QuestionType === "Currency" || itemQ.QuestionType === "CurrencyEuropean" ? CurrencyFormat(formData[itemQ.Id]?.toString(), itemQ.QuestionType)
                              : formData[itemQ.Id],
                          ApprovedConfidence: null,
                          Confidence: null,
                          MetadataType: null,
                          ValueType: null,
                        })
                      })
                    })
                  } else {
                    listEditMetadataCustom.items?.questions?.forEach(itemQ => {
                      ListMetdata.push({
                        GroupName: itemQ.QuestionGroup,
                        Name: itemQ.Name,
                        Value: itemQ.QuestionType === "Fecha" ?
                          formData[itemQ.Id] === "" ? null : ForceFormatDate(formData[itemQ.Id])
                          : itemQ.QuestionType === "Currency" || itemQ.QuestionType === "CurrencyEuropean" ? CurrencyFormat(formData[itemQ.Id]?.toString(), itemQ.QuestionType)
                            : formData[itemQ.Id],
                        ApprovedConfidence: null,
                        Confidence: null,
                        MetadataType: null,
                        ValueType: null,
                      })
                    })
                  }

                  //Metadatao que indica si se va actualizar correo del usuario y enviar ntificacion.
                  ListMetdata.push({
                    GroupName: "Update",
                    Name: "ActiveEmail",
                    Value: "1",
                    ApprovedConfidence: null,
                    Confidence: null,
                    MetadataType: null,
                    ValueType: null,
                  })

                  let Request = {
                    ObjectId: id,
                    Metadata: ListMetdata,
                    ContinueWorkFlow: false,
                    WorkFlowInstanceId: workFlowInstanceId
                  }
                  console.log("RequestUpdate metadata 1**********************: ", Request)
                  dispatch(fileActions.FileUpdateMetadata(Request))
                  setEditMetadataCustomSend(true)
                }}
              />
            </Box>
            : (infoFile.items?.Stage?.Name === "Información de la Solicitud" ||
              infoFile.items?.Stage?.Name === "Cuestionario dinamico" ||
              infoFile.items?.Stage?.Name === "Validación Documental" ||
              infoFile.items?.Stage?.Name === "Generación de documentos Selección" ||
              infoFile.items?.Stage?.Name === "Carga documental") && isEqualToAny
              ?
              <div>
                {console.log('ROLES', infoFile.items?.Stage?.Name, infoFile.items?.Status, ((userCurrentRol === rolUser) || (userCurrentEmail === userEmail)))}
                {console.log('infoFile.items?, infoFile..............', infoFile.items, infoFile)}
                {/* {console.log("userCurrentRol---------: ", userCurrentRol, 'rolUser: ', rolUser, "userCurrentEmail: ", userCurrentEmail, "userEmail: ", userEmail)} */}

                <div className={localStyles.scroll}>
                  {(infoFile.items?.Stage?.Name === "Generación de documentos Selección")
                    ||
                    (infoFile.items?.Stage?.Name === "Carga documental" && isDiferenceToAny)
                    ? null : (
                      <GenericHeaderActions LabelTitle={infoFile.items?.Status === "Validación Documental por HR Analyst" ? (
                        "Verifica que la documentación cargada por el candidato sea correcta y aprueba o rechaza según sea el caso."
                      ) : infoFile.items?.Status === "Validación Documental por HR Analyst 2da validación" ?
                        "Verifica que la documentación cargada por el verificador de antecedentes sea correcta y aprueba o rechaza según sea el caso."
                        : infoFile.items?.Stage?.Description}
                        ConfirmButtonStartIcon={< RefreshIcon />}
                        onClickConfirm={() => {
                          getInfoDispatch()
                        }} ConfirmButtonText={t("request_update")} />
                    )}

                  {infoFile.items !== undefined
                    &&
                    (infoFile.items?.Stage?.Name === "Carga documental") && isEqualToAny
                    ?
                    <DocumentUploadComponent Data={infoFile.items} />
                    : (infoFile.items?.Stage?.Name !== "Carga documental") && isEqualToAny ? null
                      : <NoShares />}

                  {infoFile.items !== undefined
                    && infoFile.items?.Stage?.Name === "Información de la Solicitud"
                    ?
                    <InformationalMessageComponent actionResset={handleContinueMessage} Data={infoFile.items} refresh={getInfoDispatch} />
                    : null}

                  {infoFile.items !== undefined && infoFile.items?.Stage?.Name === "Generación de documentos Selección"
                    ?
                    <IndexHiringKits dataStage={infoFile.items} />
                    : null}

                  {console.log('infoFile', infoFile)}

                  {infoFile.items !== undefined
                    && (infoFile.items?.StageName === "Validación de Mesa de control" ||
                      infoFile.items?.StageName === "Validación Documental" ||
                      infoFile.items?.StageName === "Validación de documentos" ||
                      infoFile.items?.StageName === "Captura de datos por HR Analyst" ||
                      infoFile.items?.StageName === "Validación KIT contratacion" ||
                      infoFile.items?.StageName === "Cuestionario dinamico" ||
                      infoFile.items?.StageName === "Validación Documental" ||
                      infoFile.items?.StageName === "Validaciones")
                    ? <>
                      {infoFile.itemsDocCardValidation?.length > 0 ?
                        <Documentation IsValidate={true} cards={infoFile.itemsDocCardValidation} val={validation}
                          docsA={docsApproved} cActor={currentActor} refresh={getInfoDispatch} bioData={biometricInfo}
                          FinaliceValidaction={handleFinaliceValidaction} fileId={id} activaFinalizaValidacion={true} />
                        : null}

                      {infoFile.items?.Stage?.QuestionGroups?.length > 0 &&
                        infoFile.items?.Stage?.Name === "Cuestionario dinamico" ?
                        <FormDynamic
                          fileId={id}
                          StageCurrent={infoFile.items}
                          handleSave={(formData, e) => {
                            let ListMetdata = []
                            console.log("FormDynamic formData: ", formData)

                            infoFile.items?.Stage?.QuestionGroups?.forEach((meta, i) => {
                              console.log("meta?.Questions**********meta?.Questions**********: ", meta?.Questions)
                              meta?.Questions.forEach(itemQ => {
                                // console.log("itemQ********************: ", itemQ.QuestionType === "Fecha" ? formData[itemQ.Id].toString().includes("/") : null)
                                ListMetdata.push({
                                  GroupName: meta.Name,
                                  Name: itemQ.Name,
                                  Value: itemQ.QuestionType === "Fecha" ? itemQ.Id == undefined || formData[itemQ.Id] === undefined || formData[itemQ.Id] === "" ? null : ForceFormatDate(formData[itemQ.Id])
                                    : itemQ.QuestionType === "Currency" || itemQ.QuestionType === "CurrencyEuropean" ? CurrencyFormat(formData[itemQ.Id]?.toString(), itemQ.QuestionType)
                                      : formData[itemQ.Id],
                                  ApprovedConfidence: null,
                                  Confidence: null,
                                  MetadataType: null,
                                  ValueType: null,
                                })
                              })
                            });

                            console.log("ListMetdata: ", ListMetdata)

                            let Request = {
                              ObjectId: id,
                              Metadata: ListMetdata,
                              ContinueWorkFlow: true,
                              WorkFlowInstanceId: workFlowInstanceId
                            }

                            console.log("RequestUpdateMetadata 2**********************: ", Request)

                            setSendCuestionarioContinue(true)
                            dispatch(fileActions.FileUpdateMetadata(Request))
                            setFileUpdateMetFile(true)
                            setLoadView(true)
                          }}
                        />
                        : infoFile.items?.Stage?.QuestionGroups?.length > 0 ? <Accordion classes={{ root: localStyles.group }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={localStyles.textGroup} >Cuestionario</Typography>
                          </AccordionSummary>
                          <AccordionDetails className={localStyles.details}>
                            <FormDynamic
                              StageCurrent={infoFile.items}
                              handleSave={handleSaveFile}
                            />
                          </AccordionDetails>
                        </Accordion>
                          : null
                      }
                    </>
                    : null}

                  {infoFile.error && <p>{infoFile.error}</p>}
                  {(infoFile.loading || actor.loading)
                    && listEquivalencias?.length <= 0 ?
                    <div className={localStyles.spinSection}>
                      <CustomLoadingOverlay
                        active={infoFile.loading} text={t("general_loading_message")}
                      />
                    </div> :
                    infoFile.items?.Stage?.Name !== "Carga documental" && infoFile.items?.Stage?.Name !== "Información de la Solicitud" && infoFile.items?.Stage.Name !== "Generación de documentos Selección"
                      && (infoFile.items?.Stage?.Name !== "Validación Documental" && infoFile.items?.Status !== "Validación Documental por HR Analyst")
                      && (infoFile.items?.Stage?.Name !== "Cuestionario dinamico" && infoFile.items?.Status !== "Captura de datos por HR Analyst") ?
                      <EquivalenciasTables items={listEquivalencias}
                        val={validation} refresh={getInfoDispatch}
                        fileId={id} status={infoFile.items?.Status} /> : null
                  }

                </div>

                <CustomAlert
                  closeAction={() => {
                    setOpenHelpFinalice(false);
                  }}
                  open={openHelpFinalice}
                  title="Finalizar validación"
                  body="El proceso de validación será definido como correcto y avanzará a los siguientes pasos."
                  leftButtonText={t("general_cancel")}
                  leftButtonAction={() => {
                    setOpenHelpFinalice(false);
                  }}
                  rightButtonText={t("general_accept")}
                  rightButtonAction={handleSendFinaliceProcess}
                />

                <CustomAlert
                  closeAction={() => {
                    setOpenHelpFinReject(false);
                  }}
                  open={openHelpFinReject}
                  title="Finalizar validación"
                  body="Tu expediente contiene documento(s) marcado(s) como incorrecto, ¿Deseas finalizar el expediente con estatus incorrecto? o solicitar los documentos incorrectos al candidato."
                  leftButtonText="Rechazar Solicitud"
                  leftButtonAction={handleSendLeftFinalice}
                  rightButtonText="Solicitar Documentos"
                  rightButtonAction={handleSendRightFinalice}
                />

              </div>
              : (infoFile.items == undefined && (infoFile.error != undefined || infoFile.error != null || infoFile.error != "") ||
                infoFile.items.Stage.Name === "Firma de documentos" ||
                infoFile.items.Stage.Name === "Ejecución paralela" ||
                infoFile.items.Stage.Name === "Cancelar Paralelos" ||
                infoFile.items.Stage.Name === "Finalizar Paralelo") || isEqualToAny ? <div>
                <NoShares />
              </div> : (infoFile.items !== undefined && (infoFile.items.Stage.Name === "RegisterDocumentMatches" ||
                infoFile.items.Stage.Name === "Notificación" || infoFile.items.Stage.Name === "Generación de documentos" ||
                infoFile.items.Stage.Name === "AsignacionMetadatos" || infoFile.items.Stage.Name === "AsignarSolicitud")) ?
                <Summary /> :
                isDiferenceToAny ?
                  <NoShares />
                  : <NoShares />
          }
        </TabPanel>

        <TabPanel value={value} index={3} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              <GenericHeaderActions LabelTitle={t("request_documents")}
                toolTip={<HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography variant="p" className={localStyles.docs}>Estatus de la documentación:</Typography>
                      <br></br>
                      <Typography variant="p" className={localStyles.docs}> <CheckCircleIcon style={{ color: "#28A745" }} />Documento aprobado</Typography>
                      <br></br>
                      <Typography variant="p" className={localStyles.docs}> <CancelIcon style={{ color: "red" }} />Documento rechazado</Typography>
                      <br></br>
                      <Typography variant="p" className={localStyles.docs}> <CircleIcon style={{ color: "#28A745" }} />Documento cargado</Typography>
                    </React.Fragment>
                  }
                  placement='bottom-start'
                >
                  <HelpIcon />
                </HtmlTooltip>}
                onClickConfirm={() => {
                  getInfoDispatch()
                  getActorInfoDispatch()
                }} ConfirmButtonText={t("request_update")}
                ConfirmButtonStartIcon={< RefreshIcon />} />

              {infoFile.error && <p>{infoFile.error}</p>}
              {infoFile.loading || actor.loading ?
                <div className={localStyles.spinSection}>
                  <CustomLoadingOverlay
                    active={infoFile.loading} text={t("general_loading_message")}
                  />
                </div> :
                actorCards?.length > 0 && Object.keys(currentActor).length !== 0 ?
                  <Documentation cards={actorCards} val={validation} docsA={docsApproved} cActor={currentActor} refresh={getActorInfoDispatch} fileId={id} />
                  : <Documentation IsValidate={false} cards={documentsRolUser?.itemsReorder?.length == 0 ? docsCard : documentsRolUser?.itemsReorder || docsCard} val={validation} docsA={docsApproved} cActor={currentActor} refresh={getInfoDispatch} bioData={biometricInfo} fileId={id} />
                // <Documentation IsValidate={false} cards={documentsRolUser?.itemsReorder ?? docsCard} val={validation} docsA={docsApproved} cActor={currentActor} refresh={getInfoDispatch} bioData={biometricInfo} fileId={id} />
              }
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} index={4} dir={theme.direction}>
          <div>
            <GenericHeaderActions LabelTitle={t("request_binnacle")}
              onClickConfirm={() => {
                dispatch(workFlowActions.GetBinnacleInfo(actorBody))
              }} ConfirmButtonText={t("request_update")}
              ConfirmButtonStartIcon={< RefreshIcon />} />
            {info.error && <p>{info.error}</p>}
            {info.loading ?
              <div className={localStyles.spinSection}>
                <CustomLoadingOverlay
                  active={info.loading} text={t("general_loading_message")}
                />
              </div> :
              infoItems ?
                <div className={localStyles.scroll1}>
                  <TableBinnacle data={infoItems} />
                </div> : null
            }
          </div>
        </TabPanel>

        {/* <TabPanel value={value} index={4} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              <GenericHeaderActions LabelTitle="Equivalencias" onClickConfirm={handleOneClickFinaliceProcess}
                ConfirmButtonText="Finalizar" ConfirmButtonDisabled={!viewButtonFinalice} />

              {infoFile.error && <p>{infoFile.error}</p>}
              {(infoFile.loading || actor.loading) && listEquivalencias?.length <= 0 ?
                <div className={localStyles.spinSection}>
                  <CustomLoadingOverlay
                    active={infoFile.loading} text="Espere un momento por favor..."
                  />
                </div> : <EquivalenciasTables items={listEquivalencias}
                  val={validation} refresh={getInfoDispatch}
                  fileId={id}
                  status={infoFile.items?.Status} />
              }

              {infoFile.items !== undefined
                && (infoFile.items?.Status === "Validación de Mesa de control" ||
                  infoFile.items?.Status === "Validación documental" ||
                  infoFile.items?.Status === "Validación de documentos")
                && infoFile.items?.Stage?.QuestionGroups?.length > 0 ?
                <Accordion classes={{ root: localStyles.group }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={localStyles.textGroup} >Cuestionario</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={localStyles.details}>
                    <FormDynamic
                      StageCurrent={infoFile.items}
                      handleSave={handleSaveFile}
                    />
                  </AccordionDetails>
                </Accordion>
                : null}
            </div>

            <CustomAlert
              closeAction={() => {
                setOpenHelpFinalice(false);
              }}
              open={openHelpFinalice}
              title="Finalizar validación"
              body="El proceso de validación será definido como correcto y avanzará a los siguientes pasos."
              leftButtonText="Cancelar"
              leftButtonAction={() => {
                setOpenHelpFinalice(false);
              }}
              rightButtonText="Aceptar"
              rightButtonAction={handleSendFinaliceProcess}
            />

            <CustomAlert
              closeAction={() => {
                setOpenHelpFinReject(false);
              }}
              open={openHelpFinReject}
              title="Finalizar validación"
              body="Tu expediente contiene documento(s) marcado(s) como incorrecto, ¿Deseas finalizar el expediente con estatus incorrecto? o solicitar los documentos incorrectos al candidato."
              leftButtonText="Rechazar Solicitud"
              leftButtonAction={handleSendLeftFinalice}
              rightButtonText="Solicitar Documentos"
              rightButtonAction={handleSendRightFinalice}
            />

          </div>
        </TabPanel> */}

        {/* <TabPanel value={value} index={5} dir={theme.direction}>
          <div>
            {biometrics.loading
              ? <div className={localStyles.spinSection}>
                <CustomLoadingOverlay
                  active={biometrics.loading} text="Espere un momento por favor..."
                />
              </div>
              : biometrics.items !== undefined
                && (biometrics.items?.Face.Selfie !== null ||
                  biometrics.items?.SpeechToText.Audio !== null ||
                  biometrics.items?.SpeechToText.Video !== null ||
                  biometrics.items?.Fingerprints.Fingerprint2 !== null ||
                  biometrics.items?.Fingerprints.Fingerprint7 !== null
                ) ?
                <div className={localStyles.scroll}>
                  <Biometrics bioData={biometricInfo} selfArray={biometrics.itemsSelfie}
                    setBioDispatch={setBioDispatch} docMetAdic={docsCard} />
                </div>
                :
                <div className={noInfo.noData}>
                  <UseImage src={noBio} type='img' style={{ maxWidth: 260 }} />
                  <Typography variant="h6" className={noInfo.h6}>Sin datos biométricos</Typography>
                </div>
            }
          </div>
        </TabPanel> */}

        {/* <TabPanel value={value} index={6} dir={theme.direction}>
          <div>
            {regisGeoLocData.error && <p>{regisGeoLocData.error}</p>}
            {regisGeoLocData.loading ?
              <div className={localStyles.spinSection}>
                <CustomLoadingOverlay
                  active={regisGeoLocData.loading} text="Espere un momento por favor..."
                />
              </div> : regisGeoLocData.items !== undefined &&
              <div className={localStyles.scroll}>
                {console.log("Entro data de localizacion:-----------------------: ", regisGeoLocData.items)}
                <GeoLocalizacionInfo data={regisGeoLocData.items} getInfoDispatch={getGeoLocationDispatch} />
              </div>
            }
          </div>
        </TabPanel>  */}

      </SwipeableViews>
    </div>
  );
}