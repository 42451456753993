import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fileActions, validationActions } from '../../store/actions'
import protect from '../../utils/protect'
import {
  Typography, useTheme
} from '@mui/material'
import Empty from '../../assets/NoResultMeet.png'
import {
  MainTable,
  UseImage,
  CustomLoadingOverlay, GenericHeaderActions
} from '../../components'

import { useStyles } from './HomeStyles'
import { mainStyles } from '../../theme/mainStyles'
import { useTranslation } from 'react-i18next';

function HomeComponent(props) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const noInfo = mainStyles.noDataSections()
  const [searching, setSearching] = useState("")
  const [search, setSearch] = useState("")
  const [infoItems, setInfoItems] = useState([])

  const { t } = useTranslation();

  const info = useSelector(state => state.SearchByUser)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fileActions.GetSearchByUser({ "SearchText": search }))
    dispatch(validationActions.PostFileValidation({}))
  }, [])

  useEffect(() => {
    dispatch(fileActions.GetSearchByUser({ "SearchText": search }))
  }, [search])

  useEffect(() => {
    if (info.items !== undefined) {
      // console.log("Items-----------: ", info.items)
      setInfoItems(info.items)
    }
  }, [info])

  return (
    <div>
      <form className={localStyles.form} >
        <GenericHeaderActions onChangeTextField={(event) => {
          setSearching(event.target.value)
        }}
          placeholderTextField={t("search_assignments")}
          onClickConfirm={(event) => {
            event.preventDefault()
            setSearch(searching.trim())
          }}
          ConfirmButtonText={t("general_search")}
          CancelButtonText={t("search_clean")}
          onClickCancel={() => { setSearch("") }} />
      </form>
      {info.error && <p>{"Error de red favor de actualizar la pagina"}</p>}
      {info.loading ?
        <CustomLoadingOverlay
          active={info.loading} text={t("general_loading_message")}
        /> : infoItems.length > 0 ? <MainTable data={infoItems} /> :
          <div className={noInfo.noResult}>
            <UseImage src={Empty} type='img' style={{ maxWidth: 300 }} />
            <Typography variant="h6" className={noInfo.h6}>{t("general_not_request")}</Typography>
          </div>}
    </div>)
}

export default protect(HomeComponent)